import { combineReducers } from "redux";
import loginReducer from "./reducers/auth/authReducers";
import toggleReducer from "./reducers/dashboard/dashboardReducers";
import userProfileReducer from "./reducers/dashboard/userProfile";
import businessReducer from "./reducers/dashboard/businessInfo";
import getBusinessReducer from "./reducers/dashboard/businessInfo/getBusinessInfo";
import expensesReducer from "./reducers/dashboard/expenses";
import incomesReducer from "./reducers/dashboard/incomes";
import statementReducer from "./reducers/dashboard/businessStatement";
import resetPasswordReducer from "./reducers/auth/changePassword/resetPassword";
import forgotPasswordReducer from "./reducers/auth/changePassword/forgotPassword";
import sidebarReducer from "./reducers/auth/sidebarReducer";
import totalIncomeReducer from "./reducers/dashboard/totalDashboard/totalIncome";
import totalExpensesReducer from "./reducers/dashboard/totalDashboard/totalExpenses";
import expenseStatementReducer from "./reducers/expenseStatement";
import incomeStatementReducer from "./reducers/expenseStatement";

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  toggleReducer: toggleReducer,
  userProfileReducer: userProfileReducer,
  businessReducer: businessReducer,
  getBusinessReducer: getBusinessReducer,
  expensesReducer: expensesReducer,
  incomesReducer: incomesReducer,
  statementReducer: statementReducer,
  resetPasswordReducer: resetPasswordReducer,
  forgotPasswordReducer: forgotPasswordReducer,
  sidebarReducer: sidebarReducer,
  totalIncomeReducer:  totalIncomeReducer,
  totalExpensesReducer:  totalExpensesReducer,
  expenseStatementReducer: expenseStatementReducer,
  incomeStatementReducer: incomeStatementReducer,



});

export default rootReducer;
