import {
  TOGGLE_TRUE,
  TOGGLE_FALSE,
  FETCH_DASHBOARD_DETAILS,
  FETCH_USER_PROFILES
} from "../../actionTypes/dashboard/dashboardActionTypes";

export const handleToggleTrue = (toggle) => {
  return {
    type: TOGGLE_TRUE,
    payload: toggle,
  };
};

export const handleToggleFalse = (toggle) => {
  return {
    type: TOGGLE_FALSE,
    payload: toggle,
  };
};

export const fetchDashboardDetails = (toggle) => {
  return {
    type: FETCH_DASHBOARD_DETAILS,
    payload: toggle,
  };
};
export const fetchUserProfile = (toggle) => {
  return {
    type: FETCH_USER_PROFILES,
    payload: toggle,
  };
};

