import React, { useState, useEffect } from "react";

function Pagination({ data, currentPage, onPageChange }) {
  const perPage = data.per_page;
  const totalPages = data?.last_page;
  const [pageNumber, setPageNumber] = useState(currentPage);

  useEffect(() => {
    setPageNumber(currentPage);
    // console.log(currentPage);
    // console.log(data);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setPageNumber(page);
    onPageChange(page);
    // console.log(totalPages);
  };

  const renderPagination = () => {
    let items = [];
    let startPage, endPage;
    const maxPages = 5;

    if (totalPages <= maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const middlePage = Math.ceil(maxPages / 2);
      if (pageNumber <= middlePage) {
        startPage = 1;
        endPage = maxPages;
      } else if (pageNumber + middlePage >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = pageNumber - middlePage + 1;
        endPage = pageNumber + middlePage - 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={
            i === pageNumber
              ? "active relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
              : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
          }
          aria-current={i === pageNumber ? "page" : "false"}
        >
          {i}
        </button>
      );
    }

    return items;
  };

  return (
    <div
      className='pagination isolate inline-flex -space-x-px rounded-md shadow-sm'
      aria-label='Pagination'
    >
      <button
        className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
        onClick={() => handlePageChange(pageNumber - 1)}
        disabled={pageNumber === 1}
      >
        Previous
      </button>
      {renderPagination()}
      <button
        className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
        onClick={() => handlePageChange(pageNumber + 1)}
        disabled={pageNumber === totalPages}
      >
        Next
      </button>
    </div>
  );
}

export default Pagination;
