import React from 'react'
import "../../../assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "../../../scss/App.scss";

const Blank = () => {
    return (
        <div>
            This is a blank page
        </div>
    )
}

export default Blank
