import React, { useState } from "react";
import {
  expenseRequest,
  expenseSuccess,
  expenseReset,
  expenseFailure,
} from "../../redux/actions/dashboard/expenses";
import axios from "axios";
import { useDispatch } from "react-redux";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

const Expenses = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [error, setError] = useState();
  const [payment, setPayment] = useState();
  const dispatch = useDispatch();

  // bearerToken
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const baseUrl = `https://api.logikeep.africa/api/v1/createoutflow`;

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    // event.preventDefault();
  };
  const handleExpensesSubmit = async (e) => {
    e.preventDefault();
    const data = {
      outflow_details: formData?.outflow_details,
      amount: formData?.amount,
      payment_type: payment,
      date_of_expenses: formData?.date_of_expenses,
    };

    dispatch(expenseRequest());
    setIsLoading(true);
    axios
      .post(baseUrl, data, config)
      .then((res) => {
        dispatch(expenseSuccess(res));
        setIsLoading(false);
        // window.location.reload();
        //sweet alert
        Swal.fire({
          icon: "success",
          title: "Expenses Updated",
          text: res?.data?.message,
          button: "Ok!",
        });
        setTimeout(function() {
          window.location.reload();
        }, 2000);
      })

      .catch((err) => {
        dispatch(expenseFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(expenseReset());
        }, 3000);
      });
  };

  const errorDiv = error ? (
    <small className="text-red-600 text-center w-52 mt-4">{error}</small>
  ) : (
    ""
  );

  const { theme } = useGlobalContext();
  const classes = classnames({
    "income-content rounded-lg shadow-md flex flex-col space-y-10 p-5 md:py-16 text-left transition duration-500 ease-in-out p-5 bg-slate-100":
      theme === "light",
    "income-content transition duration-500 ease-in-out flex flex-col space-y-10 shadow-md p-5 md:py-16 text-left  rounded-lg bg-opacity-80 bg-white":
      theme === "dark",
  });

  return (
    <>
      <div>
        {/* {messageDiv} */}
        <form
          className="flex flex-col md:grid space-y-10 md:grid-flow-dense place-content-center text-black"
          onSubmit={handleExpensesSubmit}
        >
          <div className="h-full md:w-[600px]">
            <div className={classes}>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="note">
                  Details{" "}
                  <span className="text-red-400 absolute -top-1 left-[48px]">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="outflow_details"
                  id="item"
                  placeholder="E.g Fuel Purchase"
                  onChange={handleFormDataChange}
                  required
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="note">
                  Amount{" "}
                  <span className="text-red-400 absolute -top-1 left-[58px]">
                    *
                  </span>
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="E.g #0.00"
                  required
                  onChange={handleFormDataChange}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="category1">
                  Payment type{" "}
                  <span className="text-red-400 absolute -top-1 left-[98px]">
                    *
                  </span>
                </label>
                <select
                  className="p-2"
                  name="payment_type"
                  onChange={(e) => setPayment(e.target.value)}
                  value={payment}
                  required
                >
                  <option>Select Payment Type</option>
                  <option value="cash" id="cash">
                    Cash
                  </option>
                  <option value="bank_transfer" id="bank_transfer">
                    Bank Transfer
                  </option>
                  <option value="pos" id="pos">
                    Pos
                  </option>
                </select>
              </div>
              <div className="flex flex-col space-y-1 ">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  name="date_of_expenses"
                  id="date"
                  onChange={handleFormDataChange}
                  required
                />
              </div>
            </div>
          </div>
          {errorDiv}
          <div className="col-span-2">
            <button
              className="bg-blue text-white p-2 rounded-lg w-full"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <Spinner intent="warning" size={20} /> : "Save"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Expenses;
