import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import App from "../../../assets/images/App.png";
import Fade from "react-reveal/Fade";
import { ContentContainer, DivContainer, Header } from ".";
import AppStore from "../../../assets/images/Appstore.png";
import PlayStore from "../../../assets/images/Playstore.png";

// const Header = styled.h1`
//   ${tw`text-2xl font-bold text-black py-5`}
// `;
export default function SecondContent() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer className="bg-white lg:pb-5" id="content2">
        {" "}
        <DivContainer>
          {" "}
          <div className="">
            <div
              className=""
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img className="" src={App} alt="img" />
            </div>
          </div>
          <div className="text-center lg:text-justify space-y-5 flex flex-col align-center justify-center px-3">
            <h5
              className="text-2xl text-black"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              Manage your business on the move with our mobile app anytime,
              anywhere.
            </h5>

            <Header> DOWNLOAD THE APP!</Header>

            <div className="flex space-x-5 md:space-x-8 self-center">
              <button className=" md:w-48">
                <a href="/#" className="">
                  <img src={AppStore} alt="appstore" />
                </a>
              </button>
              <button className=" md:w-48">
                <a
                  href="https://play.google.com/store/apps/details?id=com.logikeep.app&hl=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=""
                >
                  <img src={PlayStore} alt="playstore" />
                </a>
              </button>
            </div>
          </div>
        </DivContainer>
      </ContentContainer>
    </>
  );
}
