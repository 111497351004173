import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import "aos/dist/aos.css";
import styled from "styled-components";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import Logo from "../../../assets/images/logo.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const DivContainer = styled.div`
  ${tw`grid gap-5 lg:gap-0 md:grid-cols-2 lg:grid-cols-2`}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col lg:justify-self-center`}
`;
const ColFull = styled.div`
  ${tw`lg:justify-self-center`}
`;

export default function Content() {
  const [blog, setBlog] = useState({});
  const [shareURL, setShareURL] = useState({});
  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  const imageUrl = `https://api.logikeep.africa/`;
  const { id } = useParams(); // Get route parameter
  const createdDate = DateTime.fromISO(blog.created_at);
  const [isLoading, setIsLoading] = useState(true);
  // var shareURL;

  const getBlogDetails = async () => {
    const url = `${baseUrl}single-blog/${id}`;

    setShareURL(`https://${window.location.host}/blog/${id}`);

    try {
      const res = await Axios.get(url);
      const data = res.data;

      setBlog(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogDetails();
  }, [id]);
  return (
    <>
      {isLoading ? ( // Check if loading, show loading message
        <p className="text-center font-bold text-xl pt-4">Loading blogs...</p>
      ) : (
        <ContentContainer className="bg-white mt-12" id="content2">
          <DivContainer id="contentContainer">
            <Col className="relative">
              <div className="lg:w-[550px]">
                <img
                  class="BlogCard_image__oI9Zx"
                  src={imageUrl + blog.blog_image}
                  alt={blog.title}
                  width="100%"
                />
              </div>
            </Col>
            <ColFull>
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <div>
                  <span class="BlogCard_detailCategory__URLrk">
                    LOGIKEEP AFRICA
                  </span>
                </div>
              </div>

              <h4 class="BlogCard_blockEllipsis__zYaDQ BlogCard_heading__kF6L8 ">
                {blog.prefix}
              </h4>
              <div>
                <p class="mt-5">{createdDate.toFormat("MMMM dd, yyyy")}</p>
              </div>
              <div class="BlogCard_author__rK4wK">
                <div class="flex justify-content">
                  <WhatsappShareButton url={shareURL} title={blog.title}>
                    <WhatsappIcon round size={30} />
                  </WhatsappShareButton>
                  <TwitterShareButton url={shareURL} title={blog.title}>
                    <TwitterIcon round size={30} />
                  </TwitterShareButton>
                  <FacebookShareButton url={shareURL} title={blog.title}>
                    <FacebookIcon round size={30} />
                  </FacebookShareButton>
                  <LinkedinShareButton url={shareURL} title={blog.title}>
                    <LinkedinIcon round size={30} />
                  </LinkedinShareButton>
                  <TelegramShareButton url={shareURL} title={blog.title}>
                    <TelegramIcon round size={30} />
                  </TelegramShareButton>
                  {/* <img
                    class="BlogCard_avatar__uUkZe me-2"
                    src={Logo}
                    alt="logikeep"
                  />
                  <p class="BlogCard_authorName__z6Boj mb-0">Logikeep Africa</p> */}
                </div>
              </div>
            </ColFull>
          </DivContainer>

          <h3 className="text-blue pt-12 font-bold text-2xl">{blog.title}</h3>

          <p className="description-text leading-loose font-semibold text-lg">
            {blog.description}
          </p>
        </ContentContainer>
      )}
    </>
  );
}
