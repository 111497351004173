import React, { useRef, useState } from "react";
// Import Swiper React components
import { SwiperSlide, Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import AD1 from "../../assets/images/AD1.jpg";
import AD2 from "../../assets/images/AD2.jpg";
import AD3 from "../../assets/images/AD1.jpg";
// import required modules
import { Pagination, Autoplay } from "swiper";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

export default function Slider() {
  const { theme } = useGlobalContext();
  const classes = classnames({
    "flex flex-col space-y-16 p-6 text-base rounded-3xl  bg-white transition duration-500 ease-in-out p-6 h-full":
      theme === "light",
    "flex flex-col space-y-16 p-6 text-base rounded-3xl  bg-slate-800 text-white transition duration-500 ease-in-out bg-opacity-80 p-6 h-full":
      theme === "dark",
  });
  const images = [AD1, AD2, AD3];
  return (
    <div className={classes}>
      <>
        <Swiper
          className="w-[350px]"
          slidesPerView={1}
          spaceBetween={3}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 5000, disableOnInteraction: true }}
        >
          {images.map((img, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="w-72 md:w-full">
                  <img
                    src={img}
                    className="w-full object-cover"
                    alt="ads"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>
    </div>
  );
}
