import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import {
  statementRequest,
  statementSuccess,
  statementReset,
  statementFailure,
} from "../../redux/actions/dashboard/businessStatement";
import { useDispatch } from "react-redux";
import axios from "axios";
import { CSVLink } from "react-csv";
import { useGlobalContext } from "../../context";
import classnames from "classnames";
import Pagination from "../../components/pagination/Pagination";
import { useSelector } from "react-redux";

const Statement = () => {
  const column = {
    date: "Date",
    details: "Details",
    type: "Payment Method",
    income: "Income",
    expenses: "Expenses",
  };

  const { date, details, type, income, expenses } = column;

  const [search, setSearch] = useState("");
  const [businessStatement, setBusinessStatement] = useState([]);
  const [dataInCSV, setDataInCSV] = useState([]);
  const csvDownloadRef = useRef(null);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);


  // bearerToken
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
  };

  const baseUrl = `https://api.logikeep.africa/api/v1/statement?page=`;

  const headers = [
    { label: "Date", key: "created_at" },
    { label: "Details", key: "details" },
    { label: "Payment Method", key: "payment_method" },
    { label: "Income", key: "inflow" },
    { label: "Expenses", key: "outflow" },
  ];

  const fetchProfile = async (page) => {
    dispatch(statementRequest());
    await axios
      .get(baseUrl + page, config)
      .then((response) => {
        const result = response.data.data;
        dispatch(statementSuccess(result.data));
        setBusinessStatement(result.data);
        setDataInCSV(result.data);
        setCurrentPage(result.current_page);
        // console.log(page)
        setData(result);
      })
      .catch((err) => {
        dispatch(statementFailure(err?.data));
        setTimeout(() => {
          dispatch(statementReset());
        }, 5000);
      });
  };

  useEffect(() => {
    fetchProfile(currentPage);
  }, [currentPage]);

  const getCSV = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // filter an item
  const searchFilter = businessStatement.filter(
    (product) =>
      product.details.toLowerCase().includes(search.toLowerCase()) ||
      product.payment_method.toLowerCase().includes(search.toLowerCase()) ||
      product.created_at.toLowerCase().includes(search.toLowerCase())
  );

  const { theme } = useGlobalContext();
  const classes = classnames({
    "flex md:flex-col shadow-lg pb-5 transition duration-500 ease-in-out bg-slate-100":
      theme === "light",
    "flex md:flex-col shadow-lg pb-5 transition duration-500 ease-in-out bg-opacity-80 bg-white text-black":
      theme === "dark",
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="pt-3 md:px-24 flex justify-between">
        <div className="self-center drop-shadow-lg hidden md:block">
          <p className="font-bold">
            Take a look at your overall transactions and <br /> stay updated.
          </p>
        </div>
        <div className="md:w-64 w-full relative text-black">
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            className="p-2 pl-8 shadow-md"
            type="text"
            placeholder="Search by Details or Type"
          />
          <Icon
            className="absolute top-5 left-3"
            icon="ant-design:search-outlined"
          />
        </div>
      </div>

      <div className="py-10 md:px-24 flex justify-between flex-wrap">
        <div className="self-center drop-shadow-lg md:hidden">
          <p className="font-bold">
            Take a look at your overall transactions and stay updated.
          </p>
        </div>
        <h1 className="self-center text-2xl mb-5 md:mb-0 underline">
          Statement
        </h1>

        <div className="flex space-x-5 text-black">
          <div className=" self-center">
            <button
              className="bg-white shadow-lg rounded-lg p-3 flex space-x-5 self-center"
              onClick={getCSV}
            >
              <Icon className="self-center" icon="el:download-alt" />
              <span className="text-xs m-0 w-16">Export CSV</span>{" "}
            </button>
            {dataInCSV != null ? (
              <CSVLink
                headers={headers}
                data={dataInCSV}
                filename="Logikeep Account Statement.csv"
                className="hidden"
                ref={csvDownloadRef}
                target="_blank"
              />
            ) : null}
          </div>

          <div className=" self-center">
            <input
              type="date"
              className="p-3 shadow-lg rounded-lg my-0"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className="overflow-x-auto self-center">
          <div className="flex md:inline-block min-w-full md:w-[70em] overflow-x-auto md:overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full w-96 ">
              <thead className="border-b-4 border-indigo-800">
                <tr className="">
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    {date}
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    {details}
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    {type}
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    {income}
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    {expenses}
                  </th>
                </tr>
              </thead>
              {businessStatement?.length < 1 && (
                <div className="text-center py-5">
                  No statement at the moment..
                </div>
              )}
              <tbody className="bg-white divide-y divide-gray-200 w-96 text-black">
                {searchFilter.map((statement, i) => (
                  <tr
                    key={i}
                    className="text-gray-500 divide-gray-200 border-b border-black"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statement.created_at.slice(0, 10)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statement.details}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statement.payment_method}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-green-500">
                    {dashboardDetails?.currencysymbol}
                    {new Intl.NumberFormat().format(statement.inflow)} 
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                    {dashboardDetails?.currencysymbol}
                    {new Intl.NumberFormat().format(statement.outflow)} 
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex">
            <Pagination
              data={data}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Statement;
