import React, { useState, useRef } from "react";
import Avatar from "../../assets/images/avatar-image.png";

const Card2 = ({ active, userProfile, formData, domEl }) => {
  const [bgColor, setBgColor] = useState("blue");

  //change background color on click
  const handleColor = (color) => {
    setBgColor(color);
  };
  const imageUrl = `https://api.logikeep.africa`;

  return (
    <div>
      {active === 2 && (
        <div className="grid gap-5">
          <div className="flex space-x-3 self-center">
            <button
              onClick={() => handleColor("blue")}
              className="w-10 h-10 rounded-full bg-blue"
            ></button>
            <button
              onClick={() => handleColor("cyan-400")}
              className="w-10 h-10 rounded-full bg-cyan-400"
            ></button>
            <button
              onClick={() => handleColor("black")}
              className="w-10 h-10 rounded-full bg-black"
            ></button>
            <button
              onClick={() => handleColor("rose-300")}
              className="w-10 h-10 rounded-full bg-rose-300"
            ></button>
            <button
              onClick={() => handleColor("emerald-500")}
              className="w-10 h-10 rounded-full bg-emerald-500"
            ></button>
            <button
              onClick={() => handleColor("amber-600")}
              className="w-10 h-10 rounded-full bg-amber-600"
            ></button>
            <button
              onClick={() => handleColor("amber-400")}
              className="w-10 h-10 rounded-full bg-amber-400"
            ></button>
          </div>

          <div>
            <div
              className={`self-center bg-${bgColor} h-52 w-[340px] md:w-96 rounded-lg`}
              id="card2"
              ref={domEl}
            >
              <div className="grid grid-cols-2">
                <div className="rounded-lg flex flex-col justify-center">
                  <div className="flex flex-col self-center mt-10">
                    <div className="mb-10">
                      {/* {userProfile.business_logo === "undefined" ? (
                        <img
                          src={Avatar}
                          className="ml-2 w-16"
                          alt="profile-pic"
                        />
                      ) : userProfile.business_logo === "NULL" ? (
                        <img
                          src={Avatar}
                          className="ml-2 w-16"
                          alt="profile-pic"
                        />
                      ) : (
                        <img
                          src={imageUrl + userProfile.business_logo}
                          className="ml-2 w-16"
                          alt="profile-pic"
                        />
                      )} */}

                      <div className="flex justify-center items-center flex-col text-white">
                        <div className="text-center">
                          <div className="font-bold uppercase max-w-[140px]">
                            {formData?.business}
                          </div>
                        </div>
                        <small className="relative flex mt-2 gap-1 right-2">
                          <i class="bx bxs-phone self-center"></i>
                          {userProfile.phonenumber}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="text-white text-center">
                    <small className="text-xs h-5 mt-5">
                      {formData?.tagline}
                    </small>
                  </div>
                </div>
                <div className="rounded-tl-lg">
                  <div className="text-white p-4 relative">
                    <div className="space-y-16">
                      <div className="mb-7 flex space-x-4">
                        <div class="border-l-4 border-white ..."></div>
                        <div>
                          <div>
                            <span className="capitalize">
                              {userProfile.firstname}{" "}
                            </span>
                            <span className="capitalize">
                              {userProfile.lastname}
                            </span>
                          </div>
                          <div className="font-bold uppercase">
                            {formData?.title}
                          </div>
                        </div>
                      </div>
                      <div className="mb-5 h-10">
                        <div className="flex space-x-1">
                          <i className="bx bxs-envelope text-xs mt-1"></i>
                          <p className="text-xs">{userProfile.email}</p>
                        </div>
                        <div className="flex space-x-1">
                          <i class="bx bxs-location-plus text-xs mt-1"></i>
                          <p className="text-xs">
                            {userProfile.business_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button onClick={downloadImage}>Download</button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card2;
