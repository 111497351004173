import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SelectBox({ label, options, value, handleOptionChange, setExisting }) {
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(value);

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  // const handleOptionChange = (option) => {
  //   setSelectedOption(option);
  //   setExisting(option);
  // };

  return (
    <Combobox
      as='div'
      value={"" || selectedOption}
      onChange={handleOptionChange}
    >
      <Combobox.Label className='block font-extrabold text-base text-gray-700 '>
        {label}
      </Combobox.Label>
      <div className='relative mt-1'>
        <Combobox.Input
          className='w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
          onChange={(event) => {
            console.log(event);
            setQuery(event.target.value);
          }}
          displayValue={(option) => option?.name}
        />
        <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ChevronUpDownIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredOptions.map((option) => {
              // console.log(option);
              return (
                <Combobox.Option
                  key={option.id}
                  value={option}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {option.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default SelectBox;

// /*
//   This example requires Tailwind CSS v2.0+

//   This example requires some changes to your config:

//   ```
//   // tailwind.config.js
//   module.exports = {
//     // ...
//     plugins: [
//       // ...
//       require('@tailwindcss/forms'),
//     ],
//   }
//   ```
// */
// import { useState } from "react";
// import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
// import { Combobox } from "@headlessui/react";

// const people = [
//   { id: 1, name: "Leslie Alexander" },
//   // More users...
//   { id: 2, name: "Ade Alexand" },
//   // More users...
//   { id: 3, name: "Bimbo Alexaer" },
//   // More users...
//   { id: 4, name: "Mini Ader" },
//   // More users...
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// export default function SelectBox() {
//   const [query, setQuery] = useState("");
//   const [selectedPerson, setSelectedPerson] = useState();

//   const filteredPeople =
//     query === ""
//       ? people
//       : people.filter((person) => {
//           return person.name.toLowerCase().includes(query.toLowerCase());
//         });

//   return (
//     <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
//       <Combobox.Label className="block text-sm font-medium text-gray-700">
//         Assigned to
//       </Combobox.Label>
//       <div className="relative mt-1">
//         <Combobox.Input
//           className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
//           onChange={(event) => setQuery(event.target.value)}
//           displayValue={(person) => person?.name}
//         />
//         <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
//           <ChevronUpDownIcon
//             className="h-5 w-5 text-gray-400"
//             aria-hidden="true"
//           />
//         </Combobox.Button>

//         {filteredPeople.length > 0 && (
//           <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
//             {filteredPeople.map((person) => (
//               <Combobox.Option
//                 key={person.id}
//                 value={person}
//                 className={({ active }) =>
//                   classNames(
//                     "relative cursor-default select-none py-2 pl-3 pr-9",
//                     active ? "bg-indigo-600 text-white" : "text-gray-900"
//                   )
//                 }
//               >
//                 {({ active, selected }) => (
//                   <>
//                     <span
//                       className={classNames(
//                         "block truncate",
//                         selected && "font-semibold"
//                       )}
//                     >
//                       {person.name}
//                     </span>

//                     {selected && (
//                       <span
//                         className={classNames(
//                           "absolute inset-y-0 right-0 flex items-center pr-4",
//                           active ? "text-white" : "text-indigo-600"
//                         )}
//                       >
//                         <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                       </span>
//                     )}
//                   </>
//                 )}
//               </Combobox.Option>
//             ))}
//           </Combobox.Options>
//         )}
//       </div>
//     </Combobox>
//   );
// }
