import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getProfileRequest,
  getProfileSuccess,
  getProfileReset,
  getProfileFailure,
} from "../../../redux/actions/dashboard/userProfile";
import { useDispatch } from "react-redux";
import UserDetails from "../dashboard/userDetails";
import BusinessInfo from "../dashboard/BusinessInfo";
import AOS from "aos";
import "aos/dist/aos.css";





const Business = () => {
 
  const [businessName, setBusinessName] = useState([]);
  const [businessPhone, setBusinessPhone] = useState([]);
  const [businessAddress, setBusinessAddress] = useState([]);
  const [businessLogo, setBusinessLogo] = useState([]);
  const [businessDoc, setBusinessDoc] = useState([]);



  // const dispatch = useDispatch();

  // fetch Business Info Details
  // bearerToken;
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const baseUrl = `https://api.logikeep.africa/api/v1/getuserprofile`;
  // get profile api call
  const fetchProfile = () => {
    // dispatch(getProfileRequest());
    axios
      .get(baseUrl, config)
      .then((response) => {
        const data = response.data.data;
        // dispatch(getProfileSuccess(data));
        // setUserProfile(data);
        setBusinessName(data.business_name);
        setBusinessDoc(data.business_doc);
        setBusinessPhone(data.business_phone);
        setBusinessAddress(data.business_address);
        setBusinessLogo(data.business_logo);
      })
      .catch((err) => {
        // dispatch(getProfileFailure(err?.data));
        setTimeout(() => {
          // dispatch(getProfileReset());
        }, 5000);
   
      });
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  const businessInfo =
   businessDoc == null &&
    businessAddress == null &&
  businessPhone == null &&
   businessName == null &&
    businessLogo == null;

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <>
      {/* <div className=" mb-[-100px]"> */}

      {businessInfo ? (
        <div>
          <BusinessInfo />
        </div>
      ) : (
        <div>
          <UserDetails />
        </div>
      )}
    </>
  );
};

export default Business;
