import React, { useEffect } from "react";
import tw from "twin.macro";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import manage from "../../../assets/images/manage.jpeg";
import styled from "styled-components";
import Zoom from "react-reveal/Zoom";

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const DivContainer = styled.div`
  ${tw`grid gap-5 lg:gap-0 md:grid-cols-2 lg:grid-cols-2`}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col lg:justify-self-center`}
`;
const ColFull = styled.div`
  ${tw`lg:justify-self-center`}
`;

export default function Content() {
    useEffect(() => {
      AOS.init({ duration: 2000 });
      AOS.refresh();
    }, []);
    return (
      <>
        <ContentContainer className="bg-blue-100 lg:-mt-20 mt-[75px]" id="content2">
        
          <DivContainer id="contentContainer">
          <ColFull>
              <Zoom>
                <div className="lg:w-[400px] pt-8">
                  <img
                    src={manage}
                    alt="img"
                    data-aos="fade-left"
                    data-aos-duration="3000"
                  />
                </div>
              </Zoom>
            </ColFull>
           
            <Col className="relative">
           <h2 className="text-blue text-center mt-16 font-bold text-3xl">VISIT US</h2>
           <h6 className="text-blue text-center mt-4 font-medium text-2xl">Find us on Google Map</h6>
           
                
               
            </Col>
          </DivContainer>
        </ContentContainer>
      </>
    );
  }
