import React from "react";
import { IoNotificationsSharp } from "react-icons/io5";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import { useGlobalContext } from "../../context";

const Grid = ({ items, func }) => {
  const { isLoading } = useGlobalContext();
  return (
    <div className='min-w-[950px] grid p-2 bg-white'>
      <div className='grid gap-4 grid-cols-7 items-center p-4 font-bold px-8 bg-[#EFEFEF] text-sm'>
        <h2>Name</h2>
        <h2>Date</h2>
        {/* <h2>Name of Customer</h2> */}
        <h2>Description</h2>
        <h2>Total Amount</h2>
        <h2>Amount Paid</h2>
        <h2>Balance Due</h2>
        <h2>Action</h2>
      </div>
      <div>
        {items?.map(({ customer, data }, i) => {
          return (
            <div
              key={i}
              className={`grid gap-4  grid-cols-7 bg-white p-4 px-8 text-gray-400 items-center text-sm capitalize`}
            >
              <h2>{customer?.customer_name}</h2>
              <h2>{customer?.created_at?.slice(0, 10)}</h2>
              <h2 className='w-full'>{data?.inflow_details}</h2>
              <h2>{data?.total_amount_expected}</h2>
              <h2>{data?.amount_received}</h2>
              <h2>{data?.balance_due}</h2>

              <div className='flex items-center'>
                <div className='flex gap-4' onClick={() => func(data?.id)}>
                  {isLoading ? (
                    <Spinner size={20} />
                  ) : (
                    <i className='text-xl bg-amber-200 w-10 p-2 text-amber-600 flex items-center justify-center rounded-md'>
                      <IoNotificationsSharp />
                    </i>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Grid;
