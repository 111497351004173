import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_RESET,
    RESET_PASSWORD_SUCCESS,
  } from "../../../actionTypes/auth/changePassword/resetPassword";
  
  export const resetPasswordRequest = () => {
    return {
      type: RESET_PASSWORD_REQUEST,
    };
  };
  
  export const resetPasswordSuccess = (data) => {
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    };
  };
  
  export const resetPasswordFailure = (error) => {
    return {
      type: RESET_PASSWORD_FAILURE,
      payload: error,
    };
  };
  
  export const resetPasswordReset = () => {
    return {
      type: RESET_PASSWORD_RESET,
      payload: null,
    };
  };
  