import React, { useEffect, useRef } from "react";
import SearchBox from "../../components/SearchBox";
import DateInput from "../../components/DateSelector";
import { Icon } from "@iconify/react";
import Grid from "../../components/history/grid";
import { useGlobalContext } from "../../context";
import Edit from "./edit";
import { CSVLink } from "react-csv";
import Pagination from "../../components/pagination/Pagination";

function NotPaid({ search, handleSearch }) {
  const {
    currency,
    editFunc,
    notPaidData,
    notPaidHistoryResponse,
    currentPage,
    handlePageChange,
  } = useGlobalContext();

  return (
    <div>
      <div className='flex justify-between pb-5 space-x-2'>
        <SearchBox
          placeholder={"Search customer"}
          value={search}
          onChange={handleSearch}
        />
        <div className='flex space-x-2 md:space-x-5'>
          <div className=' self-center'>
            <button className='bg-white shadow-lg rounded-lg p-3 flex md:space-x-5 self-center'>
              <Icon className='self-center' icon='el:download-alt' />
              <span className='text-xs m-0 w-16'>Export CSV</span>
            </button>
          </div>
          <div className='w-32 self-center'>
            <DateInput />
          </div>
        </div>
      </div>
      <div className='overflow-scroll'>
        <Grid
          code={currency}
          part
          items={notPaidData.filter((item) =>
            item.customer.customer_name
              .toLowerCase()
              .includes(search.toLowerCase())
          )}
          editFunc={editFunc}
          type='notPaid'
        />
      </div>
      <div className='flex justify-end my-10'>
        <Pagination
          data={notPaidHistoryResponse}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <Edit />
    </div>
  );
}

export default NotPaid;
