import {
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_RESET,
    FORGOT_PASSWORD_SUCCESS,
  } from "../../../actionTypes/auth/changePassword/forgotPassword";
  
  export const forgotPasswordRequest = () => {
    return {
      type: FORGOT_PASSWORD_REQUEST,
    };
  };
  
  export const forgotPasswordSuccess = (data) => {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    };
  };
  
  export const forgotPasswordFailure = (error) => {
    return {
      type: FORGOT_PASSWORD_FAILURE,
      payload: error,
    };
  };
  
  export const forgotPasswordReset = () => {
    return {
      type: FORGOT_PASSWORD_RESET,
      payload: null,
    };
  };
  