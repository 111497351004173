import React, { useContext, useEffect, useState } from "react";
import "../../../assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "../../../scss/App.scss";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../context";

import { useDispatch, useSelector } from "react-redux";
import Polygon from "../../../assets/images/Polygon1.png";
import Ellipse1 from "../../../assets/images/Ellipse2.png";
import axios from "axios";
import "aos/dist/aos.css";
import { flip } from "react-animations";
import styled, { keyframes } from "styled-components";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const flipper = keyframes`${flip}`;
const FlipDiv = styled.div`
  animation: 10s infinite ${flipper};
`;

const Profile = () => {
  const { userProfile, isLoading } = useGlobalContext();

  // bearerToken
  const token = localStorage.getItem("BearerToken");

  const navigate = useNavigate();
  const deleteAccount = () => {
    Swal.fire({
      icon: "error",
      title: "Are you sure to delete account? This decision is final!",
      // text: error.response.data.message,
      confirmButtonText: "OK! Delete account",
      showCancelButton: true,
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        const instance = axios.create({
          baseURL: "https://api.logikeep.africa/api/v1/",
          headers: {
            Accept: "application/json",
          },
        });
        instance
          .delete("delete-account", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "User Deleted Successfully",
              text: response,
            });
            //refresh page
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
          });
      }
    });
  };

  return (
    <>
      <div className="w-3 md:w-6 lg:w-12 absolute -top-10 md:-top-10 lg:top-80 left-10">
        <FlipDiv>
          <img className="animate-pulse" src={Polygon} alt="polygon" />
        </FlipDiv>
      </div>

      <h3 className="text-blue p-6 text-xl font-semibold flex justify-center">
        Dear {userProfile.firstname}, let’s know more about your business
      </h3>

      {/* form */}
      <div className="flex justify-center">
        <div class="w-full max-w-3xl p-6 ">
          <div class="flex flex-wrap -mx-3 mb-4">
            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
              <label
                class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                First Name
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 font-semibold  text-sm border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white text-black"
                id="grid-first-name"
                type="text"
                placeholder=""
                name=""
                value={userProfile.firstname}
              />
            </div>

            <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
              <label
                class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Last Name
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 font-semibold  text-sm border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white text-black"
                id="grid-first-name"
                type="text"
                placeholder=""
                name=""
                value={userProfile.lastname}
              />
            </div>

            <div class="w-full lg:mt-2 md:w-1/2 px-3 mb-4 md:mb-0">
              <label
                class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Phone Number
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 font-semibold  text-sm border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white text-black"
                id="grid-first-name"
                type="text"
                placeholder=""
                name=""
                value={userProfile.phonenumber}
              />
            </div>
            <div class="w-full lg:mt-2 md:w-1/2 px-3 mb-4 md:mb-0">
              <label
                class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Email Address
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 font-semibold  text-sm border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white text-black"
                id="grid-first-name"
                type="text"
                placeholder=""
                name=""
                value={userProfile.email}
              />
            </div>
          </div>
          <div className="flex justify-between">
            {/* Delete Account Request Link */}
            <div className="flex justify-end py-2 px-4">
              <div className="bg-red-500 rounded-lg py-2 px-4 ml-2 text-white">
                <div className="text-center">
                  <button
                    onClick={() => deleteAccount()}
                    className="hover:text-white hover:no-underline"
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end py-2 px-4 ">
              <div className="bg-blue rounded-lg py-2 px-4 ml-2 text-white">
                <div className="text-center  ">
                  <Link
                    to="/dashboard/business"
                    className="hover:text-white hover:no-underline"
                  >
                    {" "}
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-12 lg:w-20 right-20 md:right-64 lg:right-32 absolute -top-10 md:-top-10 lg:top-80 animate-pulse drop-shadow-2xl">
            <img className="animate-pulse" src={Ellipse1} alt="ellipse1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
