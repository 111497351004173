import React, { useEffect } from "react";
import tw from "twin.macro";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import manage from "../../../assets/images/manage.jpeg";
import styled from "styled-components";
import Zoom from "react-reveal/Zoom";

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const DivContainer = styled.div`
  ${tw`grid gap-5 lg:gap-0 md:grid-cols-2 lg:grid-cols-2`}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col lg:justify-self-center`}
`;
const ColFull = styled.div`
  ${tw`lg:justify-self-center`}
`;

export default function Content() {
    useEffect(() => {
      AOS.init({ duration: 2000 });
      AOS.refresh();
    }, []);
    return (
      <>
        <ContentContainer className="bg-white lg:-mt-20 mt-[75px]" id="content2">
        
          <DivContainer id="contentContainer">
          <Col className="relative">
           <h2 className="text-blue text-center mt-16 font-bold text-3xl">WHY CHOOSE US?</h2>
           <p className="pt-4 leading-loose">Logikeep is an app that lets you take inventory across muliple sales,channels. It also helps you restock alerts,and helps you get paid faster. It makes it easier for you to track your expenses with a wide variety of database and help you track the inflow and outflow of money in your wallet. You also get notified when your inventory is running low, so you can restock and never miss a sale. It also helps you set up recurring invoices for returning customers.</p>
                
               
            </Col>
            <ColFull>
              <Zoom>
                <div className="lg:w-[400px] pt-8">
                  <img
                    src={manage}
                    alt="img"
                    data-aos="fade-right"
                    data-aos-duration="3000"
                  />
                </div>
              </Zoom>
            </ColFull>
          </DivContainer>
        </ContentContainer>
      </>
    );
  }
