import React from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import LandingPage from "../pages/home/index";
import Register from "../pages/auth/register/index";
import Login from "../pages/auth/login/index";
import SuccessMessage from "../pages/auth/successPage/successPage";
import BusinessMessage from "../pages/auth/successPage/businessInfo";
import ForgotPassword from "../pages/auth/forgotPassword";
import ResetPassword from "../pages/auth/restPassword";
import AuthLayout from "../components/layouts/authLayout/index";
import Blank from "../pages/dashboard/dashboard/Blank";
import Wallet from "../pages/dashboard/dashboard//Wallet";
import Profile from "../pages/dashboard/dashboard/Profile";
import Business from "../pages/dashboard/dashboard/Business";
import Dashboard from "../pages/dashboard/dashboard/Dashboard";
import Customer from "../pages/dashboard/dashboard/Customer";
import BusinessCard from "../pages/dashboard/dashboard/BusinessCard";
import MainLayout from "../layout/MainLayout";
import Transactions from "../pages/transactions/Transactions";
import Invoice from "../pages/invoice/Invoice";
import Statement from "../pages/statement/Statement";
import Inventory from "../pages/inventory/Inventory";
import History from "../pages/history/History";
import BusinessInfo from "../pages/dashboard/dashboard/BusinessInfo";
import UserDetails from "../pages/dashboard/dashboard/userDetails";
import Notification from "../pages/notification";
import About from "../pages/footer/about/About";
import Blog from "../pages/footer/blog/Blog";
import BlogDetails from "../pages/footer/blog/BlogDetails";
import Contact from "../pages/footer/Contact/contact";
import RedeemPoint from "../pages/dashboard/dashboard/RedeemPoint";
import PaymentOverdue from "../pages/dashboard/dashboard/PaymentOverdue";
import PaymentResponse from "../pages/payment/paymentResponse";
import Loginn from "../pages/auth/Loginn";
import Registered from "../pages/auth/register/Registered";
import Otp from "../pages/auth/otp/Otp";
import Resendotp from "../pages/auth/otp/Resendotp";
import TotalExpensesStatement from "../pages/statement/TotalExpensesStatement";
import TotalIncomeStatement from "../pages/statement/TotalIncomeStatement";
import Policy from "../pages/home/Policy/Policy";
import DeleteAccountRequestPage from "../pages/dashboard/dashboard/DeleteAccount";
import BrtCard from "../pages/dashboard/dashboard/BrtCard";
import InvoiceSummary from "../pages/invoice/InvoiceSummary";

function InAppPrivateRoute() {
  const { isLoggedIn, user } = useSelector((state) => state.loginReducer);
  const isAuth = isLoggedIn && user?.token && user?.status;
  return isAuth ? <Outlet /> : <Navigate to='/login' replace={true} />;
}

export default function AppRoute() {
  const location = useLocation();
  return (
    <div>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route exact path='/dashboard' element={<InAppPrivateRoute />}>
            <Route path='/dashboard' element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path='customers' element={<Customer />} />
              <Route path='business-card' element={<BusinessCard />} />
              <Route path='transactions' element={<Transactions />} />
              <Route path='invoice' element={<Invoice />} />
              <Route path='products' element={<Blank />} />
              <Route path='profile' element={<Profile />} />
              <Route path='business' element={<Business />} />
              <Route path='settings' element={<Blank />} />
              <Route path='history' element={<History />} />
              <Route path='wallet' element={<Wallet />} />
              <Route path='inventory' element={<Inventory />} />
              <Route path='statement' element={<Statement />} />
              <Route path='totalexpense' element={<TotalExpensesStatement />} />
              <Route path='totalincome' element={<TotalIncomeStatement />} />
              <Route path='business-info' element={<BusinessInfo />} />
              <Route path='user-details' element={<UserDetails />} />
              <Route path='notification' element={<Notification />} />
              <Route path='redeem' element={<RedeemPoint />} />
              <Route path='overdue' element={<PaymentOverdue />} />
              <Route path='brt-card' element={<BrtCard />} />
              <Route path='invoice-summary' element={<InvoiceSummary />} />
            </Route>
          </Route>

          <Route
            path='/Not-found'
            element={
              <div className='grid grid-cols-1 place-items-center text-base h-screen'>
                <div>PAGE NOT FOUND.....</div>
              </div>
            }
          />
          <Route
            path='*'
            element={<Navigate to='/Not-found' replace={true} />}
          />
          <Route element={<AuthLayout />}>
            <Route path='/' element={<LandingPage />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path='/login' element={<Loginn />} />
            <Route path='/privacy-policy' element={<Policy />} />
            <Route path='/register' element={<Registered />} />
            <Route path='/otp' element={<Otp />} />
            <Route path='/resendotp' element={<Resendotp />} />
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/success-page' element={<SuccessMessage />} />
            <Route path='/about' element={<About />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/:id' element={<BlogDetails />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/update-page' element={<BusinessMessage />} />
            <Route path='/paymentresponse' element={<PaymentResponse />} />
            <Route
              path='/delete-account'
              element={<DeleteAccountRequestPage />}
            />
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
}
