import React, { useState, useCallback, useEffect } from "react";
import Avatar from "../../assets/images/avatar-image.png";

const Card1 = ({ active, userProfile, formData, domEl }) => {
  const [bgColor, setBgColor] = useState("border-blue");
  const [cardImg, setCardImg] = useState("");

  const imageUrl = `https://api.logikeep.africa`;
  // bearerToken
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "image/jpeg",
    },
  };

  useEffect(() => {
    fetch(`${imageUrl}${userProfile.business_logo}`, {
      // headers: config,
      mode: "no-cors",
    })
      .then((response) => {
        // console.log(response);
        // console.log(`${imageUrl}${userProfile.business_logo}`);
        setCardImg(`${imageUrl}${userProfile.business_logo}`);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  });

  //change background color on click
  const handleColor = useCallback(
    (color) => {
      setBgColor(color);
    },
    [setBgColor]
  );

  const colorOptions = [
    "blue",
    "cyan-400",
    "black",
    "rose-300",
    "emerald-500",
    "amber-600",
    "amber-400",
  ];

  return (
    <div>
      {active === 1 && (
        <div className="grid gap-5 relative">
          <div className="flex space-x-3 self-center">
            <button
              onClick={() => handleColor("border-blue")}
              className="w-10 h-10 rounded-full bg-blue"
            ></button>
            <button
              onClick={() => handleColor("border-cyan-400")}
              className="w-10 h-10 rounded-full bg-cyan-400"
            ></button>
            <button
              onClick={() => handleColor("border-black")}
              className="w-10 h-10 rounded-full bg-black"
            ></button>
            <button
              onClick={() => handleColor("border-rose-300")}
              className="w-10 h-10 rounded-full bg-rose-300"
            ></button>
            <button
              onClick={() => handleColor("border-emerald-500")}
              className="w-10 h-10 rounded-full bg-emerald-500"
            ></button>
            <button
              onClick={() => handleColor("border-amber-600")}
              className="w-10 h-10 rounded-full bg-amber-600"
            ></button>
            <button
              onClick={() => handleColor("border-amber-400")}
              className="w-10 h-10 rounded-full bg-amber-400"
            ></button>
          </div>

          <div className="self-center">
            <div id="card1" ref={domEl} className="">
              <div className="relative">
                <div
                  className={`trap1 border-b-[208px] border-solid ${bgColor} relative rounded-bl-lg`}
                >
                  <div className="text-white p-4 relative">
                    <div className="">
                      <div className="mb-7">
                        <div>
                          <span className="capitalize">
                            {userProfile.firstname}{" "}
                          </span>
                          <span className="capitalize">
                            {userProfile.lastname}
                          </span>
                        </div>
                        <div className="font-bold uppercase">
                          {formData?.title}
                        </div>
                      </div>
                      <div className="mb-5 h-10">
                        <div className="flex space-x-1">
                          <i className="bx bxs-envelope text-xs mt-1"></i>
                          <p className="text-xs">{userProfile.email}</p>
                        </div>
                        <div className="flex space-x-1">
                          <i className="bx bxs-location-plus text-xs mt-1"></i>
                          <p className="text-xs">
                            {userProfile.business_address}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <small className="text-xs h-5 mt-5 absolute top-[140px]">
                        {formData?.tagline}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="trap2 absolute rounded-lg md:w-[350px] w-[300px] text-black">
                  <div className="relative flex justify-center">
                    <div className="absolute bus-logo ">
                      <div className="flex justify-center items-center flex-col">
                        <div className="text-center">
                          <div className="font-bold uppercase max-w-[140px]">
                            {formData?.business}
                          </div>
                        </div>
                        <small className="relative flex mt-2 gap-1 right-2 text-black">
                          <i class="bx bxs-phone self-center"></i>
                          {userProfile.phonenumber}
                        </small>
                      </div>

                      {/* {userProfile.business_logo === "undefined" ? (
                        <img
                          src={Avatar}
                          className="ml-2 w-16"
                          alt="profile-pic"
                        />
                      ) : userProfile.business_logo === "NULL" ? (
                        <img
                          src={Avatar}
                          className="ml-2 w-16"
                          alt="profile-pic"
                        />
                      ) : (
                        <img
                          src={cardImg}
                          className="ml-2 w-16"
                          alt="profile-pic"
                          headers={config}
                        />
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card1;
