import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

const ProgressBar = () => {
  const { theme } = useGlobalContext();
  const classes = classnames({
    "text-center": theme === "light",
    "text-center text-white": theme === "dark",
  });
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [isPercentage, setIsPercentage] = useState("");
  const [textColor, setTextColor] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [emoji, setEmoji] = useState("");

  const handleStatus = useCallback(() => {
    setStatus(dashboardDetails?.businessstate?.status);
    if (status === "Loss") {
      setTextColor("red");
      setIsPercentage(100);
      setEmoji("😥");
    } else if (status === "Profit") {
      setTextColor("green");
      setIsPercentage(0);
      setEmoji("🤑");
    } else {
      setTextColor("orange");
      setIsPercentage(50);
      setEmoji("😎");
    }
  }, [dashboardDetails, status]);

  useEffect(() => {
    setMessage(dashboardDetails?.businessstate?.message);
    handleStatus();
  }, [handleStatus, dashboardDetails]);
  return (
    <>
      <div className='flex flex-col justify-center h-full space-y-2 mt-2'>
        <h1 className='text-center underline bg-blue text-white rounded-lg p-1'>
          Business Report This Week
        </h1>
        <div
          className='w-28 h-28 self-center'
          style={{
            zIndex: 1,
          }}
        >
          <CircularProgressbarWithChildren
            value={isPercentage}
            strokeWidth={12}
            styles={buildStyles({
              pathColor: "red",
              trailColor: "#0BA34A",
            })}
          >
            <div className='mb-3'>{emoji}</div>

            <div
              style={{
                fontSize: 12,
                marginTop: -5,
                color: `${textColor}`,
              }}
            >
              <strong className='text-xs'>{status}</strong>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <p className={classes}>{message}</p>
      </div>
    </>
  );
};

export default ProgressBar;
