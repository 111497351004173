import {
    EXPENSESTATEMENT_FAILURE,
    EXPENSESTATEMENT_REQUEST,
    EXPENSESTATEMENT_SUCCESS,
    EXPENSESTATEMENT_RESET,
  } from "../actionTypes/dashboard/expenseStatement";
  
  const initialState = {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  };
  
  const expenseStatementReducer = (state = initialState, action) => {
    switch (action.type) {
      case EXPENSESTATEMENT_REQUEST:
        return { ...state, isLoading: true };
      case EXPENSESTATEMENT_SUCCESS:
        return {
          isLoading: false,
          isLoggedIn: true,
          error: null,
          // data: action.payload,
          user: action.payload,
          isError: false,
        };
      case EXPENSESTATEMENT_FAILURE:
        return {
          data: [],
          isLoading: false,
          error: action.payload,
          isError: true,
        };
      case EXPENSESTATEMENT_RESET:
        return {
          isLoading: false,
          isError: false,
          data: [],
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default expenseStatementReducer