import {
  TOGGLE_TRUE,
  TOGGLE_FALSE,
  FETCH_DASHBOARD_DETAILS,
} from "../../actionTypes/dashboard/dashboardActionTypes";

const initialState = {
  isSideBarOpen: true,
  dashboardDetails: [],
};

const toggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_TRUE:
      return {
        ...state,
        isSideBarOpen: action,
      };
    case TOGGLE_FALSE:
      return {
        ...state,
        isSideBarOpen: action,
      };
    case FETCH_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardDetails: action.payload,
      };

    default:
      return state;
  }
};


export default toggleReducer;
