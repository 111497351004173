import React, { useEffect, useState } from "react";
import "./topnav.scss";
import UserInfo from "../user-info/UserInfo";
import { data } from "../../constants";
import { TodayDate } from "../date/GetDate";
import { Icon } from "@iconify/react";
import Log from "../../assets/images/logikeepPngBlue.png";
import { useGlobalContext } from "../../context";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { Link } from "react-router-dom";
import classnames from "classnames";

const TopNav = () => {
  const { notifications, toggleTheme, theme } = useGlobalContext();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const count = notifications?.filter((not) => not.read === "false").length;
    setNotificationCount(count);
  }, [notifications]);

  const openSidebar = () => {
    document.body.classList.add("sidebar-open");
  };

  const dateClass = classnames({
    "hidden md:flex self-center gap-3 items-center md:bg-white p-4 md:px-6 rounded-full text-blue w-fit transition duration-500 ease-in-out":
      theme === "light",
    "hidden md:flex self-center gap-3 items-center md:bg-white p-4 md:px-6 rounded-full text-blue w-fit transition duration-500 ease-in-out bg-opacity-80":
      theme === "dark",
  });

  return (
    <>
      <div className='topnav'>
        <div>
          <img src={Log} alt='logo' className='w-24' />
        </div>

        <div className='sidebar-toggle' onClick={openSidebar}>
          <i className='bx bx-menu-alt-right'></i>
        </div>
      </div>
      <div className='space-y-3 pb-7'>
        <div className='flex flex-row justify-between md:items-center w-full gap-4'>
          <UserInfo user={data.user} />
          <div className='flex items-center gap-4'>
            <button
              onClick={toggleTheme}
              className='bg-slate-500 rounded-full p-2 hidden md:block'
            >
              {theme === "light" ? (
                <div className='flex'>
                  <Icon icon='icon-park-solid:dark-mode' className='' />
                </div>
              ) : (
                <div className='flex'>
                  <Icon icon='icon-park-solid:dark-mode' className='' />
                </div>
              )}
            </button>
            <Link to='/dashboard/notification' className='flex items-center'>
              <i>
                <MdOutlineNotificationsNone className='text-3xl text-blue' />
              </i>
              <p className='w-7 -translate-y-1 h-7 flex items-center justify-center rounded-full bg-red-500 text-xs text-white'>
                {notificationCount}
              </p>
            </Link>
            <div className={dateClass}>
              <span className='hidden md:block'>
                <Icon icon='simple-line-icons:calender' />
              </span>
              <TodayDate />
            </div>
          </div>
        </div>
      </div>

      <hr />
    </>
  );
};

export default TopNav;
