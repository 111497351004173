import React from "react";
import "./main-layout.scss";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import { useGlobalContext } from "../context";
import classnames from "classnames";

const MainLayout = () => {
  const { theme } = useGlobalContext();
  const classes = classnames({
    "main__content bg-slate-100": theme === "light",
    "main__content bg-black text-white": theme === "dark",
  });
  return (
    <>
      <Sidebar />
      <div className="main">
        <div className={classes}>
          <TopNav />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
