import React from "react";
import { useGlobalContext } from "../../context";
import SingleNotification from "../../components/notification/singleNotification";

const Notification = () => {
  const { notifications } = useGlobalContext();

  return (
    <div>
      <h2 className='font-bold text-2xl mt-10'>Notifications</h2>

      <div>
        {notifications &&
          notifications?.map((item, id) => {
            return <SingleNotification {...item} key={id} />;
          })}
        {notifications?.length === 0 && (
          <h1 className='my-4'>You don't have notifications at the moment</h1>
        )}
      </div>
    </div>
  );
};

export default Notification;
