import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { Col } from ".";
import { ContentContainer } from ".";

const DivContainer = styled.div`
  ${tw`grid md:grid-cols-2 lg:grid-cols-4 py-5`}
`;
const ImgIcon = styled.img`
  ${tw`h-[155px]`};
`;
const Header = styled.h4`
  ${tw`text-base font-semibold text-center text-white`}
`;
const Header2 = styled.h4`
  ${tw`text-base font-semibold text-center text-black`}
`;
const Paragraph = styled.p`
  ${tw`text-base text-center text-white`}
`;

export default function Reviews() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer className="bg-blue review" id="content2">
        <h1
          className="text-4xl text-white text-center lg:px-40"
          id="header4"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          Trusted and loved by hundreds of small business owners{" "}
          {/* <span className="text-4xl text-blue"> small business owners</span> */}
        </h1>
        <DivContainer>
          <Col className="px-16 " id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108054/Logikeep/Img1_jmscep.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              DANIEL
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              This is the best innovation I’ve come across in a long while. I
              have become more accountable as a result.
            </Paragraph>
          </Col>
          <Col className="px-16" id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108170/Logikeep/Img2_wcojvm.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              JOY
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              Logikeep has made it easy for me to keep inventory of my stocks
              and I’m super grateful for that.
            </Paragraph>
          </Col>
          <Col className="px-16" id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108066/Logikeep/Img4_wlrlgz.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              EKEOMA
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              I heard about Logikeep from my sister that owns a shop so I
              decided to try it out as well. I’m glad I did.
            </Paragraph>
          </Col>
          <Col className="px-16" id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108067/Logikeep/Img3_thoh7u.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              PETER
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              I just want to say thank you Logikeep
              <br /> for making me more aware of my transactions.
            </Paragraph>
          </Col>
        </DivContainer>
      </ContentContainer>

      <div className="flex flex-col justify-center text-center space-y-3 py-12 feedback">
        <h1
          className="lg:px-44 text-2xl text-black "
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          WANT TO HEAR FROM US?
        </h1>
        <p className=" lg:px-[400px]" data-aos="zoom-out-right text-center">
          We love to keep our customers in the loop of current happenings
          concerning our services. Subscribe to our Newsletter.
        </p>

        <div className="md:flex-row md:justify-center px-3">
          <div className="md:max-w-md lg:col-span-2 mt-4 sm:mt-4 space-y-3  mx-auto">
            <form className="mt-4 flex">
              <input
                placeholder="Type your Email Address"
                required
                type="text"
                className="w-full h-12 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              />
              <button
                type="submit"
                className="inline-flex items-center mt-2 justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md  focus:shadow-outline focus:outline-none bg-BACKGROUND_BLUE"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
