import React, { useEffect, useState } from "react";
import { data } from "../../constants";
import SkeletonLoader from "../../components/loaders/WeeksLoader";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

const WeekMonth = () => {
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [detail, setDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dashboardDetails?.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setDetail(dashboardDetails);
    }
  }, [dashboardDetails, isLoading]);

  const { theme } = useGlobalContext();
  const classes = classnames({
    "flex flex-col space-y-16 hover:cursor-pointer rounded-3xl p-5 transition duration-500 ease-in-out bg-white h-full w-full md:w-[132px] shadow-lg":
      theme === "light",
    "flex flex-col space-y-16 hover:cursor-pointer rounded-3xl p-5 bg-slate-800 text-white transition duration-500 ease-in-out bg-opacity-80 h-full w-full md:w-[132px] shadow-lg":
      theme === "dark",
  });

  const cards = data.weekMonth.map((item) => {
    return (
      <div key={item.id} className='lg:w-36 w-full h-full md:max-h-full'>
        {isLoading ? (
          <div className=''>
            <SkeletonLoader />
          </div>
        ) : (
          <div className={classes}>
            <div className='w-10 self-center'>
              <p className='text-4xl'>{item.icon}</p>
        
            </div>
            <div className='totals__item flex flex-col text-center'>
              
              <div className=''>
                <div className='totals__item__title'>
                  <p>{item.title}</p>
                </div>
              </div>
              {item.id === 1 ? (
                <div className='totals__item__value'>
                  <h1 className='space-x-1'>
                    <span className='text-base text-[#379237]'>
                      {detail?.currencysymbol}
                    </span>
                    <span className='text-base text-[#379237]'>
                      {detail?.totalweeklysalees}
                    </span>
                  </h1>
                </div>
              ) : (
                <div className='totals__item__value'>
                  <h1 className='space-x-1'>
                    <span className='text-base text-[#CF0A0A]'>
                      {detail?.currencysymbol}
                    </span>
                    <span className='text-base text-[#CF0A0A]'>
                      {" "}
                      {detail?.totalweeklyexpenses}
                    </span>
                  </h1>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div>
      <div className='flex md:flex-row gap-3 md:gap-0 h-full'>{cards}</div>
    </div>
  );
};

export default WeekMonth;
