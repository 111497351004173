import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import redeem from "../../../assets/images/redeem.png";
import Timestamp from "../../../components/Timestamp";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import { useGlobalContext } from "../../../context";
import classnames from "classnames";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { appUrls } from "../../../services/urls";
import Swal from "sweetalert2";
import Axios from "axios";
import {
  loginFailure,
  loginRequest,
  loginReset,
  loginSuccess,
} from "../../../redux/actions/auth/authActions";

const RedeemPoint = () => {
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [boardDetails, setBoardDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [convertAirtime, setConvertAirtime] = useState();
  const [error, setError] = useState();
  const [formData, setFormData] = useState();
  const navigate = useNavigate();
  let msg;

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const handleAirtime = async (e) => {
    e.preventDefault();
    const data = {
      phone_number: formData?.phone_number,
    };

    setIsLoading(true);
    Axios.post(
      `https://api.logikeep.africa/api/v1/convert-to-airtime`,
      data,
      config
    )
      .then((response) => {
        const data = response.data.data;
        msg = response.data.message;
        setConvertAirtime(data);
        Swal.fire({
          icon: "success",
          title: "Reminder Sent!",
          text: msg,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err?.message);
        setIsLoading(false);
        msg = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: msg,
        });
        setTimeout(() => {}, 3000);
      });
  };

  useEffect(() => {
    if (dashboardDetails.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setBoardDetails(dashboardDetails);
    }
  }, [dashboardDetails, isLoading]);
  return (
    <div className='my-10 text-black'>
      <p className={classnames}>
        Convert your points to <span className='text-blue'>AIRTIME</span> or{" "}
        <span className='text-blue'>DATA</span>
      </p>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='flex gap-4 my-10 md:my-0'>
          <button
            className='bg-white p-2 px-6 rounded-md  shadow-xl'
            onClick={() => setIsModalShown(true)}
          >
            Convert to <span className='text-blue font-bold'>AIRTIME</span>
          </button>
          <button
            className='bg-white p-2 px-6 rounded-md  shadow-xl'
            onClick={() => setIsModalShown(true)}
          >
            Convert to <span className='text-blue font-bold'>DATA</span>
          </button>
        </div>
        <div className='bg-white p-2 px-6 rounded-md w-fit  shadow-xl'>
          <h2 className='font-bold'>
            Total Points:{" "}
            <span className='text-blue font-bold'>
              {boardDetails?.totalpoints}
            </span>
          </h2>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-10'>
        <div className='flex items-center justify-center p-12'>
          <img src={redeem} alt='redeem' />
        </div>
        <div className='py-12'>
          <div className='bg-white p-4 rounded-md shadow-xl h-fit'>
            <h2 className='font-bold text-xl '>Point History</h2>
            {boardDetails?.pointhistory.length < 1 && (
              <h2>No History Available</h2>
            )}
            <div className='my-5'>
              {boardDetails?.pointhistory?.map(
                ({ created_at, amount, details }, index) => {
                  return (
                    <div
                      key={index}
                      className='grid grid-cols-3 gap-2 text-xs my-2'
                    >
                      <Timestamp time={created_at.slice(0, 10)} />
                      <h2>{details}</h2>
                      <h2 className='text-green-700'>{amount} points</h2>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalShown && (
        <div className='fixed top-0 right-0 w-screen h-screen flex flex-col justify-center items-center bg-trans z-50'>
          <div className='bg-white p-8 rounded-md w-[85%] md:w-fit mx-auto relative z-50'>
            <h2
              className='text-xs text-red-500 underline italic cursor-pointer select-none text-right'
              onClick={() => setIsModalShown(false)}
            >
              Close
            </h2>
            {/* <div>
              <select
                name=''
                id=''
                className='block w-full p-3 rounded-md bg-slate-100 shadow-sm my-4'
              >
                <option value=''>Select Network</option>
                <option value='mtn'>MTN</option>
                <option value='glo'>GLO</option>
                <option value='airtel'>Airtel</option>
                <option value='9mobile'>9 Mobile</option>
              </select>
            </div> */}
            <div className='flex justify-between'>
              <label className='block' htmlFor='bvn'>
                Phone Number
              </label>
            </div>
            <input
              type='tel'
              id='bvn'
              name='phone_number'
              placeholder='+234'
              className='w-full border-[1px] my-2 shadow-sm border-gray'
              onChange={handleFormDataChange}
            />
            <button
              className='bg-blue p-3 px-8 w-full my-2 text-white'
              onClick={handleAirtime}

              // onClick={getAccount}
            >
              Convert
              {/* {isLoading ? <Spinner size={20} /> : "Convert"} */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RedeemPoint;
