import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useGlobalContext } from "../../context";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";

const Edit = () => {
  const {
    isEdit,
    currentItem,
    handleEditInputChange,
    closeModal,
    updateHistory,
    isLoading,
  } = useGlobalContext();
  const [isDateRevelead, setIsDateRevealed] = useState(false);
  useEffect(() => {
    if (currentItem.status === "part_payment") {
      setIsDateRevealed(true);
    } else if (currentItem.status === "paid") {
      setIsDateRevealed(false);
    }
  }, [currentItem]);
  return (
    <div
      className={`${
        isEdit ? "flex" : "hidden"
      } bg-trans fixed min-h-screen top-0 left-0  items-center justify-center flex-col w-full`}
    >
      <div className='max-w-[500px] bg-white shadow-md p-8'>
        <AiOutlineClose className='text-2xl ml-auto' onClick={closeModal} />
        <h2 className='font-bold text-xl my-4'>Edit Payment Status</h2>
        <form action='' onSubmit={updateHistory}>
          <label className='block' htmlFor='paid'>
            Amount Paid
          </label>
          <input
            name='paid'
            className='w-full bg-gray p-2 mb-2'
            type='text'
            required
            onChange={handleEditInputChange}
          />
          <label className='block' htmlFor='paid'>
            Payment Type
          </label>
          <select
            className='w-full bg-gray p-2 mb-2'
            name='type'
            id=''
            onChange={handleEditInputChange}
            required
          >
            <option value=''>Select Payment Type</option>
            <option value='cash'>Cash</option>
            <option value='pos'>POS</option>
            <option value='bank_transfer'>Bank Transfer</option>
          </select>
          <label className='block' htmlFor='paid'>
            Payment Status
          </label>
          <select
            className='w-full bg-gray p-2 mb-2'
            name='status'
            value={currentItem.status}
            id=''
            onChange={handleEditInputChange}
            required
          >
            <option value=''>Select Payment Status</option>
            <option value='paid'>Full Payment</option>
            <option value='part_payment'>Part Payment</option>
          </select>
          {isDateRevelead && (
            <div>
              <label className='block' htmlFor='paid'>
                Date to Balance
              </label>
              <input
                name='date'
                className='w-full bg-gray p-2 mb-2'
                type='date'
                onChange={handleEditInputChange}
              />
            </div>
          )}
          <button
            type='submit'
            className='bg-blue p-3 my-6 text-center w-full text-white rounded-md'
          >
            {isLoading ? <Spinner size={20} /> : "Proceed"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Edit;
