import React from "react";

import "./weeks.css";

const WeeksLoader = () => {
  return (
    <>
      <div className="weeks-loader ">
        <div className="weeks-loader__block"></div>
        <div className="weeks-loader__block"></div>
        <div className="weeks-loader__block"></div>
      </div>
    </>
  );
};

export default WeeksLoader;
