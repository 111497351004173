import React, { useEffect } from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Polygon from "../../../assets/images/Polygon1.png";
import Ellipse1 from "../../../assets/images/Ellipse2.png";
import Ellipse2 from "../../../assets/images/Ellipse1.png";
import TopEllipse from "../../../assets/images/Ellipsetop.png";
import Frame from "../../../assets/images/frame.png";
// import Smart from "../../../assets/images/smart.jpeg";
import Smart from "../../../assets/images/updated-hero.png";
import Fade from "react-reveal/Fade";
import styled, { keyframes } from "styled-components";
import { flip } from "react-animations";
import Zoom from "react-reveal/Zoom";

const flipper = keyframes`${flip}`;
const FlipDiv = styled.div`
  animation: 10s infinite ${flipper};
`;

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const DivContainer = styled.div`
  ${tw`grid gap-5 lg:gap-0 md:grid-cols-2 lg:grid-cols-2`}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col lg:justify-self-center`}
`;
const ColFull = styled.div`
  ${tw`lg:justify-self-center`}
`;
const TextAlign = styled.div`
  ${tw`flex flex-col justify-center`}
`;
export const Header = styled.h1`
  ${tw`text-2xl font-bold text-black`}
`;
export const Paragraph = styled.p`
  ${tw`text-lg mt-4 text-black`}
`;
export const SignBtn = styled.div`
  ${tw`text-sm rounded-lg w-28 p-2 
text-center justify-center ml-5`}
`;

export default function Content() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer className="bg-white lg:-mt-20 mt-[75px]" id="content2">
        <div className="relative -top-10">
          <img src={TopEllipse} alt="ellipse" className="topEllipse" />
        </div>
        <DivContainer id="contentContainer">
          <Col className="relative">
            <div className="w-3 md:w-6 lg:w-12 absolute -top-10 md:-top-20 lg:-top-16 left-10">
              <FlipDiv>
                <img className="animate-pulse" src={Polygon} alt="polygon" />
              </FlipDiv>
            </div>
            <div className="w-12 lg:w-20 right-20 md:right-64 lg:right-32 absolute -top-10 md:-top-10 lg:top-0 animate-pulse drop-shadow-2xl">
              <img className="animate-pulse" src={Ellipse1} alt="ellipse1" />
            </div>

            <div
              className="w-24 lg:w-32 absolute md:top-5 lg:top-16 left-3"
              data-aos="zoom-in-right"
            >
              <img className="animate-pulse" src={Ellipse2} alt="ellipse2" />
            </div>
            <div className="lg:mt-16 md:ml-4">
              <Fade right>
                <Header className="lg:pr-32" id="header" data-aos="zoom-out-up">
                  We take care of{" "}
                  <span class="text-blue text-4xl font-sans">Everything</span>{" "}
                  for you, freeing up your time to focus on what you do best.
                </Header>
              </Fade>

              <div
                className="lg:pr-32 text-lg mt-4 text-black"
                id="paragraph"
                data-aos="fade-right"
              >
                With over 2 Million users nationwide,
                <p className="-mt-1 text-lg mt-4 text-black">
                  <span className="text-blue md:text-black font-black md:font-normal mr-1">
                    Logikeep
                  </span>
                  helps businesses keep a proper book-keeping of their business
                  transactions.
                </p>
              </div>

              {/* <div data-aos="fade-left">
                <div>
                  <img src={Frame} alt="ellipse" className="frame w-32" />
                </div>
                <SignBtn className="bg-BACKGROUND_BLUE">
                  <Link to="/register">
                    <h1 class="text-white animate-pulse">Get Started</h1>
                  </Link>
                </SignBtn>
              </div> */}

              <div
                data-aos='fade-left'
                className='flex justify-center md:justify-start'
              >
                 <div>
                 <img src={Frame} alt='ellipse' className='frame w-40' />
                 </div>
                <SignBtn className='bg-BACKGROUND_BLUE self-center'>
                  <Link to='/register'>
                    <h1 class='text-white animate-pulse text-center'>Get Started</h1>
                  </Link>
                </SignBtn>
              </div>
            </div>
          </Col>
          <ColFull>
            <Zoom>
              <div className="lg:w-[450px]">
                <img
                  src={Smart}
                  alt="img"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                />
              </div>
            </Zoom>
          </ColFull>
        </DivContainer>
      </ContentContainer>
    </>
  );
}
