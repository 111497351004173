import React, { useState } from "react";
import {
  Container,
  FormContainer,
  OverlayContainer,
  Main,
} from "../../styles/styledElements";
import { useNavigate } from "react-router-dom";
import Logo from "../../pages/home/Logo";
import Question from "../../assets/images/question.png";
import styled, { keyframes } from "styled-components";
import { merge, fadeIn, bounce } from "react-animations";
import {
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordReset,
  forgotPasswordSuccess,
} from "../../redux/actions/auth/changePassword/forgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../..//services/api";
import { appUrls } from "../../services/urls";
import { useParams } from "react-router-dom";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import Swal from "sweetalert2";


const bounceFadeIn = merge(bounce, fadeIn);

const bounceAnimation = keyframes`${bounceFadeIn}`;
const BouncyDiv = styled.div`
  animation: 10s infinite ${bounceAnimation};
`;

function ForgotPassword() {
  const [formData, setFormData] = useState();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("BearerToken");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleforgotPasswordSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: formData?.email,
    };

    // call update api with filed
    dispatch(forgotPasswordRequest());
    setIsLoading(true);
    await api
      .post([appUrls.forgetPasswordUrl, data, config])
      .then((response) => {
        dispatch(forgotPasswordSuccess(response));
        setIsLoading(false);
  

        Swal.fire({
          icon: "success",
          title: "Kindly Check Your Mailbox",
          text: response?.message,
          button: "Ok!",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
        navigate("/reset-password");

      })
      .catch((err) => {
        dispatch(forgotPasswordFailure(err?.data));
        setIsLoading(false);
        setTimeout(() => {
          dispatch(forgotPasswordReset());
        }, 1000);
        setMessage(err?.data?.message);
      });
  };

  return (
    <>
      <Main className="p-5 lg:p-0 lg:h-screen">
        <Container className="lg:h-full w-full lg:rounded-none rounded-lg grid lg:grid-cols-2 bg-white lg:bg-slate-200">
          <FormContainer id="sign-in-container">
            <div className="pb-5 flex justify-between">
              <Logo />
            </div>
            <div className="lg:hidden bg-[#c7d3f3]" id="overlay">
              <BouncyDiv>
                <img src={Question} id="imgBxForgot" alt="imgBxForgot" />
              </BouncyDiv>
            </div>
            <div className="lg:px-20 space-y-5 self-center">
              <h3 className="text-blue text-lg font-semibold">
                Forgot Password ?
              </h3>
              <p class="text-black">
                We will send a link to you which will help you reset <br /> your
                password.
              </p>
              {/* <label className="ml-6 text-sm">Email Address</label> */}
              <form action="#" className="">
                {/* <Logo /> */}

                <input
                  type="text"
                  placeholder="Enter your email address"
                  className="border-b-2 border-blue outline-none text-sm bg-transparent"
                  onChange={handleFormDataChange}
                  name="email"
                />
                {message && (
                  <div className="text-red-600 w-62 text-center mt-4 text-sm">
                    {message}
                  </div>
                )}
                <button
                  className="submitButton relative"
                  type="submit"
                  onClick={handleforgotPasswordSubmit}
                  id="btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent="warning" size={20} />
                  ) : (
                    "forgot password"
                  )}
                </button>
              </form>
            </div>
          </FormContainer>
          <OverlayContainer className="hidden lg:flex justify-center" >
            <BouncyDiv >
              <img
                className="h-full -full"
                src={Question}
                id="imgBxForgot"
                alt="imgBxForgot"
              />
            </BouncyDiv>

            {/* <div class="overlay">
              <div class="overlay-panel overlay-right">
                <img src="/assets/LogikeepLogo/LogikeepLogo.png"  id="imgBxForgot" alt="img" />
              </div>
            </div> */}
          </OverlayContainer>
        </Container>
      </Main>
    </>
  );
}

export default ForgotPassword;
