export const data = {
  texts: {
    h1: "Subscribe to Premium",
    p:
      "Get access to unlimited email units, virtual business cards and points to recharge card feature when you upgrade your account to Classic with just #500 Monthly.",
    img: "/assets/imagePerson.svg",
  },
  button: {
    id: 1,
    text: "Upgrade Account",
  },
};
