import React from "react";
import { Icon } from "@iconify/react";

function SearchBox({ placeholder, onChange, value }) {
  return (
    <div className="md:w-64 w-full relative">
      <input
        onChange={onChange}
        value={value}
        className="p-2 pl-8 shadow-md"
        type="text"
        placeholder={placeholder}
      />
      <Icon
        className="absolute top-5 left-3"
        icon="ant-design:search-outlined"
      />
    </div>
  );
}

export default SearchBox;
