import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import manage from "../../../assets/images/manage.jpeg";
import styled, { keyframes } from "styled-components";
import { Spinner } from "@blueprintjs/core";
import Axios from "axios";
import Swal from "sweetalert2";

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const DivContainer = styled.div`
  ${tw`grid gap-5 lg:gap-0 md:grid-cols-2 lg:grid-cols-2`}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col lg:justify-self-center`}
`;
const ColFull = styled.div`
  ${tw`lg:justify-self-center`}
`;

export default function Section() {
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState();
    useEffect(() => {
      AOS.init({ duration: 2000 });
      AOS.refresh();
    }, []);
    const handleFormDataChange = (event) => {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const data = {
        name: formData?.name,
        email: formData?.email,
        message: formData?.message,
       
      };
     setIsLoading(true);
      Axios
        .post(`https://api.logikeep.africa/api/v1/contactus`, data)
        .then((response) => {
         console.log(response);
         setMessage(response.data.message);
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Submitted Successfully",
            text: response.data.message,
            button: "Ok!",
          });
          
        })
         .catch((err) => {
          
          setError(err?.data?.message);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops! Error Occured",
            text: error.data.message,
            button: "Ok!",
          });
          
        });
    };
    return (
      <>
        <ContentContainer className="bg-blue-100 lg:-mt-20 mt-[75px]" id="content2">
        <h2 className="text-blue text-center mt-20 pt-10 font-bold text-3xl">Get In Touch</h2>
        <p className="text-center mt-3"> We are here for you? How can we help?</p>
          <DivContainer id="contentContainer">
          <ColFull>
              
                <div className="lg:w-[500px] pt-8">
                  <img
                    src={manage}
                    alt="img"
                    // data-aos="fade-left"
                    // data-aos-duration="3000"
                  />
                </div>
           
            </ColFull>
           
            <Col className="relative">
          <form action='#' className='' onSubmit={handleSubmit}>
            <div className="m-8">
          <div className='w-full mb-2'>
           <input
            name="name"
            className='p-3 bg-blue-200 rounded-md w-full my-2'
            placeholder="Enter Your name"
            onChange={handleFormDataChange}
            type="text"
            />
          </div>
           <div className='w-full mb-2'>
           <input
            name="email"
            className='p-3 bg-blue-200 rounded-md w-full my-2'
            placeholder="Enter Your Email Address"
            onChange={handleFormDataChange}
            type="email"
            />
          </div>
            <div className='w-full mb-2'>
           <textarea
            name="message"
            className='p-3 bg-blue-200 rounded-md w-full my-2'
            placeholder="Enter Your Message"
            onChange={handleFormDataChange}
            type="text">
           </textarea>
          </div>
         
        <button className='justify-center text-white  ml-auto hover:scale-95 transition rounded-md shadow-xl w-full my-6 p-5 bg-[#4B56D2]'
        type='submit'
                  id='btn'
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent='warning' size={20} />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
         </div>
          </form>
            </Col>
          </DivContainer>
        </ContentContainer>
      </>
    );
  }
