import React, { useState, useEffect } from "react";
import SkeletonLoader from "../../components/loaders/BlockLoader";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context";
import classnames from "classnames";
function ReferralCode({ active, handleActive }) {
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [boardDetails, setBoardDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dashboardDetails?.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setBoardDetails(dashboardDetails);
    }
  }, [dashboardDetails, isLoading]);
  const shareURL = boardDetails?.referral_link;

  const { theme } = useGlobalContext();
  const classes = classnames({
    "hover:bg-blue bg-white transition duration-500 ease-in-out":
      theme === "light",
    "bg-slate-800 text-white transition duration-500 ease-in-out":
      theme === "dark",
  });
  return (
    <>
      {isLoading ? (
        <div className=''>
          <SkeletonLoader />
        </div>
      ) : (
        <div
          className={
            active === 1
              ? "flex flex-col space-y-10 bg-blue  text-white p-8 w-full rounded-3xl bg-opacity-80"
              : `flex flex-col space-y-10  ${classes}  bg-opacity-80 hover:text-white p-5 w-full rounded-3xl hover:cursor-pointer`
          }
          // onClick={() => handleActive(1)}
        >
          <div className='flex'>
            <div className='flex space-x-5'>
              <div className='title self-center'>
                <h1 className='text-base'>
                  Referral Code: {boardDetails?.referral_code}
                </h1>
                <h1 className='text-base'>
                  Total Referral: {boardDetails?.total_referer}
                </h1>
                <div className='text-green-600 hover:text-green-200 mt-4 flex gap-2 text-xs items-start'>
                  {/* <i>
                    <BsFillShareFill />
                  </i> */}
                  <p className='text-xs'>
                    Share your referral link with family and friends to earn 5
                    points
                  </p>
                </div>
                <div className='flex gap-3 items-center'>
                  <WhatsappShareButton url={shareURL}>
                    <WhatsappIcon round size={30} />
                  </WhatsappShareButton>
                  <TwitterShareButton url={shareURL}>
                    <TwitterIcon round size={30} />
                  </TwitterShareButton>
                  <FacebookShareButton url={shareURL}>
                    <FacebookIcon round size={30} />
                  </FacebookShareButton>
                  <LinkedinShareButton url={shareURL}>
                    <LinkedinIcon round size={30} />
                  </LinkedinShareButton>
                  <TelegramShareButton url={shareURL}>
                    <TelegramIcon round size={30} />
                  </TelegramShareButton>
                </div>
              </div>
            </div>
            {/* <div className="totals__item__value ">
                <Icon icon="akar-icons:arrow-right" />
              </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default ReferralCode;
