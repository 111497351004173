import React from 'react'
import Section from "./Section";
import Navbar from "../../../pages/home/Navbar/index";

const Contact = () => {
    return (
        <>
        <Navbar/>
        <Section/>
       
        </>
       )
}

export default Contact
