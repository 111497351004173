import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import SkeletonLoader from "../../components/loaders/BlockLoader";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context";
import classnames from "classnames";
import Swal from "sweetalert2";

function TotalPoints({ active, handleActive, isData }) {
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [boardDetails, setBoardDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboardDetails?.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setBoardDetails(dashboardDetails);
    }
  }, [dashboardDetails, isLoading]);

  const { theme } = useGlobalContext();
  const classes = classnames({
    "hover:bg-blue bg-white transition duration-500 ease-in-out":
      theme === "light",
    "bg-slate-800 text-white transition duration-500 ease-in-out":
      theme === "dark",
  });

  const handleRedeem = () => {
    if (dashboardDetails?.plan === "free")
      Swal.fire(
        "Not Permitted",
        "Upgrade your account to use this service.",
        "warning"
      );
    else {
      navigate("redeem");
    }
  };

  return (
    <>
      {isLoading ? (
        <div className=''>
          <SkeletonLoader />
        </div>
      ) : (
        <div
          className={
            active === 3
              ? "flex flex-col space-y-10 bg-blue text-white p-5 w-full rounded-3xl bg-opacity-80"
              : `flex flex-col space-y-10  ${classes}  bg-opacity-80 hover:text-white p-5 w-full rounded-3xl hover:cursor-pointer`
          }
          onClick={() => handleActive(3)}
        >
          <div className='flex justify-between'>
            <div className='flex space-x-5'>
              <div className='title self-center'>
                <h1 className='text-xl text-center'>Total Points</h1>
              </div>
            </div>
            <div className='totals__item__value'>
              <Icon icon='akar-icons:arrow-right' />
            </div>
          </div>

          <div className='flex justify-between'>
            <div className='flex items-center gap-4'>
              <p className=''>{boardDetails?.totalpoints}</p>
              <h2
                className='text-green-400 hover:text-white cursor-pointer select-none'
                // to='redeem'
                onClick={handleRedeem}
              >
                Redeem Points
              </h2>
            </div>
            <div className='flex justify-between'>
              <div className='space-y-5'>
                <Icon className='text-4xl text-black' icon='la:coins' />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TotalPoints;
