import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContentContainer } from ".";
import Vector from "../../../assets/images/circle.svg";

const DivContainer = styled.div`
  ${tw`grid md:grid-cols-2 lg:gap-10 lg:grid-cols-2`}
`;

const Header = styled.h1`
  ${tw`text-4xl font-medium text-black text-center`}
`;
export default function SubFooter() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer id="content" className="space-y-5 lg:space-y-20">
        {/* <Header
        {/* <Header
          id="header4"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          Your Details Are Safe With Us
        </Header> */}
        <DivContainer className="space-y- ">
          <div
            className="lg:w-full space-y-2 lg:space-y-10"
            data-aos="zoom-out-right"
          >
            <div>
              <div className="flex space-x-5 mt-2">
                <img
                  className="w-8 md:w-10 self-center"
                  src={Vector}
                  alt="vector"
                />
                <p class="text-base font-bold text-black">
                  Generate Invoices quickly with our Invoice generator <br />
                  <span className="font-light">
                    Every invoice paid equals more revenue for your business.
                  </span>
                 
                </p>
              </div>
            </div>
            <div className="lg:w-full" data-aos="zoom-out-left">
              <div className="flex space-x-5 mt-2">
                <img
                  class="w-8 md:w-10 h-full self-center"
                  src={Vector}
                  alt="vector"
                />
                <p class="text-base font-bold text-black">
                  No Paper Required
                  <br />
                  <span className="font-light">
                    Stay on top of your business: add products, record sales,
                    and check available stock across your stores.
                  </span>
                 
                </p>
              </div>
            </div>
          </div>
          <div
            className="lg:w-full space-y-2 lg:space-y-10"
            data-aos="zoom-out-right"
          >
            <div>
              <div className="flex space-x-5">
                <img
                  className="w-8 md:w-10 self-center"
                  src={Vector}
                  alt="vector"
                />
                <p class="text-base font-bold text-black">
                  Recurring billing for repeat customers and subscriptions{" "}
                  <br />
                  <span className="font-light">
                    Get paid on time, every time. Set up recurring invoices and
                    automatic <br /> payments for returning customers.
                  </span>
                </p>
              </div>
            </div>

            <div className="lg:w-full" data-aos="zoom-out-left">
              <div class="flex space-x-5">
                <img
                  className="self-center w-8 md:w-10 h-full"
                  src={Vector}
                  alt="vector"
                />
                <p class="text-base font-bold text-black">
                  Instant notification to restock <br />
                  <span className="font-light">
                    Get notified when your inventory is running low, so you can
                    restock <br /> and never miss a sal. A reminder is
                    automatically sent every 24 hours.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </DivContainer>
      </ContentContainer>
    </>
  );
}
