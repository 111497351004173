import React, { useEffect, useState } from "react";
import "../../../styles/responsiveness.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginFailure,
  loginRequest,
  loginReset,
  loginSuccess,
} from "../../../redux/actions/auth/authActions";
import { api } from "../../../services/api";
import Swal from "sweetalert2";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";

const passwordRevealIcon = {
  isOpen: false,
  isType: true,
};

const Otp = () => {
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState();
  
  const [refCode, setRefCode] = useState("");

  const navigate = useNavigate();
  const param = useParams();
  //Get Referral Code from the query params
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    const ref_code = query.get("ref_code");
    setRefCode(ref_code);
  }, []);

  const errorDiv = error ? (
    <small className='text-red-600 text-center w-52 mt-4'>{error}</small>
  ) : (
    ""
  );



  const baseUrl = `https://api.logikeep.africa/api/v1/verifyotp`;

 

  

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const data = {
      otp: formData?.otp,
     
    };

    dispatch(loginRequest());
    setIsLoading(true);
    await api
      .post([baseUrl, data])
      .then((res) => {
        dispatch(loginSuccess(res));
        
        setIsLoading(false);
        Swal.fire({
            icon: "success",
            title: "Thank you",
            text: res.message,
            button: "Ok!",
          });
        navigate("/success-page");
      })

      .catch((err) => {
        dispatch(loginFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(loginReset());
        }, 3000);
      });
  };
 



  return (
    <div>
       <div className="flex  flex-wrap h-full  g-6 text-gray-800 ">
        
         
        <div className="lg:flex lg:flex-wrap g-0 ">
          <div className="lg:w-6/12 px-4 md:px-0">
            <div className="md:p-12 md:mx-6">
              <div className="text-center">
              <img
              src="/assets/LogikeepLogo/LogikeepLogo.png"
              id="imgBxForgot"
              alt="img"
              className="h-8 w-15 "
            />
             
              <h2 className='mt-8 mb-8 text-center text-2xl font-normal text-[#362FD9]'>
          Authorize your account
        </h2>
              </div>
              <form action='#' className='' onSubmit={handleLoginSubmit}>
              <h5 className="mb-2 font-normal text-sm text-blue-800 text-center ">Kindly check your mail for OTP</h5>
                <input
                  type='tel'
                  placeholder='Enter OTP Code'
                  className='shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73]'
                  name='otp'
                  onChange={handleFormDataChange}
                  id='otp'
                  required
                />
              
             

                {errorDiv}
                <button
                  className='submitButton '
                  type='submit'
                  id='btn'
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent='Verifying' size={20} />
                  ) : (
                    "VERIFY"
                  )}
                </button>
                <div className='mb-4 text-center'>
                  <Link
                        to='/resendotp'
                        className='font-normal text-sm text-blue  '
                    >
                        Click here to resend OTP
                    </Link>
               </div>
              </form>
            </div>
          </div>
          <div
            className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-gradient-to-r from-indigo-500  to-cyan-100  "
            id='overlay'
            
          >
            <div className="text-white px-4 py-6 md:p-12 md:mx-6 ">
            <img
              src="/assets/registerImage.svg"
              id="imgBxForgot"
              alt="img"
            />
           <h2
              className="text-lg text-black font-semibold ml-4 text-center"
              id="imgText"
            >
              Your Book-keeping Team So That You
              <br />
              Can Do What You Love.
            </h2> 
            </div>
          </div>
        </div>
   
  
  </div>
    </div>
  )
}

export default Otp
