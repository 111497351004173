import React from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";

const Grid = ({ data, editFunc, deleteFunc, state }) => {
  return (
    <div className='min-w-[800px] grid p-2 bg-white'>
      <div className='grid grid-cols-4 items-center p-4 font-bold px-8 bg-[#EFEFEF] text-sm text-black'>
        <h2>Customer's Name</h2>
        <h2>Email Address</h2>
        <h2>Phone Number</h2>
        <h2>Action</h2>
      </div>
      {data?.map(({ customer_name, phone_number, id, email }) => {
        return (
          <div
            key={id}
            className='grid grid-cols-4 bg-white p-4 px-8 text-gray-400 items-center text-sm'
          >
            <h2>{customer_name}</h2>
            <h2>
              {!email
                ? ""
                : email?.length < 20
                ? email
                : email?.slice(0, 20) + "..."}
            </h2>
            <h2>{phone_number}</h2>
            <div className='flex gap-4'>
              <i
                className='text-xl bg-sky-200 w-10 p-2 flex items-center justify-center rounded-md'
                onClick={() => editFunc(id, customer_name, phone_number, email)}
              >
                <AiOutlineEdit />
              </i>
              <i
                className='text-xl bg-red-200 w-10 p-2 text-red-600 flex items-center justify-center rounded-md'
                onClick={() => deleteFunc(id)}
              >
                {state ? (
                  <Spinner intent='warning' size={20} />
                ) : (
                  <AiOutlineDelete />
                )}
              </i>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
