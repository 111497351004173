import React from "react";
import { useGlobalContext } from "../../context";
import { AiOutlineDelete } from "react-icons/ai";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import { useState } from "react";
const SingleNotification = ({ details, title, id }) => {
  const { deleteNotification, isLoading, readNotification } =
    useGlobalContext();
  const [isDetailsShown, setIsDetailsShown] = useState(false);

  const readHandler = (id) => {
    setIsDetailsShown(!isDetailsShown);
    readNotification(id);
  };

  return (
    <div className='my-6 bg-white shadow-md rounded-md p-8 py-4 flex gap-4 justify-between items-center'>
      <div>
        <h1 className='text-lg font-semibold text-blue my-2'>{title}</h1>
        {isDetailsShown && (
          <p className='text-sm font-medium text-black'>{details}</p>
        )}
      </div>
      <div className='flex items-center gap-2 text-black'>
        <h2
          className='text-green underline text-xs italic cursor-pointer'
          onClick={() => readHandler(id)}
        >
          {isDetailsShown ? "Hide Details" : "See Details"}
        </h2>
        <i
          className='text-xl bg-red-200 w-10 p-2 text-red-600 flex items-center justify-center rounded-md'
          onClick={() => deleteNotification(id)}
        >
          {isLoading ? (
            <Spinner intent='warning' size={20} />
          ) : (
            <AiOutlineDelete />
          )}
        </i>
      </div>
    </div>
  );
};

export default SingleNotification;
