import React, { useEffect } from "react";
import phone from "../../../assets/images/phone.png";
import round from "../../../assets/images/round.png";
import round2 from "../../../assets/images/round2.png";
import { AiOutlinePlus, AiOutlineCreditCard } from "react-icons/ai";
import { useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useGlobalContext } from "../../../context";
import axios from "axios";
import Swal from "sweetalert2";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import Timestamp from "../../../components/Timestamp";
import classnames from "classnames";
import { useSelector } from "react-redux";


const Wallet = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [isAccountVerifiedLoader, setIsAccountVerifiedLoader] = useState(false);
  const [isPinCreationLoader, setIsPinCreationLoader] = useState(false);
  const [isTransferProcessed, setIsTransferProcessed] = useState(false);
  const [isTransferProcessingLoader, setIsTransferProcessingLoader] =
    useState(false);
  const [accountDetails, setAccountDetails] = useState("");
  const { currency, theme } = useGlobalContext();
  const [balance, setBalance] = useState(0);
  const [account, setAccount] = useState(null);
  const [isBVNshown, setIsBVNshown] = useState(false);
  const [isAmountShown, setIsAmountShown] = useState(false);
  const [bvn, setBvn] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(0);
  const [amount, setAmount] = useState(0);
  const buttons = ["All Transactions", "Top Up", "Withdrawal"];
  const [allTransactions, setAllTransactions] = useState([]);
  const [isWithdrawalModalOpen, setisWithdrawalModalOpen] = useState(false);
  const [allBanks, setAllBanks] = useState([]);
  const [bankDetails, setBankDetails] = useState({});
  const [isPinCreated, setIsPinCreated] = useState(false);
  const [createdPin, setCreatedPin] = useState("");
  const [pinCreationError, setPinCreationError] = useState("");
  const [pinCreationSuccess, setPinCreationSuccess] = useState("");
  const [accountCreationError, setAccountCreationError] = useState("");
  const [transferProcessingError, settransferProcessingError] = useState("");
  useState("");
  const [transferCharges, setTransferCharges] = useState(0);
  const [totalTransferAmount, setTotalTransferAmount] = useState(0);
  const [narration, setnarration] = useState("");
  const [pin, setPin] = useState("");
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);

  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  let url;
  const token = localStorage.getItem("BearerToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getWalletBalance = async () => {
    url = `${baseUrl}walletbalance`;
    try {
      const res = await axios.get(url, config);
      const data = await res.data;
      if (data) {
        setBalance(data.data);
        setIsPinCreated(data.transfer_pin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBankDetails = async () => {
    url = `${baseUrl}uservirtualbankdetails`;
    try {
      const res = await axios.get(url, config);
      const data = await res.data;
      if (data) {
        setAccount(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAccountCreationError("");
      setPinCreationError("");
      settransferProcessingError("");
      setPinCreationSuccess("");
    }, 3000);
  }, [accountCreationError, pinCreationError]);

  const getWalletTransactions = async () => {
    url = `${baseUrl}wallettransactions`;
    try {
      const res = await axios.get(url, config);
      const data = await res.data;
      if (data) {
        setTransactions(data.data);
        setAllTransactions(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAccount = async () => {
    setIsLoading(true);
    url = `${baseUrl}createaccountnumber`;
    const data = { bvn };
    try {
      const res = await axios.post(url, data, config);
      const msg = res.data.message;
      setAccount(res.data.data);
      setIsLoading(false);
      setIsBVNshown(false);
      Swal.fire({
        title: "Action Successful",
        text: msg,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      Swal.fire({
        title: "Oops! Something went wrong",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
      setIsBVNshown(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWalletBalance();
    getBankDetails();
    getWalletTransactions();
  }, [isPinCreated]);

  const handleClick = () => {
    setIsBVNshown(true);
  };

  const activeButtonHandler = (id) => {
    setActiveButton(id);
    let transArray;
    if (id === 0) {
      transArray = allTransactions;
    } else if (id === 1) {
      transArray = allTransactions.filter((t) => t.amount_type === "credit");
    } else {
      transArray = allTransactions.filter((t) => t.amount_type === "debit");
    }
    setTransactions(transArray);
  };

  const makePayment = async () => {
    const url = `${baseUrl}topup`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, { amount }, config);
      setIsLoading(false);
      const link = res.data.url;
      window.location.replace(link);
      console.log(res);
    } catch (error) {
      setIsLoading(false);
      console.log(false);
    }
  };

  const transfer = async () => {
    setIsWithdrawLoading(true);
    try {
      const req = await fetch(`${baseUrl}all-banks`);
      const { data } = await req.json();
      setAllBanks(data);
      setisWithdrawalModalOpen(true);
      setIsWithdrawLoading(false);
    } catch (error) {
      console.log(error);
      setIsWithdrawLoading(false);
    }
  };

  const handleBankDetails = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (bankDetails?.bank_account_number?.length === 10) {
      verifyAccount();
    }
  }, [bankDetails]);

  const handleCreatedPin = (e) => {
    setCreatedPin({ ...createdPin, [e.target.name]: e.target.value });
    // console.log(createdPin);
  };

  const verifyAccount = async () => {
    setIsAccountVerifiedLoader(true);
    const url = `${baseUrl}resolve-account`;
    const DATA = {
      bank_id: bankDetails.bank_id,
      account_number: bankDetails.bank_account_number,
    };

    try {
      const { data } = await axios.post(url, DATA, config);
      setAccountDetails(data);
      setIsAccountVerified(true);
      setIsAccountVerifiedLoader(false);
    } catch (error) {
      setAccountDetails("");
      setIsAccountVerifiedLoader(false);
      setIsAccountVerified(false);
      setAccountCreationError(error.response.data.message);
    }
  };

  const createPin = async () => {
    const url = `${baseUrl}create-transfer-pin`;
    setIsPinCreationLoader(true);
    try {
      const res = await axios.post(url, createdPin, config);
      console.log(res);
      setIsPinCreationLoader(false);
    } catch (error) {
      console.log(error);
      setIsPinCreationLoader(false);
      setPinCreationError(error.response.data.message);
    }
  };

  const getCharges = async () => {
    setIsTransferProcessingLoader(true);
    const url = `${baseUrl}transfer-fees`;
    try {
      const res = await axios.post(url, { amount }, config);
      setIsTransferProcessed(true);
      setTransferCharges(res.data.data);
      setIsTransferProcessingLoader(false);
    } catch (error) {
      settransferProcessingError(error.response.data.message);
      setIsTransferProcessingLoader(false);
    }
  };

  // console.log(transferCharges);

  useEffect(() => {
    setTotalTransferAmount(Number(transferCharges) + Number(amount));
  }, [transferCharges]);

  const completeTransfer = async () => {
    setIsLoading(true);
    const url = `${baseUrl}submit-transfer`;
    const data = {
      amount: totalTransferAmount,
      bank_id: bankDetails?.bank_id,
      account_number: accountDetails?.account_number,
      narration,
      pin,
      transfer_charges: transferCharges,
    };
    // console.log(data);

    try {
      const res = await axios.post(url, data, config);
      console.log(res);
      setIsLoading(false);
      Swal.fire({
        title: "Action Successful",
        text: res.data.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      getWalletBalance();
      setisWithdrawalModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      Swal.fire({
        title: "Ooops!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const classes = classnames({
    "bg-black text-white p-8 rounded-md transition duration-500 ease-in-out":
      theme === "light",
    "text-black p-8 rounded-md transition duration-500 ease-in-out bg-opacity-80 bg-white":
      theme === "dark",
  });

  return (
    <div className='p-4'>
      <div className='flex flex-col md:flex-row gap-6'>
        {!account && (
          <button
            className='bg-[#0118E8] px-8 p-3 flex items-center gap-2 rounded-md shadow-md'
            onClick={handleClick}
          >
            <img src={phone} className='w-10' alt='phone' />
            <h2 className='text-white text-sm'>Create your business account</h2>
          </button>
        )}

        {/* <button className='bg-black px-8 p-3 flex items-center gap-2 rounded-md shadow-md'>
          <img src={phone} className='w-10' alt='phone' />
          <h2 className='text-white text-sm'>Generate Payment Link</h2>
        </button> */}
      </div>
      <div className='my-10'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
          <div className=''>
            <h2 className='text-lg font-bold my-3'>Wallet Balance</h2>
            <div className={classes}>
              <div className='flex justify-between gap-2 items-center'>
                <h2 className='text-xs'>{account?.Bank_name || "N/A"}</h2>
                <h2 className='text-xs uppercase'>
                  {account?.Account_Name || "N/A"}
                </h2>
              </div>
              <div className='flex justify-between gap-2 items-center'>
                <h2 className='text-3xl'>
                {dashboardDetails?.currencysymbol} {balance}
                </h2>
                <h2
                  className='text-xs underline italic p-1 cursor-pointer select-none'
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? "Hide" : "Show"} Acc
                </h2>
              </div>
              <div className='flex justify-between gap-2 mt-8 flex-col md:flex-row'>
                <div className=''>
                  <div className=''>
                    <h2 className='text-xs'>Account Number</h2>
                  </div>
                  <div>
                    <h2 className='text-lg'>
                      <span>
                        {isVisible
                          ? account?.Account_Number || "N/A"
                          : "XXXXXXXXX"}
                      </span>{" "}
                    </h2>
                  </div>
                </div>
                <div className='flex items-center gap-4 flex-row'>
                  <button
                    className='flex gap-2 items-center bg-[#0118E8] p-3 rounded-md text-sm'
                    onClick={() => setIsAmountShown(true)}
                  >
                    <i className='text-white text-xl'>
                      <AiOutlinePlus />
                    </i>
                    <h2 className='text-xs'>Top Up</h2>
                  </button>
                  <button
                    className='flex gap-2 items-center bg-red-600 p-3 rounded-md text-sm'
                    onClick={transfer}
                  >
                    <i className='text-white text-xl'>
                      <AiOutlineCreditCard />
                    </i>
                    {isWithdrawLoading ? (
                      <Spinner size={14} color='#fff' />
                    ) : (
                      <h2 className='text-xs'>Transfer</h2>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className='my-2 font-bold'>Wallet Transactions</h2>
            <div className='flex gap-4 items-center my-2'>
              {buttons.map((button, i) => {
                return (
                  <button
                    className={`${
                      activeButton === i
                        ? "bg-blue rounded-md text-white p-2"
                        : "border-b-transparent"
                    } transition text-xs`}
                    key={i}
                    onClick={() => activeButtonHandler(i)}
                  >
                    {button}
                  </button>
                );
              })}
            </div>
            <div className='flex flex-col gap-2 overflow-y-scroll scrollbar max-h-[250px] scroll-smooth '>
              {transactions?.length < 1 && (
                <h1 className='text-sm my-3 '>No Transaction History</h1>
              )}
              {transactions?.map(
                ({ amount, amount_type, created_at, transaction_type }, i) => {
                  return (
                    <div className='grid grid-cols-3 gap-3 items-center p-4 bg-white text-xs text-black'>
                      <div className='flex items-center gap-2'>
                        <img
                          src={amount_type === "credit" ? round : round2}
                          className='w-2'
                          alt='rounded'
                        />
                        <h2 className='font-medium text-sm capitalize'>
                          {transaction_type}
                        </h2>
                      </div>
                      <h2>
                        {currency} {amount}
                      </h2>
                      <Timestamp time={created_at.slice(0, 10)} />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      {isBVNshown && (
        <div className='fixed top-0 right-0 w-screen h-screen flex flex-col justify-center items-center bg-trans z-50'>
          <div className='bg-white p-8 rounded-md w-[85%] md:w-fit mx-auto relative z-50'>
            <h2
              className='text-xs text-red-500 underline italic cursor-pointer select-none text-right'
              onClick={() => setIsBVNshown(false)}
            >
              Close
            </h2>
            <h2 className='text-lg font-bold text-slate-800 my-7 text-center'>
              Claim your logikeep virtual account to start receiving payments.
              Logikeep collects additional details to comply with financial
              institutions regulations.
            </h2>
            <div className='flex justify-between text-black'>
              <label className='block' htmlFor='bvn'>
                Enter BVN
              </label>
            </div>
            <input
              type='text'
              id='bvn'
              name='bvn'
              className='w-full border-[1px] my-2 shadow-md border-gray'
              value={bvn}
              placeholder='Please enter your bvn'
              onChange={(e) => setBvn(e.target.value)}
            />
            <button
              className='bg-blue p-3 px-8 w-full my-2 text-white'
              onClick={getAccount}
            >
              {isLoading ? <Spinner size={20} /> : "Generate Account"}
            </button>
          </div>
        </div>
      )}
      {isAmountShown && (
        <div className='fixed top-0 right-0 w-screen h-screen flex flex-col justify-center items-center bg-trans z-50'>
          <div className='bg-white p-8 rounded-md w-[85%] md:w-fit mx-auto relative z-50'>
            <h2
              className='text-xs text-red-500 underline italic cursor-pointer select-none text-right'
              onClick={() => setIsAmountShown(false)}
            >
              Close
            </h2>

            <div className='flex justify-between'>
              <label className='block' htmlFor='bvn'>
                Enter Amount to Top Up
              </label>
            </div>
            <input
              type='number'
              id='amount'
              name='amount'
              className='w-full border-[1px] my-2 shadow-md border-gray'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button
              className='bg-blue p-3 px-8 w-full my-2 text-white'
              onClick={makePayment}
            >
              {isLoading ? <Spinner size={20} /> : "Make Payment"}
            </button>
          </div>
        </div>
      )}

      {isWithdrawalModalOpen && (
        <div className='fixed top-0 right-0 w-screen h-screen flex flex-col justify-center items-center bg-trans z-auto p-4 md:p-8'>
          <div className='bg-white p-8 rounded-md max-h-screen overflow-scroll w-[85%] mx-auto relative z-auto max-w-xl'>
            <h2
              className='text-xs text-red-500 underline italic cursor-pointer select-none text-right'
              onClick={() => {
                setisWithdrawalModalOpen(false);
                window.location.reload();
              }}
            >
              Close
            </h2>
            <div className='rounded-md p-3 shadow-md'>
              <div className='flex justify-between'>
                <label className='block' htmlFor='bvn'>
                  Select Bank
                </label>
              </div>
              <select
                className='w-full border-[1px] my-2 border-gray p-3'
                name='bank_id'
                id='bank'
                onChange={handleBankDetails}
              >
                {allBanks.map((bank, index) => {
                  return (
                    <option key={index} value={bank.code}>
                      {bank.Bank_name}
                    </option>
                  );
                })}
              </select>

              <input
                type='number'
                id='account'
                name='bank_account_number'
                className='w-full border-[1px] my-2 border-gray p-4'
                value={bankDetails?.bank_account_number || ""}
                placeholder='Account Number'
                onChange={handleBankDetails}
                readOnly={isAccountVerifiedLoader}
              />
              <p className='text-xs text-red-600 italic'>
                {accountCreationError}
              </p>

              {isAccountVerifiedLoader && <Spinner size={20} />}

              {isAccountVerified && (
                <div>
                  <input
                    type='name'
                    id='account'
                    name='account_name'
                    className='w-full border-[1px] my-2 border-gray p-4'
                    value={accountDetails?.account_name || ""}
                    placeholder='Account Name'
                    onChange={handleBankDetails}
                    readOnly
                  />
                  <h2 className='text-sm'>Amount to transfer</h2>
                  <div className='flex w-full items-center my-2 border-gray px-3 rounded-md border-[1px]'>
                    <h2>₦</h2>
                    <input
                      type='number'
                      id='amount'
                      name='amount'
                      className='w-full'
                      placeholder='Enter amount to transfer'
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setIsTransferProcessed(false);
                      }}
                    />
                  </div>
                  <p className='text-xs text-red-600 italic'>
                    {transferProcessingError}
                  </p>

                  <button
                    className='bg-slate-400 p-3 px-8 w-full my-2 text-white rounded-md'
                    onClick={getCharges}
                  >
                    {isTransferProcessingLoader ? (
                      <Spinner size={20} />
                    ) : (
                      "Proceed"
                    )}
                  </button>
                </div>
              )}
            </div>
            {isTransferProcessed && (
              <div className='rounded-md p-3 shadow-md mt-10'>
                <h2 className='font-bold'>Trasaction Summary</h2>
                <hr className='my-4 border-slate-200' />
                <div className='flex justify-between gap-3'>
                  <h2>Amount</h2>
                  <h2>₦{amount}</h2>
                </div>
                <hr className='my-4 border-slate-200' />
                <div className='flex justify-between gap-3'>
                  <h2>Charges</h2>
                  <h2>₦{transferCharges}</h2>
                </div>
                <hr className='my-4 border-slate-200' />
                <div className='flex justify-between gap-3 font-bold'>
                  <h2>Total</h2>
                  <h2>₦{totalTransferAmount}</h2>
                </div>
                <hr className='my-4 border-slate-200' />
                <div>
                  <h2 className='text-sm'>Narration</h2>
                  <input
                    type='text'
                    id='narration'
                    name='narration'
                    className='w-full border-[1px] my-2 border-gray p-4'
                    value={narration || ""}
                    placeholder='short narration'
                    onChange={(e) => setnarration(e.target.value)}
                  />
                </div>
                <hr className='my-4 border-slate-200' />
                {isPinCreated ? (
                  <div>
                    <h2 className='text-sm'>Transfer Pin</h2>
                    <input
                      type='password'
                      id='account'
                      name='pin'
                      className='w-full border-[1px] my-2 border-gray p-4'
                      value={pin || ""}
                      placeholder='Input Pin'
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </div>
                ) : (
                  <div>
                    <h2 className='text-sm'>Create Transaction Pin</h2>
                    <input
                      type='password'
                      id='account'
                      name='pin'
                      className='w-full border-[1px] my-2 border-gray p-4'
                      value={createdPin?.pin || ""}
                      placeholder='New Pin'
                      onChange={handleCreatedPin}
                    />
                    <input
                      type='password'
                      id='account'
                      name='confirm_pin'
                      className='w-full border-[1px] my-2 border-gray p-4'
                      value={createdPin?.confirm_pin || ""}
                      placeholder='Confirm Pin'
                      onChange={handleCreatedPin}
                    />
                    <p className='text-xs text-red-600 italic'>
                      {pinCreationError}
                    </p>
                    <p className='text-xs text-green-600 italic'>
                      {pinCreationSuccess}
                    </p>
                    <button
                      className='bg-slate-300 p-2 px-5 rounded-md text-sm'
                      onClick={createPin}
                    >
                      {isPinCreationLoader ? (
                        <Spinner size={20} />
                      ) : (
                        "Create Pin"
                      )}
                    </button>
                  </div>
                )}

                <button
                  className='bg-blue p-3 px-8 w-full my-2 text-white rounded-md'
                  onClick={completeTransfer}
                >
                  {isLoading ? <Spinner size={20} /> : "Complete Transfer"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;
