import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Icon } from "@iconify/react";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import {
  invoiceRequest,
  invoiceFailure,
  invoiceSuccess,
  invoiceReset,
} from "../../redux/actions/dashboard/invoice";
import axios from "axios";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../context";
import classnames from "classnames";
import InvoicePreview from "./Invoicepreview";
import SelectBox from "../../components/pagination/SelectBox";
import ReactToPdf from "react-to-pdf";

const CreateInvoice = ({ handleCustomer, isCustomer }) => {
  const initialState = {
    isValue: true,
    isClass: false,
  };
  const [customer, setCustomer] = useState(initialState);
  // const [incomes, setIncomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [existing, setExisting] = useState();
  const [existingCustomer, setexistingCustomer] = useState([]);

  const dispatch = useDispatch();
  // bearerToken
  const token = localStorage.getItem("BearerToken");
  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);
  const baseUrl = `https://api.logikeep.africa/api/v1/create-receipt`;
  const todaysDate = `${new Date().getFullYear()}-${
    new Date().getMonth() + 1
  }-${new Date().getDate()}`;

  const [invoiceNumber, setInvoiceNumber] = useState(Date.now().toString());
  const [invoice, setInvoiceData] = useState({
    date: todaysDate,
    customer_id: "",
    customer_name: "[Select/Enter customer name]",
    customer_type: "",
    invoiceNumber: invoiceNumber,
    invoice_payment_status: "",
    discount: 0,
    subtotal: 0,
    shipping_fee: 0,
    vat: 0,
    total: 0,
    amount_paid: 0,
    balance: 0,
  });
  const [isPayment, setIsPayment] = useState(false); 
  const [isNoPayment, setIsNoPayment] = useState(false); 
  const handleInvoiceDataChange = (e) => {
    console.log(e.target.value);
    setInvoiceData({
      ...invoice,
      [e.target.name]: e.target.value,
    });
    setSubmitData({
      ...submitData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "customer_name") {
      setInvoiceNumber((prevInvoiceNumber) => prevInvoiceNumber + 1);
    }
    if (e.target.name === "invoice_payment_status") {
      setIsPayment(e.target.value === "part_payment");
    }
    if (e.target.name === "invoice_payment_status") {
      setIsNoPayment(e.target.value === "not_paid");
    }
  };
  const handleOptionChange = (option) => {
    console.log(option);
    setExisting(option);
    setInvoiceData({
      ...invoice,
      customer_name: option.name,
      customer_id: option.id,
    });
    setSubmitData({
      ...submitData,
      customer_name: option.name,
      customer_id: option.id,
    });
  };
  const [invoiceDetailsList, setInvoiceDetailsList] = useState({
    id: 1,
    receipt_details: "",
    selling_price: "",
    quantity: "",
    sub_total: "",
  });

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    // Calculate the sub_total if selling_price or quantity is changed
    if (name === "selling_price" || name === "quantity") {
      const costPrice =
        name === "selling_price"
          ? parseFloat(value)
          : invoiceDetailsList.selling_price;
      const quantity =
        name === "quantity" ? parseFloat(value) : invoiceDetailsList.quantity;
      const amountExpected = costPrice * quantity;

      setInvoiceDetailsList((prevState) => ({
        ...prevState,
        [name]: value,
        sub_total: amountExpected.toFixed(2), // Round to 2 decimal places
      }));
    } else {
      // If other fields are changed, update them without changing sub_total
      setInvoiceDetailsList((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddInvoiceDetail = (e) => {
    e.preventDefault();

    // Calculate the sub_total for the current entry
    const amountExpected =
      invoiceDetailsList.selling_price * invoiceDetailsList.quantity;

    // Create a new array by concatenating the existing details and the new item
    const newDetails = [
      ...submitData.details,
      { ...invoiceDetailsList, sub_total: amountExpected },
    ];

    // Update submitData with the new array
    setSubmitData({
      ...submitData,
      details: newDetails,
    });

    // Increment the item number for the next entry
    setInvoiceDetailsList((prevState) => ({
      ...prevState,
      id: prevState.id + 1,
    }));

    // Clear other fields for the next entry
    setInvoiceDetailsList((prevState) => ({
      ...prevState,
      receipt_details: "",
      selling_price: "",
      quantity: "",
      sub_total: 0, // Reset sub_total to 0
    }));

    // Update the subtotal by adding the current amountExpected
    setInvoiceData({
      ...invoice,
      subtotal: invoice.subtotal + amountExpected,
    });
  };

  // Calculate the total, including discount, VAT, and tax
  const calculateTotal = useCallback(() => {
    const { subtotal, discount, vat, shipping_fee } = invoice;
    const total =
      parseInt(vat) +
      parseInt(subtotal) +
      parseInt(shipping_fee) -
      parseInt(discount);

    return total;
  }, [invoice]);

  // Calculate the balance
  const calculateBalance = useCallback(() => {
    let { amount_paid } = invoice;
    if (amount_paid === 0 || amount_paid[0] === "") {
      return 0;
    }
    const balance = calculateTotal() - amount_paid;
    return balance;
  }, [invoice, calculateTotal]);

  const [submitData, setSubmitData] = useState({
    invoice_date: todaysDate,
    customer_id: "",
    customer_type: "",
    customer_name: "",
    customer_phone: "",
    customer_email: "",
    invoice_payment_status: "",
    invoice_due_date: "",
    details: [],
    vat: "",
    discount: "",
    shipping_fee: "",
    amount_paid: "",
    amount_due: "",
    total_invoice_amount: "",
  });
  const pdfRef = useRef(); // Define a ref for the ReactToPdf component

  const handleIncomeSubmit = async (e) => {
    e.preventDefault();
    console.log(submitData);

    dispatch(invoiceRequest());
    setIsLoading(true);
    axios
      .post(baseUrl, submitData, config)
      .then((res) => {
        dispatch(invoiceSuccess(res));
        setIsLoading(false);

        //sweet alert
        Swal.fire({
          icon: "success",
          title: "Receipt saved successfully",
          text: "Download a copy",
          confirmButtonText: "Download",
          showCancelButton: true,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            // Call toPdf from the ReactToPdf component when confirmed
            pdfRef.current.toPdf();
          }
        });
      })
      .catch((err) => {
        dispatch(invoiceFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(invoiceReset());
        }, 3000);
      });
  };
  // const PDFHandler = (toPdf) => {
  //   if (invoicePreviewRef.current) {
  //     setTimeout(() => {
  //       toPdf();
  //     }, 100);
  //   }
  // };
  const pdfFilename = `${invoice.customer_name}_receipt.pdf`;
  const PDFHandler = () => {
    if (invoicePreviewRef.current) {
      setTimeout(() => {
        invoicePreviewRef.current.toPdf();
      }, 100);
    }
  };
  const { theme } = useGlobalContext();
  const classes = classnames({
    "income-content rounded-lg shadow-md flex flex-col space-y-10 p-5 md:py-12 text-left transition duration-500 ease-in-out p-5 bg-slate-100":
      theme === "light",
    "income-content transition duration-500 ease-in-out flex flex-col space-y-10 shadow-md p-5 md:py-16 text-left  rounded-lg bg-opacity-80 bg-white":
      theme === "dark",
  });
  useEffect(() => {
    const getExistingCustomerData = async () => {
      await fetch(
        "https://api.logikeep.africa/api/v1/get-business-customer",
        config
      )
        .then((response) => response.json())
        .then((data) => {
          const existingCustomer = data.data.map((existing) => ({
            name: existing.customer_name,
            value: existing.customer_name,
            id: existing.id,
          }));
          setexistingCustomer(existingCustomer);
        });
    };
    getExistingCustomerData();
    const total = calculateTotal();
    const amountDue = calculateBalance();

    // Update submitData with the updated values
    setSubmitData((prevSubmitData) => ({
      ...prevSubmitData,
      total_invoice_amount: total,
      amount_due: amountDue,
    }));
    calculateBalance();
    calculateTotal();
  }, [config, calculateTotal, calculateBalance, invoice]);

  const invoicePreviewRef = useRef();
  // Define the onDeleteItem function to remove an item by its id
  const onDeleteItem = (itemId) => {
    const items = [...submitData.details]; // Create a copy of the original array
    // Find the index of the item with the matching itemId
    const indexToRemove = items.findIndex((item) => item.id === itemId);
    if (indexToRemove !== -1) {
      // If an item with the given itemId was found, remove it using splice
      items.splice(indexToRemove, 1);
      console.log("Item removed, Updated items:", items);
      // Update the state with the modified array
      setSubmitData((prevSubmitData) => ({
        ...prevSubmitData,
        details: items,
      }));
    } else {
      console.log("Item with itemId not found.");
    }
  };
  

  return (
    <>
      <div>
        <div className="flex flex-col md:grid grid-cols-2 gap-5 md:gap-28 md:pb-20 text-center md:px-0 text-black">
          <div className="h-full">
            <div className="flex flex-col space-y-1 pb-3">
              <div className="border-2 border-blue self-center rounded-full p-2">
                <Icon className="text-xl text-blue" icon="bxs:user" />
              </div>

              <div
                id="income"
                className={theme === "light" ? "text-black" : "text-white"}
              >
                Invoice Details
              </div>
            </div>
            <div
              className={classes}
              style={
                theme === "light"
                  ? {
                      backgroundColor: "white",
                      color: "black",
                    }
                  : {
                      backgroundColor: "black",
                      color: "white",
                    }
              }
            >
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="category">
                  Invoice Date
                  <span className="text-red-400 absolute -top-1 left-[86px]">
                    *
                  </span>
                </label>
                <input
                  className="shadow"
                  type="date"
                  name="date"
                  id="invoice_date"
                  required
                  onChange={handleInvoiceDataChange}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="category">
                  Customer
                  <span className="text-red-400 absolute -top-1 left-[72px]">
                    *
                  </span>
                </label>
                <select
                  className="p-2 shadow"
                  name="customer_type"
                  id="customer_type"
                  onChange={(e) => {
                    handleCustomer(e);
                    setCustomer(e.target.value);
                    handleInvoiceDataChange(e);
                  }}
                  required
                  value={customer}
                >
                  <option>Select Customer Type</option>
                  <option value="new" id="new">
                    New Customer
                  </option>
                  <option value="existing" id="existing">
                    Existing Customer
                  </option>
                </select>
              </div>

              <div
                className={
                  isCustomer.isExistingCustomer
                    ? "flex flex-col space-y-1"
                    : "hidden"
                }
              >
                <SelectBox
                  label="Existing Customer"
                  options={existingCustomer}
                  handleOptionChange={handleOptionChange}
                />
              </div>
              {isCustomer.isNewCustomer && (
                <div className="flex flex-col space-y-1">
                  {" "}
                  <h1>Add a New Customer</h1>
                  <label className="relative" htmlFor="date">
                    Name of Customer{" "}
                    <span className="text-red-400 absolute -top-1 left-[135px]">
                      *
                    </span>
                  </label>
                  <input
                    className="shadow"
                    type="text"
                    name="customer_name"
                    id="customer_name"
                    placeholder="E.g Kamarudeen Ishola"
                    onChange={handleInvoiceDataChange}
                    required
                  />
                </div>
              )}

              <div
                className={
                  isCustomer.isNewCustomer
                    ? "flex flex-col space-y-1"
                    : "hidden"
                }
              >
                <label className="relative" htmlFor="note">
                  Phone Number{" "}
                  <span className="text-red-400 absolute -top-1 left-[107px]">
                    *
                  </span>
                </label>
                <input
                  className="shadow"
                  type="number"
                  name="customer_phone"
                  id="customer_phone"
                  placeholder="E.g +234-000-00-000-00"
                  onChange={handleInvoiceDataChange}
                />
              </div>
              <div
                className={
                  isCustomer.isNewCustomer
                    ? "flex flex-col space-y-1"
                    : "hidden"
                }
              >
                <label htmlFor="note">Email Address (Optional)</label>
                <input
                  className="shadow"
                  type="email"
                  name="customer_email"
                  id="phone"
                  placeholder="E.g haruish@yahoo.com"
                  onChange={handleInvoiceDataChange}
                />
              </div>
              <form
                onSubmit={handleAddInvoiceDetail}
                className="flex flex-col space-y-1"
              >
                <h2 className="font-semibold text-md uppercase">
                  Add invoice details
                </h2>

                <div>
                  {" "}
                  <label className="relative" htmlFor="receipt_details">
                    Invoice Details
                    <span className="text-red-400 absolute -top-1 left-[100px]">
                      *
                    </span>
                  </label>
                  <input
                    className="shadow"
                    type="text"
                    name="receipt_details"
                    id="receipt_details"
                    value={invoiceDetailsList.receipt_details}
                    onChange={handleFormDataChange}
                    required
                  />
                </div>
                <div>
                  <label className="relative" htmlFor="selling_price">
                    Cost Price
                    <span className="text-red-400 absolute -top-1 left-[69px]">
                      *
                    </span>
                  </label>
                  <input
                    className="shadow"
                    type="number"
                    name="selling_price"
                    id="selling_price"
                    value={invoiceDetailsList.selling_price}
                    onChange={handleFormDataChange}
                    required
                  />
                </div>
                <div>
                  <label className="relative" htmlFor="quantity">
                    Quantity
                    <span className="text-red-400 absolute -top-1 left-[60px]">
                      *
                    </span>
                  </label>
                  <input
                    className="shadow"
                    type="number"
                    name="quantity"
                    id="quantity"
                    value={invoiceDetailsList.quantity}
                    onChange={handleFormDataChange}
                    required
                  />
                </div>
          
               <div className="space-y-3"></div>
               <button
                  type="submit"
                  className="bg-blue text-white p-2 rounded-lg"
                >
                  + Add Item
                </button>
                <div className="self-center font-semibold text-sm">You can delete an item by clicking on it on the table</div>
           
              </form>

              <div className="space-y-3">
              <div className="flex flex-col space-y-1">
                  <label className="relative" htmlFor="invoice_payment_status">
                    Payment Status{" "}
                    <span className="text-red-400 absolute -top-1 left-[112px]">
                      *
                    </span>
                  </label>
                  <select
                    className="p-2 shadow"
                    name="invoice_payment_status"
                    id="invoice_payment_status"
                    required
                    value={paymentStatus}
                    onChange={handleInvoiceDataChange}
                  >
                    <option>Select Status</option>
                    <option value="paid" id="paid">
                      Full - payment
                    </option>
                    <option value="part_payment" id="part_payment">
                      Part - payment
                    </option>
                    <option value="not_paid" id="not_paid">
                      No - payment
                    </option>
                  </select>
                </div>
                {isPayment && (
                <div className="flex flex-col space-y-1">
                  <label className="relative py-2" htmlFor="amount_paid">
                    Amount paid
                    <span className="text-red-400 absolute top-1 left-[93px]">*</span>
                  </label>
                  <input
                    className="shadow"
                    type="number"
                    name="amount_paid"
                    id="amount_paid"
                    placeholder="#0.00"
                    onChange={handleInvoiceDataChange}
                    required
                  />
                </div>
              )}
               {isNoPayment && (
                <div className="flex flex-col space-y-1">
                  <label className="relative py-2" htmlFor="amount_paid">
                    Amount paid
                    <span className="text-red-400 absolute top-1 left-[93px]">*</span>
                  </label>
                  <input
                    className="shadow"
                    type="number"
                    name="amount_paid"
                    id="amount_paid"
                    placeholder="#0.00"
                    onChange={handleInvoiceDataChange}
                    required
                  />
                </div>
              )}
          
                <div className="flex flex-col space-y-1 ">
                  <label className="relative" htmlFor="vat">
                    Vat{" "}
                   
                  </label>
                  <input
                    className="shadow"
                    type="number"
                    name="vat"
                    id="vat"
                    placeholder="#0.00"
                    onChange={handleInvoiceDataChange}
                    required
                  />
                </div>

                <div className="flex flex-col space-y-1 ">
                  <label className="relative" htmlFor="shipping_fee">
                    Shipping Fee{" "}
                   
                  </label>
                  <input
                    className="shadow"
                    type="number"
                    name="shipping_fee"
                    id="shipping_fee"
                    placeholder="#0.00"
                    onChange={handleInvoiceDataChange}
                    required
                  />
                </div>

                <div className="flex flex-col space-y-1 ">
                  <label className="relative" htmlFor="discount">
                    Discount{" "}
                   
                  </label>
                  <input
                    className="shadow"
                    type="number"
                    name="discount"
                    id="discount"
                    placeholder="#0.00"
                    onChange={handleInvoiceDataChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="h-full">
            <div className="flex flex-col space-y-1 pb-3">
              <div className="border-2 border-blue self-center rounded-full p-2">
                <Icon
                  className="text-xl text-blue"
                  icon="mingcute:shopping-bag-1-fill"
                />
              </div>

              <div
                id="income"
                className={theme === "light" ? "text-black" : "text-white"}
              >
                Invoice Sample
              </div>
            </div>
            <div
              className={classes}
              style={
                theme === "light"
                  ? {
                      backgroundColor: "white",
                      color: "black",
                      padding: "0px",
                    }
                  : {
                      backgroundColor: "black",
                      color: "white",
                      padding: "0px",
                    }
              }
            >
              {/* Display invoice details preview */}
              <div>
                <InvoicePreview
                  invoice={invoice}
                  items={submitData.details}
                  total={calculateTotal}
                  balance={calculateBalance}
                  PDFHandler={PDFHandler}
                  invoicePreviewRef={invoicePreviewRef}
                  onDeleteItem={onDeleteItem}
                />
              </div>
            </div>
          </div>

       
          <ReactToPdf
            targetRef={invoicePreviewRef}
            filename={pdfFilename}
            // filename="Logikeep_receipt.pdf"
            ref={pdfRef}
          >
            {({ toPdf }) => (
              // This button is not displayed, it's used in the SweetAlert dialog
              <button
                className="hidden rounded-md p-2 whitespace-nowrap bg-blue text-white text-sm my-5"
                onClick={toPdf}
              >
                Download
              </button>
            )}
          </ReactToPdf>
          <div className="col-span-2">
            {submitData.customer_type === "new" ? (
              // save for new
              <button
                onClick={handleIncomeSubmit}
                className="bg-blue text-white p-2 rounded-lg md:w-96 w-full"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner intent="warning" size={20} />
                ) : (
                  "Save new"
                )}
              </button>
            ) : (
              // save for existing
              <button
                onClick={handleIncomeSubmit}
                className="bg-blue text-white p-2 rounded-lg md:w-96 w-full"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner intent="warning" size={20} />
                ) : (
                  "Create Invoice"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
