import React, { useEffect, useState } from "react";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function PaymentResponse() {
  const [transID, setTransID] = useState(null);
  const token = localStorage.getItem("BearerToken");
  const navigate = useNavigate();
  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const verifyPayment = async () => {
    const trans = query.get("transaction_id");
    setTransID(trans);
    const status = query.get("status");

    if (status === "cancelled" || status === "failed") {
      Swal.fire({
        icon: "error",
        title: "Top up failed",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/dashboard/wallet");
      }, 1500);
    } else if (transID && status === "successful") {
      const url = `${baseUrl}verifypayment/${transID}`;
      try {
        const res = await axios.post(url, {}, config);
        Swal.fire({
          icon: "success",
          title: res?.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          navigate("/dashboard/wallet");
        }, 1500);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    verifyPayment();
  }, [transID]);
  return (
    <div className='flex flex-col items-center justify-center min-h-screen w-screen'>
      <Spinner intent='warning' size={50} />
      <h2 className='text-xl my-2' onClick={verifyPayment}>
        Please Wait...
      </h2>
    </div>
  );
}

export default PaymentResponse;
