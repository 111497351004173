import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Axios from "axios";
import SearchBox from "../../../components/SearchBox";
import DateInput from "../../../components/DateSelector";
import Grid from "../../../components/overdue/grid";
import { useGlobalContext } from "../../../context";

function PaymentOverdue({ search, handleSearch }) {
  const { sendReminder } = useGlobalContext();
  const [data, setData] = useState([]);
  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  let url;
  const token = localStorage.getItem("BearerToken");

  const getData = async () => {
    url = `${baseUrl}paymentoverdue`;
    try {
      const res = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.data;
      // console.log(data);
      setData(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className='flex justify-between pb-5 space-x-2 text-black'>
        <SearchBox
          placeholder={"Search customer"}
          value={search}
          onChange={handleSearch}
        />
        <div className='flex space-x-2 md:space-x-5'>
          <div className=' self-center'>
            <button className='bg-white shadow-lg rounded-lg p-3 flex md:space-x-5 self-center'>
              <Icon className='self-center' icon='el:download-alt' />
              <span className='text-xs m-0 w-16'>Export CSV</span>
            </button>
          </div>
          <div className='w-32 self-center'>
            <DateInput value={search} onChange={handleSearch} />
          </div>
        </div>
      </div>
      <div className='overflow-scroll text-black'>
        <Grid func={(id) => sendReminder(id)} items={data} />
      </div>
    </div>
  );
}

export default PaymentOverdue;
