import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginFailure,
  loginRequest,
  loginReset,
  loginSuccess,
} from "../../../redux/actions/auth/authActions";
import { api } from "../../../services/api";
import { appUrls } from "../../../services/urls";
import { Icon } from "@iconify/react";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";

const passwordRevealIcon = {
  isOpen: false,
  isType: true,
};

const Registered = () => {
  const [formData, setFormData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [countries, setCountries] = useState([]);
  const [refCode, setRefCode] = useState("");

  const navigate = useNavigate();
  //Get Referral Code from the query params
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    const ref_code = query.get("ref_code");
    if (ref_code) {
      setRefCode(ref_code);
    }
  }, [query]);

  const errorDiv = error ? (
    <small className="text-red-600 text-center w-52 mt-4">{error}</small>
  ) : (
    ""
  );

  useEffect(() => {
    const getCountryData = async () => {
      await fetch("https://api.logikeep.africa/api/v1/allcountry")
        .then((response) => response.json())
        .then((data) => {
          const countries = data.data.map((country) => ({
            name: country.name,
            value: country.name,
            country_id: country.id,
          }));
          setCountries(countries);
        });
    };
    getCountryData();
  }, []);

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    // first name validation
    if (formData?.firstname === undefined || formData?.firstname.length < 3) {
      setError("First name must be at least 3 characters");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    // last name validation
    if (formData?.lastname === undefined || formData?.lastname.length < 3) {
      setError("Last name must be at least 3 characters");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    // email validation
    if (!formData?.email.includes("@")) {
      setError("Invalid email address");

      console.log(error);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    // phone number validation
    if (
      formData?.phonenumber === undefined ||
      formData?.phonenumber.length < 11
    ) {
      console.log(error);
      setError("Invalid phone number");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    // country validation
    if (!formData?.country) {
      setError("Please select a country");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    // password validation special characters and length and number validation
    if (
      !formData?.password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,32}$/
      )
    ) {
      setError(
        "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number"
      );
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    // password validation
    if (formData?.password !== formData?.confirm_password) {
      setError("Password does not match");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    // terms validation
    if (!formData?.terms) {
      setError("Please accept the terms and conditions");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    const data = {
      firstname: formData?.firstname,
      lastname: formData?.lastname,
      email: formData?.email,
      phonenumber: formData?.phonenumber,
      password: formData?.password,
      confirm_password: formData?.confirm_password,
      referral_code: refCode,
      all_country_id: formData?.country,
    };
    dispatch(loginRequest());
    setIsLoading(true);
    await api
      .post([appUrls.signupUrl, data])
      .then((res) => {
        console.log(res);
        dispatch(loginSuccess(res));
        localStorage.setItem("BearerToken", res.token);
        setIsLoading(false);
        navigate("/otp");
      })

      .catch((err) => {
        dispatch(loginFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(loginReset());
        }, 3000);
      });
  };
  const [passwordReveal, setPasswordReveal] = useState(passwordRevealIcon);
  const revealPass = passwordReveal.isOpen ? "el:eye-open" : "el:eye-close";

  const inputType = passwordReveal.isType ? "password" : "text";

  const handlePasswordReveal = () => {
    setPasswordReveal({
      ...passwordReveal,
      isOpen: !passwordReveal.isOpen,
      isType: !passwordReveal.isType,
    });
  };

  // handle reveal password 2
  const [passwordReveal2, setPasswordReveal2] = useState(passwordRevealIcon);
  const revealPass2 = passwordReveal2.isOpen ? "el:eye-open" : "el:eye-close";

  const inputType2 = passwordReveal2.isType ? "password" : "text";

  const handlePasswordReveal2 = () => {
    setPasswordReveal2({
      ...passwordReveal2,
      isOpen: !passwordReveal2.isOpen,
      isType: !passwordReveal2.isType,
    });
  };

  return (
    <div>
      <div className="flex  flex-wrap h-full  g-6 text-gray-800 ">
        <div className="lg:flex lg:flex-wrap g-0 ">
          <div className="lg:w-6/12 px-4 md:px-0">
            <div className="md:p-12 md:mx-6 mt-2">
              <div className="text-center">
                <img
                  src="/assets/LogikeepLogo/LogikeepLogo.png"
                  id="imgBxForgot"
                  alt="img"
                  className="h-8 w-15 "
                />
              </div>
              <div className="mt-4">
                <p className="text-black " id="loginText ">
                  Let’s ensure you remain informed and in control of the
                  finances for your business.
                </p>
                <p className="text-center lg:text-right font-normal">
                  Already have an account?{" "}
                  <Link className="text-xl text-blue" to="/login">
                    Sign In
                  </Link>
                </p>
              </div>
              <form className="" onSubmit={handleLoginSubmit}>
                <input
                  type="text"
                  placeholder="First Name"
                  className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73]"
                  name="firstname"
                  onChange={handleFormDataChange}
                  id="firstname"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                  name="lastname"
                  onChange={handleFormDataChange}
                  id="lastname"
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                  onChange={handleFormDataChange}
                  name="email"
                  id="email"
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                  onChange={handleFormDataChange}
                  name="phonenumber"
                  id="phonenumber"
                />
                <select
                  name="country"
                  className="shadow appearance-none rounded w-full py-2 px-3 mt-2 mb-2 bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                  aria-label="Default select example"
                  onChange={handleFormDataChange}
                >
                  <option className="text-gray">Select Country</option>
                  {countries.map((country, i) => (
                    <option key={i} value={country.country_id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className="relative w-full">
                  <input
                    type={inputType}
                    placeholder="Enter Password"
                    className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                    onChange={handleFormDataChange}
                    name="password"
                    id="password"
                  />
                  <Icon
                    className="absolute right-0 top-0 mt-[22px] mr-4 cursor-pointer"
                    icon={revealPass}
                    onClick={handlePasswordReveal}
                  />
                </div>
                <div className="relative w-full">
                  <input
                    type={inputType2}
                    placeholder="Confirm Password"
                    className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                    onChange={handleFormDataChange}
                    name="confirm_password"
                    id="confirm_password"
                  />

                  <Icon
                    className="absolute right-0 top-0 mt-[22px] mr-4 cursor-pointer"
                    icon={revealPass2}
                    onClick={handlePasswordReveal2}
                  />
                </div>

                <input
                  type="text"
                  placeholder="Referral Code(Optional)"
                  className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                  onChange={handleFormDataChange}
                  name="referral_code"
                  value={refCode}
                  readOnly={refCode ? true : false}
                  id="referral_code"
                />
                <div className="flex items-center" id="term">
                  <input
                    type="checkbox"
                    id="checkbox-example"
                    className="h-4 w-4 text-gray-700 border rounded mr-2"
                    name="terms"
                    onChange={handleFormDataChange}
                  />
                  <label
                    htmlFor="checkbox-example"
                    className="text-sm"
                    name="terms"
                    id="term"
                  >
                    I agree to Logikeep’s{" "}
                    <Link
                      to={"/privacy-policy"}
                      className="text-blue font-semibold"
                    >
                      Privacy Terms
                    </Link>{" "}
                    and{" "}
                    <span className="text-blue font-semibold">Condition</span>
                  </label>
                </div>

                {errorDiv}
                <button
                  className="submitButton "
                  type="submit"
                  id="btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent="warning" size={20} />
                  ) : (
                    "SIGN UP"
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-gradient-to-r from-indigo-500  to-cyan-100  ">
            <div className="text-white px-4 py-6 md:p-12 md:mx-6" id="overlay">
              <img src="/assets/registerImage.svg" id="imgBxForgot" alt="img" />
              <h2
                className="text-lg text-black font-semibold ml-4 text-center"
                id="imgText"
              >
                Your Book-keeping Team So That You
                <br />
                Can Do What You Love.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registered;
