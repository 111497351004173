import { SET_INDEX } from "../../actionTypes/auth/authActionTypes";

const initialState = {
  selectedIndex: 0,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INDEX:
      const newIndex = action.payload;
      return {
        selectedIndex: newIndex,
      };
    default:
      return state;
  }
};

export default sidebarReducer;
