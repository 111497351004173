import React from 'react';
import Navbar from "../../../pages/home/Navbar/index";
import Footer from '../../home/Navbar/footer';
import Section from './Section';
import GridBlog from './GridBlog';



const Blog = () => {
  return (
   <>
   <Navbar/>
   <Section/>
   <GridBlog/>
   <Footer/>
   </>
  )
}

export default Blog
