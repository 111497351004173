import axios from "axios";
import React, { useEffect, useState } from "react";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
const BrtCard = () => {
  const [isNameShown, setIsNameShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [formData, setFormData] = useState({ amount: 0 });
  const [rate, setRate] = useState(0);
  const baseUrl = `https://api.logikeep.africa/api/v1`;
  // "https://live.logikeep.africa/api/v1";
  // ;

  const token = localStorage.getItem("BearerToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resolveName = async () => {
    const url = `${baseUrl}/verify-cowry-number`;
    setIsLoading(true);
    setIsNameShown(false);
    try {
      const res = await axios.post(
        url,
        { phone_number: formData?.phone_number },
        config
      );

      setFormData({
        ...formData,
        name: res.data.data.message.details.fullname,
      });
      setIsNameShown(true);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setMsg(error?.response?.data?.message || "An error occured");
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formData?.phone_number?.length > 10) {
      resolveName();
    }
  }, [formData?.phone_number]);

  const getCharges = async () => {
    const url = `${baseUrl}/get-cowry-fees`;
    try {
      const res = await axios.get(url, config);
      const charge = res.data.data[0].charges;
      setRate(+charge);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCharges();
  }, []);

  const amount = formData?.amount;

  useEffect(() => {
    setFormData({
      ...formData,
      total: +amount + rate * amount,
      charge: rate * +amount,
    });
  }, [amount]);

  const rechargeCowry = async () => {
    const url = `${baseUrl}/recharge-cowry-number`;
    setIsPageLoading(true);
    const data = {
      customer_number: formData?.phone_number,
      customer_name: formData?.name,
      amount: formData?.total,
      cowry_charge: formData?.charge,
    };
    try {
      const res = await axios.post(url, data, config);
      setMsg(res.data.message);
      setIsPageLoading(false);
      setIsNameShown(false);
    } catch (error) {
      setError(true);
      setMsg(error?.response?.data?.message || "An error occured");
      console.log(error);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (msg.length > 0) {
      setTimeout(() => {
        setMsg("");
        setError(false);
      }, 3000);
    }
  }, [msg]);

  return (
    <div className='flex flex-col items-center justify-center mt-10 max-w-lg mx-auto'>
      <h2 className='text-blue text-3xl font-bold'>Cowry Card Top Up</h2>
      <p className='my-2 text-sm mb-8'>Load your Cowry Cards on this Page </p>
      {msg && (
        <p
          className={`${
            error ? "bg-red-200 text-red-600" : " bg-green-200 text-green-600"
          } p-2 text-sm w-full text-center`}
        >
          {msg}
        </p>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          rechargeCowry();
        }}
        className='w-full'
      >
        <div className='flex relative'>
          <div className='w-full'>
            <label className='text-sm font-bold' htmlFor='tel'>
              Enter Your Cowry Card Phone Number
            </label>
            <input
              name='phone_number'
              type='tel'
              className='mb-3'
              onChange={handleFormData}
            />
          </div>

          <div className='absolute bottom-4 text-sm  right-3'>
            {isLoading && <Spinner size={20} />}
          </div>
        </div>
        {isNameShown && (
          <div>
            <label className='text-sm font-bold' htmlFor='tel'>
              Cowry Card Full name
            </label>
            <input
              type='name'
              className='mb-3'
              name='name'
              value={formData?.name}
              readOnly
            />
            <div className='flex relative'>
              <div className='w-full'>
                <label className='text-sm font-bold' htmlFor='tel'>
                  Enter Amount to load (₦)
                </label>
                <input
                  type='number'
                  name='amount'
                  className='mb-3'
                  onChange={handleFormData}
                  value={formData?.amount || ""}
                />
              </div>
            </div>
            {formData?.amount > 0 && (
              <div className='flex justify-between gap-2 text-sm text-red-600'>
                <span>Service Charge: ₦{formData?.charge}</span>
                <span className='font-bold text-blue'>
                  Total: ₦{formData?.total}
                </span>
              </div>
            )}

            <div className='flex items-center justify-center mt-4'>
              {isPageLoading ? (
                <Spinner />
              ) : (
                <input
                  type='submit'
                  className='bg-blue text-white rounded-md p-2 max-w-sm mx-auto'
                  value='Load Cowry Card'
                />
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default BrtCard;
