import React, { useState, useEffect } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import axios from "axios";
import { useGlobalContext } from "../../context";
import Swal from "sweetalert2";
import Modal from "../../components/Modal";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import classnames from "classnames";

function ItemTable({ handleEdit }) {
  const { setInventoryCount, theme } = useGlobalContext();
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [close, setClose] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [editId, setEditId] = useState("");
  const [listItem, editListItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const inventoryUrl = `https://api.logikeep.africa/api/v1/allinventories`;

  const baseUrl = `https://api.logikeep.africa/api/v1/`;

  useEffect(() => {
    displayInventory();
  }, []);

  const displayInventory = () => {
    axios
      .get(inventoryUrl, config)
      .then((response) => {
        const data = response.data.data;
        editListItem(data);
        setInventoryCount(data.length);
        setStatus(true);

        // console.log(data);
      })
      .catch((err) => {});
  };

  const handleDelete = async (id) => {
    // setIsLoading(true);
    const deleteUrl = `https://api.logikeep.africa/api/v1/deleteinventory/${id}`;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      axios
        .delete(deleteUrl, config)
        .then((response) => {
          displayInventory();
        })
        .catch((err) => {});
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
    // setIsLoading(false);
  };

  const handleIsEditing = (id) => {
    setIsEdit(true);
    setEditId(id);
    const selectedAItem = listItem.find((item) => item.id === id);
    setCurrentItem({
      product: selectedAItem.product,
      numbers_of_stocks_available: selectedAItem.numbers_of_stocks_available,
      unit: selectedAItem.unit,
    });
  };

  const handleCloseModal = () => {
    setClose(true);
    setShowModal(false);
    setIsEdit(false);
  };

  let url;
  // Form submit
  const handleEditItem = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const updatedInventory = {
      numbers_of_stocks_available: currentItem.numbers_of_stocks_available,
    };
    url = `${baseUrl}updateinventory/${editId}`;
    try {
      const res = await axios.post(url, updatedInventory, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      console.log(res);
      displayInventory();
      setIsEdit(false);
      Swal.fire({
        title: "Action Successful",
        text: "Inventory Edited successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: "Error",
        text: "There was an error editing this inventory, please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleEditInputChange = (e) => {
    setCurrentItem({ ...currentItem, [e.target.name]: e.target.value });
  };
  const classes = classnames({
    "divide-y divide-gray-200 transition duration-500 ease-in-out bg-slate-100":
      theme === "light",
    "divide-y divide-gray-200 text-white bg-white transition duration-500 ease-in-out bg-opacity-80":
      theme === "dark",
  });

  return (
    <>
      <table className='table-fixed w-full text-center text-black'>
        <thead className='hidden md:table-header-group border-b-4 border-indigo-800'>
          <tr className=' bg-[#EFEFEF]'>
            <th className='p-3'>Name of Item </th>
            <th className='p-3'>Quantity</th>
            <th className='p-3'>Unit</th>

            <th className='p-3'>Action</th>
          </tr>
        </thead>
        <thead className='md:hidden border-b-4 border-indigo-800'>
          <tr className=' bg-[#EFEFEF]'>
            <th className='p-3'>Item</th>
            <th className='p-3'>Qty</th>
            <th className='p-3'>Unit</th>

            <th className='p-3'>Action</th>
          </tr>
        </thead>
        <tbody className={classes}>
          {status ? (
            listItem?.length > 0 ? (
              listItem.map((items, key) => {
                return (
                  <tr key={key}>
                    <td className='p-3 uppercase'>{items.product}</td>
                    <td className='p-3'>{items.numbers_of_stocks_available}</td>
                    <td className='p-3 uppercase'>{items.unit}</td>

                    <td className='p-2 md:space-x-3'>
                      <button
                        onClick={() => handleIsEditing(items.id)}
                        className='border-[1px] px-3 h-9 self-center bg-blue text-white rounded-md'
                        type='submit'
                      >
                        <AiOutlineEdit />
                      </button>

                      <button
                        onClick={() => handleDelete(items.id)}
                        className='border-[1px] px-3 h-9 self-center bg-red-600 text-white rounded-md'
                        type='submit'
                      >
                        <AiOutlineDelete />
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className='px-5 pb-5'>
                <h5 className='text-xl font-medium text-center tracking-tight'>
                  No Inventory at the moment
                </h5>
              </div>
            )
          ) : (
            <p className='text-center text-xl font-medium'>loading...</p>
          )}
        </tbody>
      </table>

      {isEdit && (
        <Modal
          close={close}
          show={showModal}
          handleClose={handleCloseModal}
          title='Edit Items'
          onSubmit={handleEditItem}
        >
          <div className='flex flex-col space-y-3'>
            <div className='flex flex-col'>
              <label className='text-sm'>Item Name</label>
              <input
                className='border-[1px] border-gray-300 rounded-md p-2'
                type='text'
                name='product'
                value={currentItem.product}
                onChange={handleEditInputChange}
                placeholder='e.g Rolex Watch'
                required
                disabled
              />
            </div>
            <div className='flex flex-col'>
              <label className='text-sm'>No of Stock</label>
              <input
                className='border-[1px] border-gray-300 rounded-md p-2'
                type='number'
                name='numbers_of_stocks_available'
                value={currentItem.numbers_of_stocks_available}
                onChange={handleEditInputChange}
                placeholder='e.g 10'
                required
              />
            </div>
            <div className='flex flex-col'>
              <label className='text-sm'>Unit</label>
              <input
                className='border-[1px] border-gray-300 rounded-md p-2'
                type='text'
                name='unit'
                value={currentItem.unit}
                onChange={handleEditInputChange}
                required
                disabled
              />
            </div>
            <div className='flex space-x-3'>
              <button
                onClick={handleEditItem}
                className='border-[1px] px-3 h-9 self-center bg-blue text-white rounded-md'
                type='submit'
              >
                {isLoading ? <Spinner intent='warning' size={20} /> : "Save"}
              </button>
              <button
                onClick={handleCloseModal}
                className='border-[1px] px-3 h-9 self-center bg-red text-white rounded-md'
                type='submit'
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ItemTable;
