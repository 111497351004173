import React, { useEffect, useState } from "react";
import "../../../styles/screen.css";
import {
  Container,
  FormContainer,
  OverlayContainer,
  Main,
} from "../../../styles/styledElements";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginFailure,
  loginRequest,
  loginReset,
  loginSuccess,
} from "../../../redux/actions/auth/authActions";
import { api } from "../../../services/api";
import { appUrls } from "../../../services/urls";
import Logo from "../../home/Logo";
import { Icon } from "@iconify/react";
import styled, { keyframes } from "styled-components";
import { merge, fadeIn, bounce } from "react-animations";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";

const bounceFadeIn = merge(bounce, fadeIn);

const bounceAnimation = keyframes`${bounceFadeIn}`;
const BouncyDiv = styled.div`
  animation: 10s infinite ${bounceAnimation};
`;

const passwordRevealIcon = {
  isOpen: false,
  isType: true,
};

function SignUp() {
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [country, setCountry] = useState([]);
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState();
  const [refCode, setRefCode] = useState("");

  const navigate = useNavigate();
  const param = useParams();
  //Get Referral Code from the query params
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    const ref_code = query.get("ref_code");
    setRefCode(ref_code);
  }, []);

  const errorDiv = error ? (
    <small className='text-red-600 text-center w-52 mt-4'>{error}</small>
  ) : (
    ""
  );

  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const baseUrl = `https://api.logikeep.africa/api/v1/register"`;

  //countryapi call
  const oncountryChange = (event) => {
    const countryCode = event.target.value;
    setCountry(countryCode);
  };

  useEffect(() => {
    const getCountryData = async () => {
      await fetch("https://api.logikeep.africa/api/v1/allcountry")
        .then((response) => response.json())
        .then((data) => {
          const countries = data.data.map((country) => ({
            name: country.name,
            value: country.name,
            country_id: country.id,
          }));
          setCountries(countries);
        });
    };
    getCountryData();
  }, []);

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const data = {
      firstname: formData?.firstname,
      lastname: formData?.lastname,
      email: formData?.email,
      phonenumber: formData?.phonenumber,
      password: formData?.password,
      confirm_password: formData?.confirm_password,
      referral_code: formData?.referral_code,
      all_country_id: state,
    };

    dispatch(loginRequest());
    setIsLoading(true);
    await api
      .post([appUrls.signupUrl, data])
      .then((res) => {
        dispatch(loginSuccess(res));
        localStorage.setItem("BearerToken", res.token);
        setIsLoading(false);
        navigate("/success-page");
      })

      .catch((err) => {
        dispatch(loginFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(loginReset());
        }, 3000);
      });
  };
  const [passwordReveal, setPasswordReveal] = useState(passwordRevealIcon);
  const revealPass = passwordReveal.isOpen ? "el:eye-open" : "el:eye-close";

  const inputType = passwordReveal.isType ? "password" : "text";

  const handlePasswordReveal = () => {
    setPasswordReveal({
      ...passwordReveal,
      isOpen: !passwordReveal.isOpen,
      isType: !passwordReveal.isType,
    });
  };

  return (
    <>
      <Main className='p-5 lg:p-0 lg:h-max'>
        <Container
          className='lg:h-full w-full rounded-lg lg:rounded-none grid lg:grid-cols-2 md:bg-white lg:bg-slate-200 md:p-4'
          id='containerBox'
        >
          <FormContainer
            id='sign-in-container'
            className='absolute md:relative sign-in'
          >
            {" "}
            <div className='pb-2 lg:pb-0 flex justify-between'>
              <Logo />
            </div>
            <div className='lg:px-20 space-y-5 lg:space-y-0'>
              <div className='space-y-3'>
                <h3
                  className='text-blue text-lg lg:text-3xl font-semibold lg:text-center'
                  id='logText'
                >
                  Sign Up
                </h3>
                <p class='text-black' id='loginText'>
                  Let’s ensure you remain informed and in control of the
                  finances for your business.
                </p>
                <p className='text-center lg:text-left font-bold'>
                  Already have an account?{" "}
                  <Link className='text-xl text-blue' to='/login'>
                    Sign In
                  </Link>
                </p>
              </div>

              <form action='#' className='' onSubmit={handleLoginSubmit}>
                <input
                  type='text'
                  placeholder='First Name'
                  className='border-b-2 border-blue outline-none text-sm bg-transparent'
                  name='firstname'
                  onChange={handleFormDataChange}
                  id='input'
                  required
                />
                <input
                  type='text'
                  placeholder='Last Name'
                  className='border-b-2 border-blue outline-none text-sm bg-transparent'
                  name='lastname'
                  onChange={handleFormDataChange}
                  required
                  id='input'
                />
                <input
                  type='email'
                  placeholder='Email Address'
                  className='border-b-2 border-blue outline-none text-sm bg-transparent'
                  onChange={handleFormDataChange}
                  name='email'
                  required
                  id='input'
                />
                <input
                  type='text'
                  placeholder='Phone Number'
                  className='border-b-2 border-blue outline-none text-sm bg-transparent'
                  onChange={handleFormDataChange}
                  name='phonenumber'
                  required
                  id='input'
                />
                <select
                  name='all_country_id'
                  value={state}
                  class='form-select appearance-none mt-1
                    block
                    w-full max-w-3xl
                    px-3
                    py-2
                    text-sm
                   text-gray-300 
                   border-b-2 border-blue outline-none bg-transparent
                    '
                  aria-label='Default select example'
                  onChange={(e) => setState(e.target.value)}
                >
                  <option className='text-gray'>Select Country</option>
                  {countries.map((country, i) => (
                    <option key={i} value={country.country_id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className='relative w-full'>
                  <input
                    type={inputType}
                    placeholder='Enter Password'
                    className='border-b-2 border-blue outline-none text-sm bg-transparent'
                    onChange={handleFormDataChange}
                    name='password'
                    required
                    id='input'
                  />
                  <Icon
                    className='absolute right-0 top-0 mt-4 mr-4 cursor-pointer'
                    icon={revealPass}
                    onClick={handlePasswordReveal}
                  />
                </div>
                <input
                  type={inputType}
                  placeholder='Confirm Password'
                  className='border-b-2 border-blue outline-none text-sm bg-transparent'
                  onChange={handleFormDataChange}
                  name='confirm_password'
                  required
                  id='input'
                />
                <input
                  type='text'
                  placeholder='Referral Code(Optional)'
                  className='border-b-2 border-blue outline-none text-sm bg-transparent'
                  onChange={handleFormDataChange}
                  name='referral_code'
                  value={refCode}
                  readOnly={refCode ? true : false}
                  id='input'
                />
                <div class='flex items-center' id='terms'>
                  <input
                    type='checkbox'
                    id='checkbox-example'
                    class='h-4 w-4 text-gray-700 border rounded mr-2'
                  />
                  <label
                    for='checkbox-example'
                    className='text-sm'
                    name='terms'
                    id='terms'
                  >
                    I agree to Logikeep’s{" "}
                    <span className='text-blue font-semibold'>
                      Privacy Terms
                    </span>{" "}
                    and{" "}
                    <span className='text-blue font-semibold'>Condition</span>
                  </label>
                </div>

                {errorDiv}
                <button
                  className='submitButton '
                  type='submit'
                  id='btn'
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent='warning' size={20} />
                  ) : (
                    "SIGN UP"
                  )}
                </button>
              </form>
            </div>
          </FormContainer>
          <OverlayContainer className='hidden lg:block' id='overlay'>
            <BouncyDiv>
              <img
                className=''
                src='/assets/registerImage.svg'
                id='imgBx'
                alt='img'
              />
            </BouncyDiv>
            <h2 className='text-blue text-lg font-semibold ml-2 text-center mt-2'>
              Maintaining good accounting records <br />
              is vital to the successful management <br /> of a business.
            </h2>
          </OverlayContainer>
        </Container>
      </Main>
    </>
  );
}

export default SignUp;
