import React, { useState } from "react";
import { category } from "./categoriesData";
import Nav from "./NavInventory";
import SearchInventory from "./SearchInventory";
import ItemTable from "./ItemTable";
import Container from "./Container";
import Axios from "axios";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../context";

const Inventory = () => {
  const { inventoryCount } = useGlobalContext();
  const [active, setActive] = useState(1);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [isModal, setIsModal] = useState(true);
  const [close, setClose] = useState(false);
  const [addItem, setAddItem] = useState([]);

  const [newItem, setNewItem] = useState("");

  const setAndSaveItems = (items) => {
    setAddItem(items);
    localStorage.setItem("items", JSON.stringify(items));
  };

  const addNewItem = (item, stock, unit) => {
    const id = addItem.length ? addItem[addItem.length - 1].id + 1 : 1;
    const theNewItem = {
      id,
      item,
      stock,
      unit,
      date: new Date().toLocaleDateString().split("/").reverse().join("-"),
    };
    const listItems = [...addItem, theNewItem];
    setAndSaveItems(listItems);
  };

  const [submit, setSubmit] = useState([]);


  const handleChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShow(false);
    setAddItem({
      Item: "",
      Stock: "",
      Unit: "",
    });

    if (!newItem) return;
    setSubmit([...submit, newItem]);
    addNewItem(newItem.item, newItem.stock, newItem.unit, newItem.date);
   
    setNewItem({
      item: "",
      stock: "",
      unit: "",
    });
  };

  const handleClose = () => {
    setClose(true);
    setShow(false);

  };

  const handleShow = () => {
    setShow(true);
    setClose(false);
    setIsModal(true);
  
  };

  const handleEdit = (id) => {
    setShow(true);
    setClose(false);
    setIsModal(false);

  };

  // //delete inventory

  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  let url;
  const token = localStorage.getItem("BearerToken");

  const handleDelete = async (id) => {
    url = `${baseUrl}deleteinventory/${id}`;
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete inventory!",
      }).then((result) => {
        if (result.isConfirmed) {
          Axios.delete(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          Swal.fire("Deleted!", "Inventory deleted successfully.", "success");
        }
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      });
    } catch (error) {
  
    }
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  
  };

  //Edit inventory

  return (
    <div className="text-black">
      <Nav
        category={category}
        active={active}
        setActive={setActive}
        addItem={addItem}
        count={inventoryCount}
      />

      <SearchInventory
        search={search}
        handleSearch={handleSearch}
        handleShow={handleShow}
      />
      <ItemTable
        handleEdit={handleEdit}
        item={addItem.filter(
          (item) =>
            item.item.toLowerCase().includes(search.toLowerCase()) ||
            item.unit.toLowerCase().includes(search.toLowerCase()) ||
            item.stock.toLowerCase().includes(search.toLowerCase()) ||
            item.date.toLowerCase().includes(search.toLowerCase())
        )}
        handleDelete={handleDelete}
      />

      <Container
        addItem={addItem}
        handleChange={handleChange}
        handleClose={handleClose}
        submit={submit}
        handleSubmit={handleSubmit}
        show={show}
        close={close}
        setClose={setClose}
        newItem={newItem}
        setNewItem={setNewItem}
        isModal={isModal}
        handleEdit={handleEdit}
      />
    </div>
  );
};

export default Inventory;
