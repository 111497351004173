import React from "react";
import { Icon } from "@iconify/react";

function Table({
  data,
  columns,
  columnsMobile,
  handleEdit,
  handleDelete,
  handleAlert,
  handleReceipt,
}) {
  return (
    <table className="table-fixed w-full text-center border-collapse border border-slate-400">
      <thead className="hidden md:table-header-group border-b-4 border-indigo-800">
        <tr className="p-3 bg-[#EFEFEF]">
          {columns.map((column) => (
            <th key={column.path || column.key}>{column.label}</th>
          ))}
          <th className='p-3'>Action</th>
        </tr>
      </thead>
      <thead className="md:hidden border-b-4 border-indigo-800">
        <tr className="text-xs bg-[#EFEFEF]">
          {columnsMobile.map((column) => (
            <th key={column.path || column.key}>{column.label}</th>
          ))}
          <th className='p-3'>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className="text-xs md:text-base ">
            {columns.map((column) => (
              <td key={column.path || column.key} className="overflow-x-auto px-2">
                {column.content ? column.content(item) : item[column.path]}
              </td>
            ))}
            {item.status ? (
              <td className='p-2'>
                <button
                  className='text-slate-500 bg-slate-200 p-2  rounded-lg text-xs md:text-base'
                  disabled
                >
                  Receipt Sent
                </button>
              </td>
            ) : item.actions ? (
              <td className='p-2 flex justify-center gap-2'>
                <button
                  onClick={() => handleEdit(item.id)}
                  className='text-blue bg-slate-200 p-2  rounded-lg text-xs md:text-base'
                >
                  <Icon icon='ci:edit' />
                </button>{" "}
                <button
                  onClick={() => handleDelete(item.id)}
                  className='text-rose-500 bg-slate-200 p-2  rounded-lg text-xs md:text-base'
                >
                  <Icon icon='bi:trash-fill' />
                </button>
                <button
                  onClick={() => handleAlert(item.id)}
                  className="text-yellow bg-amber-50 p-2 hidden md:block rounded-lg text-xs md:text-base"
                >
                  <Icon icon='heroicons:bell-alert-solid' />
                </button>
              </td>
            ) : item.customer ? (
              <td className='p-2 flex justify-center gap-2'>
                <button
                  onClick={() => handleEdit(item.id)}
                  className='text-blue bg-slate-200 p-2  rounded-lg text-xs md:text-base'
                >
                  <Icon icon='ci:edit' />
                </button>{" "}
                <button
                  onClick={() => handleDelete(item.id)}
                  className='text-rose-500 bg-slate-200 p-2  rounded-lg text-xs md:text-base'
                >
                  <Icon icon='bi:trash-fill' />
                </button>
              </td>
            ) : (
              <td className='p-2'>
                <button
                  onClick={() => handleReceipt(item.id)}
                  // onClick={handleEdit}
                  className='text-slate-100 bg-blue p-2 rounded-lg text-xs md:text-base'
                >
                  Send Receipt
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
