import {
   INCOMES_FAILURE,
   INCOMES_REQUEST,
   INCOMES_SUCCESS,
   INCOMES_RESET,
   } from "../../../actionTypes/dashboard/incomes/index";
   
   export const incomeRequest = () => {
     return {
       type:INCOMES_REQUEST,
     };
   };
   
   export const incomeSuccess = (data) => {
     return {
       type:INCOMES_SUCCESS,
       payload: data
     };
   };
   
   export const incomeFailure = (error) => {
     return {
       type :INCOMES_FAILURE,
       payload: error
     };
   };
   
   export const incomeReset = () => {
     return {
       type:INCOMES_RESET,
       payload: null
     };
   };
   
 