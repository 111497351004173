import React from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const date = new Date();
const day = date.getDate();
const month = months[date.getMonth()];
const year = date.getFullYear();

export const TodayDate = () => {
  if (day === 1) {
    return (
      <span>
        {month} {day}st, {year}
      </span>
    );
  } else if (day === 2) {
    return (
      <span>
        {month} {day}nd, {year}
      </span>
    );
  } else if (day === 3) {
    return (
      <span>
        {month} {day}rd, {year}
      </span>
    );
  } else {
    return (
      <span>
        {month} {day}th, {year}
      </span>
    );
  }
};



const currentTime = new Date().getHours();
let greeting;

const customStyle = {
  color: "",
};

if (currentTime < 12) {
  greeting = "Good Morning";
  customStyle.color = "blue";
} else if (currentTime < 18) {
  greeting = "Good Afternoon";
  customStyle.color = "blue";
} else {
  greeting = "Good Evening";
  customStyle.color = "blue";
}

export const Greet = ({ user }) => {
  return (
    <h1 className="heading" style={customStyle}>
      {greeting}, <span className="text-base mt-2 md:text-2xl">{user}</span>
    </h1>
  );
};
