import React from "react";
import AddItem from "./AddItem";

function Modal({
  handleClose,
  addItem,
  submit,
  handleSubmit,
  handleChange,
  newItem,
  setNewItem,
  isModal,
}) {
  return (
    <div className="flex justify-center h-full items-center">
      {isModal && (
        <AddItem
          handleClose={handleClose}
          submit={submit}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          addItem={addItem}
          newItem={newItem}
          setNewItem={setNewItem}
        />
      )}
    </div>
  );
}

export default Modal;


