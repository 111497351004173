import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import Timestamp from "../Timestamp";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

const Payment = () => {
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [recent, setRecent] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  // console.log(dashboardDetails);

  useEffect(() => {
    if (dashboardDetails?.length < 1) {
      setIsloading(true);
    } else {
      setIsloading(false);
      setRecent(dashboardDetails?.recenttransaction);
    }
  }, [dashboardDetails, isLoading]);

  const { theme } = useGlobalContext();
  const classes = classnames({
    "flex flex-col justify-between rounded-3xl md:p-5 transition duration-500 ease-in-out py-5 bg-white h-full md:h-[305px] px-4":
      theme === "light",
    "flex flex-col justify-between rounded-3xl md:p-5 py-5 bg-slate-800 h-full md:h-[305px] px-4 transition duration-500 ease-in-out bg-opacity-80":
      theme === "dark",
  });
  return (
    <div>
      <div className={classes}>
        <div className=''>
          <div className=''>
            <h1
              className={
                theme === "light"
                  ? "text-2xl font-bold text-center pb-3 transition duration-500 ease-in-out"
                  : "text-2xl font-bold text-center pb-3 text-white transition duration-500 ease-in-out"
              }
            >
              Recent Transactions.
            </h1>

            <div className='grid grid-cols-3 gap-2 bg-slate-200 rounded-full p-1 md:px-2'>
              <button className='hover:bg-white p-2 px-4 rounded-full self-center h-10 shadow-lg'>
                <div className='self-center'>Date</div>
              </button>
              <button className='hover:bg-white p-1  ml-4 rounded-full w-fit flex self-center h-10 shadow-lg'>
                <div className='self-center text-green-500'>
                  <Icon icon='akar-icons:arrow-down' />
                </div>
              </button>
              <button className='hover:bg-white p-1 rounded-full w-fit flex self-center h-10  shadow-lg'>
                <div className='self-center text-red-500'>
                  <Icon icon='akar-icons:arrow-up' />
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className='overflow-y-scroll scrollbar max-h-[200px] scroll-smooth '>
          {isLoading ? (
            <h2>Loading...</h2>
          ) : recent?.length < 1 ? (
            <div className='text-center py-5'>
              You don't have any transactions at the moment..
            </div>
          ) : (
            ""
          )}

          <div className='py-3'>
            {recent?.map((item, index) => {
              const date = item?.created_at.slice(0, 10);
              return (
                <div key={index} className='md:px-3 bg-slate-100 my-2 py-3'>
                  <div className='grid grid-cols-3 gap-2 p-2 md:p-1  w-full'>
                    <div className=' pl-2 text-sm'>
                      <Timestamp time={date} />
                    </div>
                    <div className='w-5 ml-4 md:w-auto text-green-500 text-sm whitespace-nowrap'>
                      {dashboardDetails?.currencysymbol} {item.inflow}
                    </div>{" "}
                    <div className='w-5 md:w-auto text-red-500 text-sm whitespace-nowrap'>
                      {dashboardDetails?.currencysymbol} {item.outflow}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
