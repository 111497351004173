import React from "react";
import Cards from "../../../components/business card/Cards";

const BusinessCards = () => {
  return (
    <div>
      <Cards />
    </div>
  );
};

export default BusinessCards;
