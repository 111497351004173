import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  updateBusinessSuccess,
  updateBusinessReset,
  updateBusinessFailure,
} from "../../../redux/actions/dashboard/businessInfo";
import { getBusinessRequest } from "../../../redux/actions/dashboard/businessInfo/getBuisnessInfo";
import LoadingSpin from "react-loading-spin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import Polygon from "../../../assets/images/Polygon1.png";
import Ellipse1 from "../../../assets/images/Ellipse2.png";
import "aos/dist/aos.css";
import { flip } from "react-animations";
import styled, { keyframes } from "styled-components";
import { useGlobalContext } from "../../../context";

const flipper = keyframes`${flip}`;
const FlipDiv = styled.div`
  animation: 10s infinite ${flipper};
`;

const BusinessDetails = () => {
  const { userProfile, allStates } = useGlobalContext();

  // input details
  const [business_doc, setBusiness_doc] = useState([]);
  const [business_logo, setBusiness_logo] = useState([]);
  const [business_name, setBusiness_name] = useState([]);
  const [business_address, setBusiness_address] = useState([]);
  const [business_phone, setBusiness_phone] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [state, setstate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //industry api call
  const onindustryChange = (event) => {
    const industryCode = event.target.value;
    setIndustry(industryCode);
  };

  useEffect(() => {
    const getIndustryData = async () => {
      await fetch("https://api.logikeep.africa/api/v1/getindustry")
        .then((response) => response.json())
        .then((data) => {
          const industries = data.data.map((industry) => ({
            name: industry.name,
            value: industry.name,
            id: industry.id,
          }));

          setIndustries(industries);
        });
    };
    getIndustryData();
  }, []);

  // state api call
  const onstateChange = (event) => {
    const stateCode = event.target.value;
    setstate(stateCode);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();
    for (let key in business_doc) {
      formdata.append("business_doc", business_doc[key]);
    }
    for (let key in business_logo) {
      formdata.append("business_logo", business_logo[key]);
    }

    formdata.append("business_name", business_name);
    formdata.append("business_address", business_address);
    formdata.append("business_phone", business_phone);
    formdata.append("state", state);
    formdata.append("industry", industry);

    const token = localStorage.getItem("BearerToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch(getBusinessRequest());
    setIsLoading(true);
    axios
      .post(
        `https://api.logikeep.africa/api/v1/updatebusiness`,
        formdata,
        config
      )
      .then((response) => {
        const data = response.data.data;
        dispatch(updateBusinessSuccess(data));
        // setUserProfile(data);
        setIsLoading(false);
        navigate("/update-page");
      })
      .catch((err) => {
        dispatch(updateBusinessFailure(err?.data));
        setIsLoading(false);
        setTimeout(() => {
          dispatch(updateBusinessReset());
        }, 3000);
      });
  };

  return (
    <>
      <div className="w-3 md:w-6 lg:w-12 absolute -top-10 md:-top-10 lg:top-80 left-10">
        <FlipDiv>
          <img className="animate-pulse" src={Polygon} alt="polygon" />
        </FlipDiv>
      </div>

      <h3 className="text-blue p-6 text-lg font-semibold flex justify-center">
        Kindly fill in your business information so we can properly take records
      </h3>
      <div>
        {/* form */}
        {!userProfile ? (
          <div className="flex justify-center">
            <div className={"ExampleOfUsage"}>
              <LoadingSpin />
            </div>
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="w-full max-w-3xl p-6 ">
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    Business Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-sm text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    required
                    name="business_name"
                    placeholder="Enter your business name"
                    onChange={(e) => setBusiness_name(e.target.value)}
                    value={userProfile.business_name}
                  />
                </div>
                {/* industry */}
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    Industry Of Profession
                  </label>
                  <select
                    name="industry"
                    id="industry"
                    value={industry}
                    className="form-select appearance-none mt-2
                block
                w-full max-w-3xl
                px-3
                py-3.5
                text-sm
               text-black
               bg-gray bg-clip-padding bg-no-repeat
                border border bg-gray
                 rounded
                 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    aria-label="Default select example"
                    onChange={onindustryChange}
                  >
                    <option>Select Industry</option>
                    {industries.map((industry) => (
                      <option key={industry.id} value={industry.id}>
                        {industry.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* state */}
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    State
                  </label>
                  <select
                    name="state"
                    id="state"
                    value={state}
                    className="form-select appearance-none mt-2
                block
                w-full max-w-3xl
                px-3
                py-3.5
                text-sm
               text-black
               bg-gray bg-clip-padding bg-no-repeat
                border border bg-gray
                 rounded
                 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    aria-label="Default select example"
                    onChange={onstateChange}
                  >
                    <option>Select state</option>
                    {allStates.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* business address */}
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    Business Address
                  </label>
                  <input
                    className="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    onChange={(e) => setBusiness_address(e.target.value)}
                    required
                    name="business_address"
                    placeholder="Enter your business address"
                    value={userProfile.business_address}
                  />
                </div>
                {/* business phone number */}
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Business Phone Number
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-sm text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    name="business_phone"
                    onChange={(e) => setBusiness_phone(e.target.value)}
                    required
                    placeholder="Enter your business phone number"
                    value={userProfile.phonenumber}
                  />
                </div>
              </div>
              {/* business logo */}

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Business Logo
                  </label>
                  <input
                    type="file"
                    name="business_logo"
                    accept=".jpg, .png"
                    onChange={(e) => {
                      setBusiness_logo(e.target.files);
                    }}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Business Document (CAC)
                  </label>
                  <input
                    type="file"
                    name="business_doc"
                    accept=".pdf"
                    onChange={(e) => {
                      setBusiness_doc(e.target.files);
                    }}
                  />
                </div>
              </div>

              <p className="text-sm italic">
                Supported formats are .jpg, .png and .pdf
              </p>

              {/* next */}
              <div className="flex justify-end py-2 px-4">
                <div className="border-2 border-blue rounded-lg py-1 px-2 text-center">
                  <Link to="/dashboard/profile" className="hover:no-underline">
                    1
                  </Link>
                </div>

                <div className="bg-blue rounded-lg py-1 px-4  ml-2 text-white">
                  <div
                    className="text-center mt-1 hover:cursor-pointer "
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    id=""
                  >
                    {isLoading ? (
                      <Spinner intent="warning" size={20} />
                    ) : (
                      "Submit"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="w-12 lg:w-20 right-20 md:right-64 lg:right-32 absolute -top-10 md:-top-10 lg:top-80 animate-pulse drop-shadow-2xl">
        <img className="animate-pulse" src={Ellipse1} alt="ellipse1" />
      </div>
    </>
  );
};

export default BusinessDetails;
