import {
    STATEMENT_FAILURE,
    STATEMENT_REQUEST,
    STATEMENT_SUCCESS,
    STATEMENT_RESET,
  } from "../../actionTypes/dashboard/businessStatement";
  
  const initialState = {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  };
  
  const statementReducer = (state = initialState, action) => {
    switch (action.type) {
      case STATEMENT_REQUEST:
        return { ...state, isLoading: true };
      case STATEMENT_SUCCESS:
        return {
          isLoading: false,
          isLoggedIn: true,
          error: null,
          // data: action.payload,
          user: action.payload,
          isError: false,
        };
      case STATEMENT_FAILURE:
        return {
          data: [],
          isLoading: false,
          error: action.payload,
          isError: true,
        };
      case STATEMENT_RESET:
        return {
          isLoading: false,
          isError: false,
          data: [],
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default statementReducer