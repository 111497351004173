import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import SkeletonLoader from "../../components/loaders/BlockLoader";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

import { Link } from "react-router-dom";

function TotalExpenses({ active, handleActive, isData }) {
  const { theme } = useGlobalContext();
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [boardDetails, setBoardDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dashboardDetails?.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setBoardDetails(dashboardDetails);
      // console.log(dashboardDetails, "detaisl")
    }
  }, [dashboardDetails, isLoading]);
  const classes = classnames({
    "hover:bg-blue bg-white transition duration-500 ease-in-out":
      theme === "light",
    "bg-slate-800 text-white transition duration-500 ease-in-out":
      theme === "dark",
  });


  return (
    <>
    
      {isLoading ? (
        <div className=''>
          <SkeletonLoader />
        </div>
      ) : (
        <div
          className={
            active === 2
              ? "flex flex-col space-y-10 bg-blue text-white p-5 w-full rounded-3xl bg-opacity-80"
              : `flex flex-col space-y-10  ${classes}  bg-opacity-80 hover:text-white p-5 w-full rounded-3xl hover:cursor-pointer`
          }
          onClick={() => handleActive(2)}
        >
          <div className='flex justify-between'>
            <div className='flex space-x-5'>
              <div className='icon flex self-center justify-center w-8 h-8 border-dotted border-2 border-white'>
                <Icon className='self-center' icon='majesticons:logout' />
              </div>

              <div className='title self-center'>
                <h1 className='text-xl'>Money Out</h1>
                <h6 className="text-sm">per month</h6>
              </div>
              
            </div>
            {/* <div className='totals__item__value'>
              <Icon icon='akar-icons:arrow-right' />
            </div> */}
          </div>

          <div className='flex justify-between'>
            <div className='flex space-x-5'>
              <p className='self-center'>
                {boardDetails?.currencysymbol} {boardDetails?.totalmonthlyexpenses}
              </p>
              <Link to="/dashboard/totalexpense" className='mt-0 hover:text-white cursor-pointer select-none'>
                  View All
                </Link>
            </div>
            
          <div className='flex justify-between'>
            
            </div>
            {/* <div className='flex justify-between'>
            <Link to="" className='text-blue mt-0 hover:text-white'>
                  View All
                </Link>
            </div> */}
            <div className='flex justify-between'>
              <div className='space-y-5'>
                <Icon icon='bi:graph-up' />
              </div>
            </div>
          </div>
        </div>
      )}
      
    </>
  )
          
}

export default TotalExpenses;
