import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { data } from "../../constants";
import { Link } from "react-router-dom";
import SkeletonLoader from "../../components/loaders/BlockLoader";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context";
import classnames from "classnames";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const TotalCustomersOwing = () => {
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [boardDetails, setBoardDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dashboardDetails?.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setBoardDetails(dashboardDetails);
      console.log(dashboardDetails, "details");
    }
  }, [dashboardDetails, isLoading]);
  const shareURL = boardDetails?.referral_link;

  const { theme } = useGlobalContext();
  const classes = classnames({
    "flex justify-between text-xl bg-white p-6 rounded-3xl h-full transition duration-500 ease-in-out":
      theme === "light",
    "flex justify-between text-xl bg-slate-800 text-white p-6 rounded-3xl h-full bg-white transition duration-500 ease-in-out bg-opacity-80":
      theme === "dark",
  });

  const cards = data.customersowing.map((item) => {
    return (
      <div key={item.id} className="w-full">
        {isLoading ? (
          <div className="">
            <SkeletonLoader />
          </div>
        ) : (
          <div>
            <div className={classes}>
              
              <div className="space-y-7">
                <h1>{item.title}</h1>
                <div className="flex space-x-5 ">
                  {item.id === 1 ? (
                    <p className="text-xl font-bold text-red-500">
                      NGN {boardDetails?.total_owing}{" "}
                    </p>
                  ) : (
                    <p className="text-2xl font-bold"></p>
                  )}
                  <Link to={item.url} className="text-blue mt-0">
                    View All
                  </Link>
                </div>
            <div></div>
              </div>
             

              <div className="self-end shadow-2xl">
                <Icon className="text-4xl text-blue" icon={item.icon} />
              
              </div>
            
            </div>
            
          </div>
          
        )}
      </div>
    );
  });

  return (
    <div className="pt-3 md:pt-0">
      <div className="flex md:flex-row flex-col md:flex-wrap lg:flex-nowrap gap-5">
        {cards}
      </div>
    </div>
  );
};

export default TotalCustomersOwing;
