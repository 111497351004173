 import React, { useState, useEffect } from "react";
import "../../../styles/screen.css";
import {
  Container,
  FormContainer,
  OverlayContainer,
  Main,
} from "../../../styles/styledElements";
import "../../../styles/responsiveness.css";
import {
  loginFailure,
  loginRequest,
  loginReset,
  loginSuccess,
  setToken,
} from "../../../redux/actions/auth/authActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { api } from "../../../services/api";
import { appUrls } from "../../../services/urls";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../home/Logo";
import { Icon } from "@iconify/react";
import styled, { keyframes } from "styled-components";
import { merge, fadeIn, bounce } from "react-animations";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";

const bounceFadeIn = merge(bounce, fadeIn);

const bounceAnimation = keyframes`${bounceFadeIn}`;
const BouncyDiv = styled.div`
  animation: 10s infinite ${bounceAnimation};
`;

function Login() {
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const dispatch = useDispatch();
  const loginReducder = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();
  const errorDiv = error ? (
    <small className="text-red-600 text-center w-52 mt-4">{error}</small>
  ) : (
    ""
  );

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData, 
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password: formData?.password,
      email: formData?.email
      
    };

    dispatch(loginRequest());
    setIsLoading(true);
    await api
      .post([appUrls.loginUrl, data])
      .then((res) => {
        dispatch(setToken(res.token));
        dispatch(loginSuccess(res));
        localStorage.setItem("BearerToken", res.token);
        setIsLoading(false);
        let businessName = res.data.business_name;
        businessName ? navigate("/dashboard") : navigate("/dashboard/business");
      })
      .catch((err) => {
        dispatch(loginFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(loginReset());
        }, 3000);
      });
  };

  const passwordRevealIcon = {
    isOpen: false,
    isType: true,
  };

  const [passwordReveal, setPasswordReveal] = useState(passwordRevealIcon);
  const revealPass = passwordReveal.isOpen ? "el:eye-open" : "el:eye-close";

  const inputType = passwordReveal.isType ? "password" : "text";

  const handlePasswordReveal = () => {
    setPasswordReveal({
      ...passwordReveal,
      isOpen: !passwordReveal.isOpen,
      isType: !passwordReveal.isType,
    });
  };

  return (
    <>
      <Main className="p-5 lg:p-0 lg:h-screen">
        <Container
          className="lg:h-full w-full lg:rounded-none rounded-lg grid lg:grid-cols-2 md:bg-white lg:bg-slate-200 md:p-4 md:p-8"
          id="containerBox"
        >
          <FormContainer
            id="sign-in-container"
            className="absolute md:relative sign-in space-y-5"
          >
            <div className="pb-5 flex justify-between">
              <Logo />
            </div>
            <div className="lg:px-20 space-y-5">
              <h1
                className="text-center text-blue text-lg font-semibold"
                id="logText"
              >
                Sign In to LogiKeep
              </h1>
              <p className="text-black text-center" id="loginText">
                We take care of everything for you, freeing up your time to
                focus on what you do best.
              </p>
              <form action="#" className="" onSubmit={handleLoginSubmit}>
                <input
                  type="text"
                  placeholder="Email Address"
                  className="border-b-2 border-blue outline-none text-sm bg-transparent focus:outline-none"
                  onChange={handleFormDataChange}
                  name="email"
                  required
                />
                <div className="relative w-full">
                  <input
                    type={inputType}
                    placeholder="Enter Password"
                    className="border-b-2 border-blue outline-none text-sm bg-transparent focus:outline-none"
                    onChange={handleFormDataChange}
                    name="password"
                    required
                  />
                  <Icon
                    className="absolute right-0 top-0 mt-4 mr-4 cursor-pointer"
                    icon={revealPass}
                    onClick={handlePasswordReveal}
                  />
                </div>
                {errorDiv}
                <div className="flex justify-between w-full items-center mt-4">
                  <div className="flex">
                    <input
                      type="checkbox"
                      id="checkbox-example"
                      className="h-4 w-4 text-gray-700 border rounded mr-2 "
                    />
                    <label htmlFor="checkbox-example" className="text-sm flex">
                      <div className="self-center" id="remember">
                        Remember me
                      </div>
                    </label>
                  </div>

                  <div>
                    <Link className="link" to="/forgot-password">
                      Forgot Password ?
                    </Link>
                  </div>
                </div>
                <button
                  className="submitButton relative"
                  type="submit"
                  id="btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent="warning" size={20} />
                  ) : (
                    "LOG IN"
                  )}
                </button>
              </form>
            </div>
            <div className="space-y-5 flex flex-col items-center">
              <h1 className="text-center">
                Don't have an Account?{" "}
                <Link to="/register">
                  <span className=" text-blue lg:text-xl ">Sign Up</span>
                </Link>
              </h1>

              <p className="text-center text-bold  text-blue">
                <Link className="text-xs" to="/">
                  Take me back to Homepage
                </Link>
              </p>
            </div>
          </FormContainer>
          <OverlayContainer className="hidden lg:block" id="overlay">
            <BouncyDiv>
              <img
                className=""
                src="/assets/registerImage.svg"
                id="imgBx"
                alt="img"
              />
            </BouncyDiv>

            <h2
              className="text-lg text-blue font-semibold ml-4 text-center"
              id="imgText"
            >
              Your Online Booking Team So That You
              <br />
              Can Do What You Love.
            </h2>
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <img
                  src="/assets/LogikeepLogo/LogikeepLogo.png"
                  id="imgBxForgot"
                  alt="img"
                />
              </div>
            </div>
          </OverlayContainer>
        </Container>
      </Main>
    </>
  );
}

export default Login;
