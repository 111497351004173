import {
    EXPENSESTATEMENT_FAILURE,
    EXPENSESTATEMENT_REQUEST,
    EXPENSESTATEMENT_SUCCESS,
    EXPENSESTATEMENT_RESET,
    } from "../../actionTypes/dashboard/expenseStatement";
    
    export const statementRequest = () => {
      return {
        type:EXPENSESTATEMENT_REQUEST,
      };
    };
    
    export const statementSuccess = (data) => {
      return {
        type:EXPENSESTATEMENT_SUCCESS,
        payload: data
      };
    };
    
    export const statementFailure = (error) => {
      return {
        type :EXPENSESTATEMENT_FAILURE,
        payload: error
      };
    };
    
    export const statementReset = () => {
      return {
        type:EXPENSESTATEMENT_RESET,
        payload: null
      };
    };
    
  