import React, { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import SearchBox from "../../../components/SearchBox";
import Axios from "axios";
import Grid from "../../../components/customer/grid";
import Swal from "sweetalert2";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import Pagination from "../../../components/pagination/Pagination";
import sms from "../../../assets/images/sms.png";
import { IoNotificationsSharp } from "react-icons/io5";

function Customer() {
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [close, setClose] = useState(false);
  const [search, setSearch] = useState("");
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [editId, setEditId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [defaulters, setDefaulters] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  let url;
  const token = localStorage.getItem("BearerToken");

  const getAllUsers = async (page) => {
    url = `${baseUrl}getcustomer?page=${page}`;
    try {
      const res = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = res.data;

      setCurrentPage(data.data.current_page);
      setCustomers(data.data.data);
      setPages(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaulters = async () => {
    url = `${baseUrl}owingtotalcustomers`;
    try {
      const res = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res);
      setDefaulters(res.data.data);
      const data = await res.data;
    } catch (error) {}
  };
  // console.log(defaulters);

  useEffect(() => {
    getAllUsers(currentPage);
    getDefaulters();
  }, [activeIndex, currentPage]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCloseModal = () => {
    setClose(true);
    setShowModal(false);
    setIsEdit(false);
  };

  const showAlert = (msg) => {
    Swal.fire({
      title: "Action Successful",
      text: msg,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    url = `${baseUrl}deletecustomer/${id}`;
    try {
      await Axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      showAlert("Customer succesfully deleted");
      getAllUsers();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [newCustomer, setNewCustomer] = useState([]);

  const handleAddCustomer = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const newCustomerPost = {
      customer_name: newCustomer.name,
      customer_phone: newCustomer.phone,
      customer_email: newCustomer.email,
    };
    if (newCustomer) {
      url = `${baseUrl}addcustomer`;
      try {
        await Axios.post(url, newCustomerPost, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setIsLoading(false);
        showAlert("New Customer Added Successfully");
        getAllUsers();
        setShowModal(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else return;
  };

  const handleInputChange = (e) => {
    setNewCustomer({
      ...newCustomer,
      [e.target.name]: e.target.value,
      customer: true,
    });
  };

  const handleEditInputChange = (e) => {
    setCurrentCustomer({ ...currentCustomer, [e.target.name]: e.target.value });
  };

  const handleEditCustomer = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const updatedCustomer = {
      customer_name: currentCustomer.name,
      customer_phone: currentCustomer.phone,
      customer_email: currentCustomer.email,
    };
    url = `${baseUrl}updatecustomer/${editId}`;
    try {
      await Axios.post(url, updatedCustomer, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      showAlert("Customer Details updated succesfully");
      setIsLoading(false);
      getAllUsers();
      setIsEdit(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const editCustomer = (newId) => {
    setEditId(newId);
    setIsEdit(true);
    const selectedData = customers.find(({ id }) => newId === id);
    setCurrentCustomer({
      name: selectedData.customer_name,
      phone: selectedData.phone_number,
      email: selectedData.email,
    });
  };
  const btns = ["Customers", "Customers owing"];
  let msg;

  const sendSMS = async (id) => {
    url = `${baseUrl}bulk-sms-reminder/${id}`;
    setIsLoading(true);
    try {
      Swal.fire({
        title: "Do you want to send Reminder to this Client by SMS?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Send",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await Axios.post(
              url,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            msg = res.data.message;
            // console.log(res);
            Swal.fire({
              icon: "success",
              title: "Reminder Sent!",
              text: msg,
            });
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            msg = error.response.data.message;

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: msg,
            });
          }
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {}
  };

  const sendEmail = async (id) => {
    url = `${baseUrl}bulk-email-reminder/${id}`;
    setIsLoading(true);
    try {
      Swal.fire({
        title: "Do you want to send Reminder to this Client by SMS?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Send",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await Axios.post(
              url,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            msg = res.data.message;
            // console.log(res);
            Swal.fire({
              icon: "success",
              title: "Reminder Sent!",
              text: msg,
            });
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            msg = error.response.data.message;
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: msg,
            });
          }
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {}
  };

  return (
    <div>
      <div className='flex'>
        {btns.map((btn, i) => {
          return (
            <div
              key={i}
              className={`${
                activeIndex === i ? "bg-blue text-white" : "text-black"
              } flex gap-3 bg-white rounded-t-2xl p-3 shadow-md cursor-pointer items-center`}
              onClick={() => setActiveIndex(i)}
            >
              <div className='self-center'>{btn}</div>
              <div className='flex flex-col text-center'>
                <div className='bg-blue rounded-full p-1'>
                  <h1 className='w-12 text-white'>
                    {i === 0 ? customers?.length : defaulters?.length}
                  </h1>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className='flex flex-col md:flex-row justify-between bg-white text-black p-5 gap-4'>
        <SearchBox
          placeholder={"Search customer"}
          value={search}
          onChange={handleSearch}
        />
        {activeIndex === 0 && (
          <button
            onClick={handleShowModal}
            className='border-[1px] p-3 w-fit bg-blue text-white rounded-md'
            type='submit'
          >
            + Add New Customer
          </button>
        )}
      </div>

      <div className='grid overflow-x-scroll text-black'>
        {activeIndex === 0 ? (
          <Grid
            data={customers.filter((item) =>
              item.customer_name.toLowerCase().includes(search.toLowerCase())
            )}
            editFunc={(id) => editCustomer(id)}
            deleteFunc={(id) => handleDelete(id)}
            state={isLoading}
          />
        ) : (
          <div className='bg-white text-black'>
            <div className='grid grid-cols-3 bg-slate-300 p-4 font-bold text-base'>
              <h2>Name</h2>
              <h2>Amount</h2>
              <h2>Send Reminder</h2>
            </div>
            {defaulters.length < 1 && (
              <h2>You don't have any customer owing you</h2>
            )}
            {defaulters?.map(({ data, customer }, i) => {
              return (
                <div
                  key={i}
                  className='grid grid-cols-3 gap-4 p-4 text-sm my-3'
                >
                  <h2>{customer.customer_name}</h2>
                  <h2>
                    <del className='decoration-double'>N</del>
                    {data.sum}
                  </h2>
                  <div className='flex gap-4'>
                    <i
                      className='text-xl bg-amber-200 w-10 p-2 text-amber-600 flex items-center justify-center rounded-md'
                      onClick={() => sendEmail(data?.customer_id)}
                    >
                      <IoNotificationsSharp />
                    </i>
                    <img
                      src={sms}
                      className='w-7 hover:scale-105 object-contain'
                      alt='sms'
                      onClick={() => sendSMS(data?.customer_id)}
                    />
                  </div>
                </div>
              );
            })}{" "}
          </div>
        )}

        <div className='flex bg-white'>
          <Pagination
            data={pages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      {/* Add new Customer */}

      {showModal && (
        <Modal
          close={close}
          show={showModal}
          handleClose={handleCloseModal}
          title='Add New Customer'
        >
          <form action='' onSubmit={handleAddCustomer}>
            <div className='flex flex-col space-y-3'>
              <div className='flex flex-col'>
                <label className='text-sm'>Name</label>
                <input
                  className='border-[1px] border-gray-300 rounded-md p-2'
                  type='text'
                  name='name'
                  value={customers.customer_name}
                  onChange={handleInputChange}
                  placeholder='Enter name'
                  required
                />
              </div>
              <div className='flex flex-col'>
                <label className='text-sm'>Email</label>
                <input
                  className='border-[1px] border-gray-300 rounded-md p-2'
                  type='email'
                  name='email'
                  value={customers.email}
                  onChange={handleInputChange}
                  placeholder='Enter email'
                />
              </div>
              <div className='flex flex-col'>
                <label className='text-sm'>Phone</label>
                <input
                  className='border-[1px] border-gray-300 rounded-md p-2'
                  type='text'
                  name='phone'
                  value={customers.phone_number && customers.customer}
                  onChange={handleInputChange}
                  placeholder='Enter phone'
                  required
                />
              </div>

              <div className='flex space-x-3'>
                <button
                  className='border-[1px] px-3 h-9 self-center bg-blue text-white rounded-md'
                  type='submit'
                >
                  {isLoading ? <Spinner intent='warning' size={20} /> : "Save"}
                </button>
                <button
                  onClick={handleCloseModal}
                  className='border-[1px] px-3 h-9 self-center bg-red text-white rounded-md'
                  type='submit'
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
      )}

      {/* Edit customer */}
      {isEdit && (
        <Modal
          close={close}
          show={showModal}
          handleClose={handleCloseModal}
          title='Edit Customer'
        >
          <form action='' onSubmit={handleEditCustomer}>
            <div className='flex flex-col space-y-3 text-black'>
              <div className='flex flex-col'>
                <label className='text-sm'>Name</label>
                <input
                  className='border-[1px] border-gray-300 rounded-md p-2'
                  type='text'
                  name='name'
                  value={currentCustomer.name}
                  onChange={handleEditInputChange}
                  placeholder='Enter name'
                  required
                />
              </div>
              <div className='flex flex-col'>
                <label className='text-sm'>Email</label>
                <input
                  className='border-[1px] border-gray-300 rounded-md p-2'
                  type='email'
                  name='email'
                  value={currentCustomer.email}
                  onChange={handleEditInputChange}
                  placeholder='Enter email'
                />
              </div>
              <div className='flex flex-col'>
                <label className='text-sm'>Phone</label>
                <input
                  className='border-[1px] border-gray-300 rounded-md p-2'
                  type='text'
                  name='phone'
                  value={currentCustomer.phone}
                  onChange={handleEditInputChange}
                  placeholder='Enter phone'
                  required
                />
              </div>
              <div className='flex space-x-3'>
                <button
                  className='border-[1px] px-3 h-9 self-center bg-blue text-white rounded-md'
                  type='submit'
                >
                  {isLoading ? <Spinner intent='warning' size={20} /> : "Save"}
                </button>
                <button
                  onClick={handleCloseModal}
                  className='border-[1px] px-3 h-9 self-center bg-red text-white rounded-md'
                  type='submit'
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}

export default Customer;
