import React, { useEffect } from "react";
import tw from "twin.macro";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import TopEllipse from "../../../assets/images/Ellipsetop.png";
import manage from "../../../assets/images/manage.jpeg";
import styled from "styled-components";
import Zoom from "react-reveal/Zoom";

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const DivContainer = styled.div`
  ${tw``}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col text-center lg:justify-self-center`}
`;


export default function Content() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer className="bg-white lg:-mt-20 mt-[75px]" id="content2">
        <div className="relative -top-10">
          <img src={TopEllipse} alt="ellipse" className="topEllipse" />
        </div>
        {/* <h1 className="text-blue text-center pb-4 font-bold text-4xl">ABOUT US</h1> */}
        <DivContainer id="contentContainer">
          <Col className="relative">
            <h2 className="text-blue text-center mt-6 font-bold text-3xl">
              Articles and News.
            </h2>
            <p className="pt-4 leading-loose text-center text-lg">
              Logikeep Blog serves as a knowledge center for current trends in <br/>
              contemporary accounting, business finance, digital technology, <br/>
              financial lifestyles, and beyond.
            </p>
          </Col>
         
        </DivContainer>
      </ContentContainer>
    </>
  );
}
