import React, { useEffect } from "react";
import SearchBox from "../../components/SearchBox";
import Grid from "../../components/history/grid";
import DateInput from "../../components/DateSelector";
import { Icon } from "@iconify/react";
import Edit from "./edit";
import { useGlobalContext } from "../../context";
import { CSVLink } from "react-csv";
import Pagination from "../../components/pagination/Pagination";

function PartPayment({ search, handleSearch }) {
  const {
    partPaymentData,
    currency,
    editFunc,
    partHistoryResponse,
    currentPage,
    handlePageChange,
  } = useGlobalContext();

  return (
    <div>
      <div className='flex justify-between pb-5 space-x-2'>
        <SearchBox
          placeholder={"Search customer"}
          value={search}
          onChange={handleSearch}
        />
        <div className='flex  space-x-2 md:space-x-5'>
          <div className=' self-center'>
            <button className='bg-white shadow-lg rounded-lg p-3 flex md:space-x-5 self-center'>
              <Icon className='self-center' icon='el:download-alt' />
              <span className='text-xs m-0 w-16'>Export CSV</span>
            </button>
          </div>
          <div className=' w-32 self-center'>
            <DateInput />
          </div>
        </div>
      </div>
      <div className='overflow-scroll'>
        <Grid
          code={currency}
          part
          items={partPaymentData.filter((item) =>
            item.customer.customer_name
              .toLowerCase()
              .includes(search.toLowerCase())
          )}
          editFunc={editFunc}
          type='part'
        />
      </div>
      <div className='flex justify-end my-10'>
        <Pagination
          data={partHistoryResponse}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <Edit />
    </div>
  );
}

export default PartPayment;
