export const category = [
  {
    id: 1,
    name: "All Items",
    value: 1653,
  },
  // {
  //   id: 2,
  //   name: "Drinks",
  //   value: 100,
  // },
  // {
  //   id: 3,
  //   name: "Airtime",
  //   value: 200,
  // },
  // {
  //   id: 4,
  //   name: "Shoes",
  //   value: 300,
  // },
];
