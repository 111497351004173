import React, { useEffect, useState } from "react";
import "../successPage/successPage.css";
import { Link } from "react-router-dom";
import Logo from "../../home/Logo";
import {
  getProfileRequest,
  getProfileSuccess,
  getProfileReset,
  getProfileFailure,
} from "../../../redux/actions/dashboard/userProfile";
import axios from "axios";
import { useDispatch } from "react-redux";
import Confetti from "react-confetti";

const SuccessPage = () => {
  const [userProfile, setUserProfile] = useState([]);
  const [username, setUserName] = useState();
  const dispatch = useDispatch();

  // bearerToken
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const baseUrl = `https://api.logikeep.africa/api/v1/getuserprofile`;

  // get profile api call
  const fetchProfile = () => {
    dispatch(getProfileRequest());
    axios
      .get(baseUrl, config)
      .then((response) => {
        dispatch(getProfileSuccess(response?.data?.data?.user));
        setUserProfile(response?.data?.data?.user);
        setUserName(response?.data?.data?.firstname);
      })
      .catch((err) => {
        dispatch(getProfileFailure(err?.data));
        setTimeout(() => {
          dispatch(getProfileReset());
        }, 5000);
      
      });
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <div>
      <Confetti />
      <div className="modal">
        <div className="overlayModal"></div>

        <div className="modal-content">
          <div className="pb-5">
            <Logo />
          </div>
          <span className="uppercase text-blue font-semibold text-lg flex justify-center">
            good job, {username}
          </span>
          <div className="flex">
            {/* <div>
              <img src="/assets/businessInfo.svg" alt="logo" className="w-38"  />
            </div>
            */}
            <div className="details text-base font-semibold text-center italic">
              {" "}
              Now that you’ve setup your business profile
              <br />
              You can go ahead to view your dashboard.
              <div className="flex justify-center">
                <Link to="/dashboard">
                  <button
                    className=" text-sm bg-BACKGROUND_BLUE text-white
                 py-2 px-6 rounded uppercase "
                  >
                    Dashboard
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
