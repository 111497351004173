import styled from "styled-components";
// import Books from "../assets/images/books.jpg";

export const Main = styled.div`
background: #f6f5f7;
${'' /* background-image: url("${Books}"); */}
background-position: center;
background-repeat: no-repeat;
background-size: cover;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
${'' /* height: 100vh; */}
${'' /* max-width: 400px; */}
${"" /* // margin: -20px 0 50px; */}
${"" /* margin-top: 80px */}

`;
export const ReviewStar = styled.div`
  color: #ffa500;
`;
// export const PriceCard = styled.div`
// color: #f6f5f7;
// `;

export const SubmitButton = styled.button`
border-radius: 8px;
	border: 1px solid #0118E8;
	background-color: #0118E8;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}
`;
export const OtherButton = styled.button`
border-radius: 8px;
	border: 1px solid #0118E8;
	background-color: #0118E8;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 5px;
	letter-spacing: 1px;
	transition: transform 80ms ease-in;
  width: 120px;
}
`;

export const Button1 = styled.button`
border-radius: 8px;
	border: 1px solid #0118E8;
	background-color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	transition: transform 80ms ease-in;
  width: 280px;
}`;
export const Container = styled.div`
  ${'' /* background-color: #fff; */}
  ${'' /* border-radius: 20px; */}
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  ${"" /* position: relative; */}
  overflow: hidden;
  ${'' /* width: 900px; */}
  max-width: 100%;
 ${'' /* height: 100%; */}
`;
// export const Container2 = styled.div`
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
//   position: relative;
//   overflow: hidden;
//   width: 868px;
//   max-width: 100%;
//   min-height: 590px;
//   margin-top: 80px;
// `;
export const FormContainer = styled.div`
  ${'' /* position: absolute; */}
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  padding: calc(2px + 1.5625vw);
`;

export const OverlayContainer = styled.div`
  ${'' /* position: absolute; */}
  top: 0;
  left: 50%;
  ${'' /* width: 50%; */}
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;

  background: #c7d3f3;
`;
export const LinkStyle = styled.div`
  color: #333;
  font-size: 2px;
  text-decoration: none;
  margin: 5px 0;
`;
