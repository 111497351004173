import React from 'react';
import Navbar from "../../../pages/home/Navbar/index";
import Footer from '../../home/Navbar/footer';
import Section from './Section';
import SecondSection from './SecondSection';
import Choose from './Choose';
import Visit from './Visit';
import Review from './Review';


const About = () => {
  return (
   <>
   <Navbar/>
   <Section/>
   <SecondSection/>
   <Choose/>
   <Visit/>
   <Review/>
   <Footer/>
   </>
  )
}

export default About
