import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import sidebarNav from "../../configs/sidebarNav";
import { logout, setIndex } from "../../redux/actions/auth/authActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../services/api";
import { appUrls } from "../../services/urls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Polygon from "../../assets/images/logikeeppngwhite.png";
import "../../styles/responsiveness.css";
import { useGlobalContext } from "../../context";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";
import Axios from "axios";

const Sidebar = () => {
  const { getNotifications, toggleTheme, theme } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedIndex } = useSelector((state) => state.sidebarReducer);
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [isPlan, setIsPlan] = useState();

  useEffect(() => {
    setIsPlan(dashboardDetails?.plan);
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location, dashboardDetails]);

  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
      document.querySelector(".main__content").style = "";
    }, 500);
  };
  const token = localStorage.getItem("BearerToken");
  let msg;
  const handleLogout = async () => {
    try {
      Swal.fire({
        title: "Do you want to logout",
        text: "Are you sure you want to logout from your account!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await api.post([appUrls.logoutUrl]).then((res) => {
              dispatch(logout(res));
              navigate("/login");
              msg = res.data.message;
              localStorage.removeItem("BearerToken");
            });

            msg = res.data.message;
        
            Swal.fire({
              icon: "success",
              title: "Logout",
              text: msg,
            });
          } catch (error) {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {}
    // await api.post([appUrls.logoutUrl]).then((res) => {
    //   dispatch(logout(res));
    //   navigate("/loginn");
    //   localStorage.removeItem("BearerToken");
    //   toast.success("Logout Successfully !", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // });
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={Polygon} alt="logo" />
        <div className="sidebar-close" onClick={closeSidebar}>
          <i className="bx bx-x"></i>
        </div>
      </div>
      <div className="sidebar__menu overflow-y-auto">
        {(isPlan === "free" || isPlan === undefined) &&
          sidebarNav
            .filter((nav) => nav.plan !== "classic")
            .map((nav, index) => (
              <Link
                key={nav.id}
                to={nav.link}
                className={`sidebar__menu__item ${
                  selectedIndex === index ? "bg-white text-blue" : ""
                } mx-4 rounded-full my-2`}
                onClick={() => {
                  closeSidebar();
                  dispatch(setIndex(index));
                }}
              >
                <div className="sidebar__menu__item__icon">{nav.icon}</div>
                <div className="sidebar__menu__item__txt">{nav.text}</div>
              </Link>
            ))}
        {isPlan === "classic" &&
          sidebarNav.map((nav, index) => (
            <Link
              key={nav.id}
              to={nav.link}
              className={`sidebar__menu__item ${
                selectedIndex === index ? "bg-white text-blue" : ""
              } mx-4 rounded-full my-2`}
              onClick={() => {
                closeSidebar();
                dispatch(setIndex(index));
              }}
            >
              <div className="sidebar__menu__item__icon">{nav.icon}</div>
              <div className="sidebar__menu__item__txt">{nav.text}</div>
            </Link>
          ))}

        <div className="sidebar__menu__item ml-5">
          <button onClick={toggleTheme} className="rounded-full p-2">
            {theme === "light" ? (
              <div className="flex">
                <Icon icon="icon-park-solid:dark-mode" className="mt-1" />
                <div className="ml-7"> Dark mode</div>
              </div>
            ) : (
              <div className="flex">
                <Icon icon="icon-park-solid:dark-mode" className="mt-1" />
                <div className="ml-7"> Light mode</div>
              </div>
            )}
          </button>
        </div>
        <div className="sidebar__menu__item ml-4 pt-3" id="logoutBtn">
          <div className="sidebar__menu__item__icon">
            <i className="bx bx-log-out"></i>
          </div>
          <div className="sidebar__menu__item__txt" onClick={handleLogout}>
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
