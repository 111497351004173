import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import "aos/dist/aos.css";
import { format } from "date-fns"; // Import the format function
import styled from "styled-components";
import Axios from "axios";
import { Link } from 'react-router-dom';

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;

export default function Content() {
  const [blog, setBlog] = useState({});
  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  const imageUrl = `https://api.logikeep.africa/`;
  const getAllBlogs = async () => {
    const url = `${baseUrl}blogs`;
    try {
      const res = await Axios.get(url);
      const data = res.data;

      setBlog(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <>
      <ContentContainer className="bg-white lg:-mt-20  " id="content2">
        <div className="">
          <div className="blog-grid">
            {Object.keys(blog).length > 0 ? (
              Object.values(blog).map((blogItem) => (
                <div key={blogItem.id} className="grid justify-items-center">
                  
                  <div class="BlogCard_fcard__sKa6T ">
                    <div>
                

                      <div class="BlogCard_featuredImage__zm0vO">
                        <img
                          class="BlogCard_image__oI9Zx"
                          src={imageUrl + blogItem.blog_image}
                          alt={blogItem.title}
                          width="100%"
                        />
                      
                      </div>

                      <div class="undefined pt-3 pb-2">
                        <Link to={`/blog/${blogItem.id}`}>
                          <h4 class="BlogCard_blockEllipsis__zYaDQ BlogCard_heading__kF6L3 ">
                            {blogItem.title}
                          </h4>
                        </Link>
                      </div>
                      <div>
                        <p class="mb-3">
                          {format(
                            new Date(blogItem.created_at),
                            "MMMM dd, yyyy"
                          )}
                        </p>
                      </div>
                    </div>
                    {/* <div class="BlogCard_author__rK4wK">
                      <div class="flex justify-content">
                        <img
                          class="BlogCard_avatar__uUkZe me-2"
                          src="https://tymsbook.s3.eu-west-2.amazonaws.com/temp_upload/YxNH9br5yXVmIR1DQrEzGJrJnnAs0i3PivDRvokC.png"
                        />
                        <p class="BlogCard_authorName__z6Boj mb-0">
                          Logikeep
                        </p>
                      </div>
                      <div>
                        <p class="mb-0">5 mins read</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center font-bold text-xl pt-4">Loading blogs...</p>
            )}
          </div>
        </div>
      </ContentContainer>
    </>
  );
}
