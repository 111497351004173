import AppRoute from "./router/index";
import { AppProvider } from "./context";

function App() {
  return (
    <AppProvider >
      <AppRoute/>
    </AppProvider>
  );
}

export default App;
