import images from "./images";
import ThisWeek from "../assets/images/week.png";
import ThisMonth from "../assets/images/month.png";

const data = {
  user: {
    name: "David",
    img: images.avt,
  },

  weekMonth: [
    {
      id: 1,
      title: "Total Credit this Week",
      value: 3.45,
      img: ThisWeek,
      icon: "🤑",
    },
    {
      id: 2,
      title: "Total Debit this Week",
      value: 7.13,
      img: ThisMonth,
      icon: "😢",
    },
  ],

  revenueSummary: {
    title: "Revenue",
    value: "$678",
    chartData: {
      labels: ["May", "Jun", "July", "Aug", "May", "Jun", "July", "Aug"],
      data: [300, 300, 280, 380, 200, 300, 280, 350],
    },
  },
  overall: [
    {
      id: 1,
      value: 78,
      title: "Customers",
      url: "customers",
      icon: "fa6-solid:users-between-lines",
    },
    {
      id: 2,
      value: 4,
      title: "Payment Overdue",
      url: "overdue",
      icon: "fa6-solid:file-invoice-dollar",
    },
  ],

    customersowing: [
    {
      id: 1,
      value: 78,
      title: "Money yet to be paid",
      url: "customers",
      icon:  "fa6-solid:file-invoice-dollar",
    },
    // {
    //   id: 1,
    //   value: 78,
    //   title: "Money yet to be paid",
    //   url: "customers",
    //   icon:  "fa6-solid:file-invoice-dollar",
    // },
   
   
  ],
  revenueByChannel: [
    {
      title: "Direct",
      value: 40,
    },
  ],
  revenueByMonths: {
    labels: [
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
    ],
    data: [250, 200, 300, 280, 100, 220, 310, 190, 200, 120, 250, 350],
  },
};

export default data;
