import React, { useState, useEffect } from "react";
import axios from "axios";
import { data } from "./premiumData";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

const Premium = () => {
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const [isSubscribe, setIsSubscribe] = useState("");
  const [isPlan, setIsPlan] = useState();
  const { texts, button } = data;
  const [showModal, setShowModal] = useState(false);
  const [close, setClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // bearerToken
  const token = localStorage.getItem("BearerToken");

  useEffect(() => {
    setIsPlan(dashboardDetails?.plan);
    setIsSubscribe(dashboardDetails?.subscription);
  }, [dashboardDetails]);

  const subscribe = () => {
    const instance = axios.create({
      baseURL: "https://api.logikeep.africa/api/v1/",
      headers: {
        Accept: "application/json",
      },
    });
    setIsLoading(true);
    instance
      .post(
        "upgradeaccount",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setShowModal(false);
        Swal.fire({
          icon: "success",
          title: "Plan Upgraded Successfully",
          text: response.data.message,
          button: "Ok!",
        });
        //refresh page
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        setIsLoading(false);
        // console.log(error);
        // console.log(error.response);
        setShowModal(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          confirmButtonText: "OK! Top-up",
          showCancelButton: true,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/dashboard/wallet");
          }
        });
      });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setClose(true);
    setShowModal(false);
  };
  const { theme } = useGlobalContext();
  const classes = classnames({
    "flex flex-col md:w-[280px] bg-white transition duration-500 ease-in-out p-6 justify-between shadow-lg rounded-3xl h-full":
      theme === "light",
    "flex flex-col md:w-[280px] bg-slate-800 text-white transition duration-500 ease-in-out bg-opacity-80 p-6 justify-between shadow-lg rounded-3xl h-full":
      theme === "dark",
  });
  const headerClass = classnames({
    "text-blue transition duration-500 ease-in-out capitalize ml-2":
      theme === "light",
    "text-white transition duration-500 ease-in-out capitalize ml-2":
      theme === "dark",
  });
  return (
    <>
      <div className={classes}>
        <div className='self-center'>
          <h1 className=''>
            Plan: <span className={headerClass}>{isPlan}</span>
          </h1>
        </div>
        <div className='w-32 self-center'>
          <img src={texts.img} alt='' />
        </div>

        <div
          className={
            theme === "light"
              ? "flex flex-col justify-center text-black"
              : "flex flex-col justify-center text-white"
          }
        >
          {isPlan === "free" && (
            <p>
              Get access to unlimited email units, virtual business cards and{" "}
              <span className='font-extrabold'>points-to-recharge-card </span>
              feature when you upgrade your account to{" "}
              <span className='font-extrabold text-blue'>Classic </span>
              with just{" "}
              <span className='font-extrabold text-red-700'>#800</span> Monthly.
            </p>
          )}

          {isPlan === "free" ? (
            <button
              onClick={handleShowModal}
              className='rounded-lg px-8 py-2 bg-blue mb-2 text-white mt-2'
            >
              <small className='animate-pulse'>
                {button.text.toLocaleUpperCase()}
              </small>
            </button>
          ) : (
            <div className='rounded-lg  py-2 bg-slate-200 mb-2 text-red-700 mt-2'>
              <p className='animate-pulse text-center'>{isSubscribe}</p>
            </div>
          )}
        </div>
        {showModal && (
          <Modal
            close={close}
            show={showModal}
            handleClose={handleCloseModal}
            title=''
          >
            <div className='flex flex-col space-y-5'>
              <div className='flex flex-col space-y-2'>
                <div className='space-y-5'>
                  <h4 className='font-bold text-xl'>
                    Benefits When You Upgrade Your Account
                  </h4>
                  <ul className='space-y-3'>
                    <li>
                      - Access to unlimited emails for reminder to customers
                    </li>
                    <li>
                      - Access to redeem point and convert to recharge card
                    </li>
                    <li>
                      - Access to 50 units for whatsApp reminders to customers
                    </li>
                    <li>- Access to 50 sms units for reminders to customers</li>
                    <li>- Access to create virtual business card</li>
                  </ul>
                </div>
                <div className='flex justify-center'>
                  <button
                    onClick={subscribe}
                    disabled={isLoading}
                    className='submitButton rounded-lg px-8 py-2 bg-blue mb-2 text-white mt-2 '
                  >
                    {isLoading ? (
                      <Spinner intent='warning' size={20} />
                    ) : (
                      "Subscribe Now"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Premium;
