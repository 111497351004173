import {
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_RESET,
    FORGOT_PASSWORD_SUCCESS,
  } from "../../../actionTypes/auth/changePassword/forgotPassword";
  
  const initialState = {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  };
  
  const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case FORGOT_PASSWORD_REQUEST:
        return { ...state, isLoading: true };
      case FORGOT_PASSWORD_SUCCESS:
        return {
          isLoading: false,
          error: null,
          data: action.payload,
          isError: false,
        };
      case FORGOT_PASSWORD_FAILURE:
        return {
          data: [],
          isLoading: false,
          error: action.payload,
          isError: true,
        };
      case FORGOT_PASSWORD_RESET:
        return {
          isLoading: false,
          isError: false,
          data: [],
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default forgotPasswordReducer;
  