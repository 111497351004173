import React from "react";
import { useGlobalContext } from "../context";
import classnames from "classnames";

function Modal({ children, title, showModal, handleClose }) {
  const { theme } = useGlobalContext();
  const classes = classnames({
    "modal-card bg-white p-5 rounded-xl flex self-center flex-col justify-center max-w-lg absolute w-full transition duration-500 ease-in-out":
      theme === "light",
    "modal-card p-5 rounded-xl flex self-center flex-col justify-center max-w-lg absolute text-white w-full bg-slate-800 text-white transition duration-500 ease-in-out":
      theme === "dark",
  });
  return (
    <div
      className={`modal modal-background flex justify-center relative ${
        showModal ? "is-active" : ""
      }`}
      style={{
        zIndex: 100,
      }}
    >
      <div className="w-full h-full -z-50 absolute" onClick={handleClose}></div>
      <div className={classes}>
        <header className="modal-card-head flex justify-between">
          <p className="modal-card-title">{title}</p>
          <button className="delete" aria-label="close" onClick={handleClose}>
            close
          </button>
        </header>
        <section className="modal-card-body">{children}</section>
        {/* <footer className="modal-card-foot">
          <button className="button is-success">Save changes</button>
          <button className="button" onClick={handleClose}>
            Cancel
          </button>
        </footer> */}
      </div>
    </div>
  );
}

export default Modal;
