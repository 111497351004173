import {
    GETDASHBOARD_FAILURE,
    GETDASHBOARD_SUCCESS,
    GETDASHBOARD_REQUEST,
    GETDASHBOARD_RESET,
  } from "../../../actionTypes/dashboard/totalDashboard/totalIncome";
  
  const initialState = {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  };
  
  const totalIncomeReducer = (state = initialState, action) => {
    switch (action.type) {
      case GETDASHBOARD_REQUEST:
        return { ...state, isLoading: true };
      case GETDASHBOARD_SUCCESS:
        return {
          isLoading: false,
          isLoggedIn: true,
          error: null,
          // data: action.payload,
          user: action.payload,
          isError: false,
        };
      case GETDASHBOARD_FAILURE:
        return {
          data: [],
          isLoading: false,
          error: action.payload,
          isError: true,
        };
      case GETDASHBOARD_RESET:
        return {
          isLoading: false,
          isError: false,
          data: [],
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default totalIncomeReducer