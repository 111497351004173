import React from "react";
import "./user-info.scss";
import { useGlobalContext } from "../../context";
import { Greet } from "../date/GetDate";
import Avatar from "../../assets/images/avatar-image.png";
import Skeleton from "../../components/loaders/Skeleton";

const UserInfo = () => {
  const { userProfile, isLoading } = useGlobalContext();

  const imageUrl = `https://api.logikeep.africa`;

  return (
    <>
      <div className='user-info relative'>
        <div className='user-info__img '>
          {isLoading ? (
            <Skeleton />
          ) : userProfile?.business_logo === "NULL" ? (
            <img src={Avatar} className='ml-2 w-32' alt='profile-pic' />
          ) : userProfile?.business_logo === "undefined" ? (
            <img src={Avatar} className='ml-2 w-32' alt='profile-pic' />
          ) : (
            <img
              src={imageUrl + userProfile?.business_logo}
              className='ml-2 w-32'
              alt='profile-pic'
            />
          )}
        </div>
        <div className='px-4 text-base md:user-info__name'>
          <Greet user={isLoading ? "..." : userProfile?.firstname} />
        </div>
      </div>
    </>
  );
};

export default UserInfo;
