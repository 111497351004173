import React, { useState, useRef } from "react";
import "./cards.css";
import { useGlobalContext } from "../../context";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import * as htmlToImage from "html-to-image";
import { Spinner } from "@blueprintjs/core";

const Cards = () => {
  const [active, setActive] = useState(1);
  const [formData, setFormData] = useState({
    title: "[title here]",
    business: "[business name]",
    tagline: "[tagline here]",
  });
  const [imgUrl, setImgUrl] = useState("");
  const domEl = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const downloadImage = async () => {
    setIsLoading(true);
    const dataUrl = await htmlToImage
      .toPng(domEl.current)
      .then(function(dataUrl) {
        setImgUrl(dataUrl);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });

    // download image
    // const link = document.createElement("a");
    // link.download = "html-to-img.png";
    // link.href = dataUrl;
    // link.click();
  };

  const { userProfile } = useGlobalContext();

  const handleFormDataChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleActive = (e) => {
    setActive(e);
  };

  return (
    <div>
      <div className="text-center my-5">
        <h1 className="text-blue text-2xl">Fast and Easy Business Cards</h1>
        <p className="text-xs">Customize your personal business card</p>
      </div>

      <div className="md:grid grid-row md:grid-cols-2 gap-32 space-y-5 md:space-y-0">
        <div>
          <form>
            <div className="flex flex-col space-y-1">
              <input
                onChange={handleFormDataChange}
                type="text"
                name="title"
                placeholder="Title"
                required
                maxLength={10}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <input
                onChange={handleFormDataChange}
                type="text"
                name="business"
                placeholder="Business name or alias"
                required
                maxLength={15}
              />
              <small className="self-end">Max. 15 characters </small>
            </div>
            <div className="flex flex-col space-y-1">
              <input
                onChange={handleFormDataChange}
                type="text"
                name="tagline"
                placeholder="Business Tagline"
                required
                maxLength={30}
              />
              <small className="self-end">Max. 30 characters </small>
            </div>
          </form>
          <div className="flex justify-center">
            {isLoading ? (
              <div className="flex flex-col gap-5 mt-20 self-center">
                <Spinner intent="warning" size={40} />
                <div>Creating your Business card...</div>
              </div>
            ) : (
              <div>
                <div className="max-w-sm">
                  <img src={imgUrl} alt="" />
                </div>
                {imgUrl && (
                  <div className="flex justify-end max-w-sm">
                    <a
                      className="bg-blue text-white p-2 rounded-lg hover:no-underline hover:text-white"
                      href={imgUrl}
                      download="my-business-card.png"
                    >
                      Download Card
                    </a>
                    {/* <a href={imgUrl} target="_blank" rel="noreferrer">
                    Share Card
                  </a> */}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col max-w-sm">
          <div className="h-72">
            <Card1
              active={active}
              userProfile={userProfile}
              formData={formData}
              domEl={domEl}
            />
            <Card2
              active={active}
              userProfile={userProfile}
              formData={formData}
              domEl={domEl}
            />

            <Card3
              active={active}
              userProfile={userProfile}
              formData={formData}
              domEl={domEl}
            />
          </div>

          <div className="space-x-3 text-white self-center md:w-96 mb-3">
            <button
              onClick={() => handleActive(1)}
              className={
                active === 1
                  ? "md:w-10 w-5 h-5 md:h-10 w-5 h-5 rounded-full bg-slate-500	"
                  : "bg-slate-300	w-5 h-5 md:w-10 md:h-10 rounded-full"
              }
            ></button>
            <button
              onClick={() => handleActive(2)}
              className={
                active === 2
                  ? "md:w-10 w-5 h-5 md:h-10 w-5 h-5 rounded-full bg-slate-500	"
                  : "bg-slate-300	w-5 h-5 md:w-10 md:h-10 rounded-full"
              }
            ></button>
            <button
              onClick={() => handleActive(3)}
              className={
                active === 3
                  ? "md:w-10 w-5 h-5 md:h-10 w-5 h-5 rounded-full bg-slate-500	"
                  : "bg-slate-300	w-5 h-5 md:w-10 md:h-10 rounded-full"
              }
            ></button>
          </div>
          <button
            onClick={downloadImage}
            className="bg-blue text-white p-2 rounded-lg md:w-96"
          >
            Create Card
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cards;
