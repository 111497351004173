import {
  GET_USERPROFILE_FAILURE,
  GET_USERPROFILE_REQUEST,
  GET_USERPROFILE_SUCCESS,
  GET_USERPROFILE_RESET,
} from "../../../actionTypes/dashboard/userProfile";

export const getProfileRequest = () => {
  return {
    type: GET_USERPROFILE_REQUEST,
  };
};

export const getProfileSuccess = (data) => {
  return {
    type: GET_USERPROFILE_SUCCESS,
    payload: data
  };
};

export const getProfileFailure = (error) => {
  return {
    type: GET_USERPROFILE_FAILURE,
    payload: error
  };
};

export const getProfileReset = () => {
  return {
    type: GET_USERPROFILE_RESET,
    payload: null
  };
};
