import React, { useEffect } from "react";
import Accounting from "../../../assets/images/Invoice.png";
import { SignBtn } from ".";
import { DivContainer } from ".";
import { ContentContainer } from ".";
import { Col } from ".";
import AOS from "aos";
import "aos/dist/aos.css";
import Fade from "react-reveal/Fade";
import { Icon } from "@iconify/react";
import SubFooter from "./subFooter";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
    
      <ContentContainer className="bg-white" id="content2">
        <div className="text-center space-y-3">
          <h1
            className="lg:px-44 text-4xl text-black "
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <span className="text-blue text-4xl ">Send invoices</span> and get
            paid 10x faster
          </h1>
          <p className=" lg:px-[380px] text-black" data-aos="zoom-out-right">
            Get paid faster with automated invoicing and payment reminders.
            Accept credit cards, debit cards, and bank transfers.
          </p>
        </div>
        <div className="bg-BACKGROUND_GRAY mt-4">
          <div
            className="flex justify-center"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <img className="lg:w-[600px]" src={Accounting} alt="accounting" />
          </div>
          {/* <Col data-aos="flip-up">
            <Fade right>
              <div className="lg:w-full" data-aos="zoom-out-right">
                <a href="/#" className="flex space-x-5 p-3 pl-3 ">
                  <Icon
                    className="text-4xl text-blue w-10 self-center"
                    icon="fa6-solid:file-invoice-dollar"
                  />
                  <div>
                    <h1>Generate invoices quickly wih our invoice generator</h1>{" "}
                    <p className="text-base text-black">
                      Every invoice paid equals more revenue for your business.
                    </p>
                  </div>
                </a>
              </div>

              <div className="lg:w-full" data-aos="zoom-out-right">
                <a href="/#" className="flex space-x-5 p-3 pl-3 ">
                  <Icon
                    icon="mdi:recurring-payment"
                    className="text-7xl md:text-5xl text-blue self-center"
                  />
                  <div>
                    <h1>
                      Recurring billing for repeat customers and subscriptions
                    </h1>{" "}
                    <p class="text-base text-black">
                      Get paid on time, every time. Set up recurring invoices
                      and automatic payments for returning customers.
                    </p>
                  </div>
                </a>
              </div>
              <div className="flex justify-center md:justify-start">
                <SignBtn
                  className="bg-BACKGROUND_BLUE text-white"
                  data-aos="fade-left"
                >
                  <a href="/#" className="animate-pulse">
                    Get Started
                  </a>
                </SignBtn>
              </div>
            </Fade>
          </Col> */}
        </div>
      </ContentContainer>
      <div className="bg-white py-2">
        <SubFooter />
      </div>
    </>
  );
};

export default Hero;
