import React,{useState} from "react";
import { Icon } from "@iconify/react";
import { Spinner } from "@blueprintjs/core";
import Swal from "sweetalert2";
import axios from "axios";

function AddItem({ handleClose, addItem }) {
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [message,setMessage] =useState();
  const [error,setError] =useState();
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  
  const baseUrl = `https://api.logikeep.africa/api/v1/createinventory`;

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      product: formData?.product,
      numbers_of_stocks_available: formData?.numbers_of_stocks_available,
      unit: formData?.unit,
      
    };
    setIsLoading(true);
  
    axios
      .post(baseUrl,data,config)
      .then((response) => {
        const data = response.data.data;
     
         setMessage(response.data.message)
         setIsLoading(false);
          //sweet alert
        Swal.fire({
          icon: "success",
          title: "Item Added Successfully",
          text: response.data.message,
          button: "Ok!",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
       
        if (error.response) {
          setError(error.response.data.message)
        }
        else{
          setError(error.message)
        }
        setIsLoading(false);
      });
  }
  return (
    <form
      className="flex flex-col w-96 bg-white p-5 rounded-lg space-y-3"
      onSubmit={handleSubmit}
    >
      <div
        onClick={handleClose}
        className="text-red-600 self-end cursor-pointer"
      >
        <Icon icon="fa:close" />
      </div>

      <div className="">
        <h1 className="text-2xl font-bold">Add New Item</h1>
      </div>

      <div>
        <label htmlFor="item">Item Name</label>
        <input
          className="border-b-2"
          type="text"
          name="product"
          id="item"
          placeholder="e.g Rolex Watch"
          onChange={handleChange}
          value={addItem.name}
          required
        />
      </div>
      <div>
        <label htmlFor="stock">No of Stock</label>
        <input
          className="border-b-2"
          type="number"
          name="numbers_of_stocks_available"
          id="stock"
          placeholder="e.g 10"
          required
          onChange={handleChange}
          value={addItem.name}
        />
      </div>
      <div>
        <label htmlFor="unit">Unit</label>
        <select
          className="w-full border-b-2 focus:outline-none"
          onChange={handleChange}
          value={addItem.name}
          name="unit"
          id="unit"
        >
          <option>Select Unit</option>
          <option value="pack">Pack</option>
          <option value="pair">Pair</option>
          <option value="Box">Box</option>
          <option value="Pcs">Pcs</option>
          <option value="Bag">Bag</option>
          <option value="Bowl">Bowl</option>
          <option value="Portion">Portion</option>
          <option value="Kg">Kg</option>
          <option value="Yard">Yard</option>
          <option value="Cm">Cm</option>
          <option value="Litres">Litres</option>
          <option value="Carton">Carton</option>
          <option value="Bottle">Bottle</option>
          <option value="Sachets">Sachets</option>
          <option value="Bundle">Bundle</option>
        </select>
      </div>

      <div className="py-5">
        <button
          className="bg-blue text-white p-2 rounded-md w-full"
          type="submit"
          disabled={isLoading}
        >
            {isLoading ? (
                    <Spinner intent="warning" size={20} />
                  ) : (
                    "Add Item"
                  )}
        </button>
      </div>
    </form>
  );
}

export default AddItem;
