import React, { useEffect, useState } from "react";
import FullPayment from "./FullPayment";
import PartPayment from "./PartPayment";
import NotPaid from "./NotPaid";
import { useGlobalContext } from "../../context";



function History() {
  const { setCurrentPage } = useGlobalContext();
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const [isActive, setIsActive] = useState("fullPayment");

  const handleFullPayment = () => {
    setCurrentPage(1);
    setIsActive("fullPayment");
  };

  const handlePartPayment = () => {
    setCurrentPage(1);
    setIsActive("partPayment");
  };

  const handleNotPaid = () => {
    setCurrentPage(1);
    setIsActive("notPaid");
  };


  return (
    <div className='history pt-4'>
      <div className='history__header pb-5'>
        <div className='history__header__buttons flex gap-2'>
          <button
            className={
              isActive === "fullPayment"
                ? "active rounded-t-lg bg-blue py-1 pl-5 px-8  border text-white text-center"
                : "text-slate-300 text-center  py-1 pl-5 px-8 border-[1px]  rounded-t-lg"
            }
            onClick={handleFullPayment}
          >
            <p>Full Payment</p>
          </button>
          <button
            className={
              isActive === "partPayment"
                ? "active rounded-t-lg bg-blue text-white  py-1 pl-5 px-8 border text-center"
                : "text-slate-300   py-1 pl-5 px-8 border-[1px]  rounded-t-lg text-center"
            }
            onClick={handlePartPayment}
          >
            <p>Part Payment</p>
          </button>
          <button
            className={
              isActive === "notPaid"
                ? "active rounded-t-lg bg-blue text-white  py-1 pl-5 px-8 border text-center"
                : "text-slate-300 py-1 pl-5 px-8 border-[1px]  rounded-t-lg text-center"
            }
            onClick={handleNotPaid}
          >
            <p>Not Paid</p>
          </button>
        </div>{" "}
        <div className='max-w-2xl border-b-4 border-slate-300'></div>
      </div>
      <div className='history__content text-black'>
        {isActive === "fullPayment" && (
          <FullPayment search={search} handleSearch={handleSearch} />
        )}
        {isActive === "partPayment" && (
          <PartPayment search={search} handleSearch={handleSearch} />
        )}
        {isActive === "notPaid" && (
          <NotPaid search={search} handleSearch={handleSearch} />
        )}
      </div>
    </div>
  );
}

export default History;
