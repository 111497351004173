import React, { useState } from "react";
import "../../styles/screen.css";
import {
  SubmitButton,
  Button1,
  Container,
  FormContainer,
  OverlayContainer,
  Main,
} from "../../styles/styledElements";
import Logo from "../../pages/home/Logo";
import Question from "../../assets/images/question.png";
import styled, { keyframes } from "styled-components";
import { merge, fadeIn, bounce } from "react-animations";
import {
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordReset,
  resetPasswordSuccess,
} from "../../redux/actions/auth/changePassword/resetPassword";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../..//services/api";
import { appUrls } from "../../services/urls";
import { useParams } from "react-router-dom";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";

const bounceFadeIn = merge(bounce, fadeIn);

const bounceAnimation = keyframes`${bounceFadeIn}`;
const BouncyDiv = styled.div`
  animation: 10s infinite ${bounceAnimation};
`;
const passwordRevealIcon = {
  isOpen: false,
  isType: true,
};

function ResetPassword() {
  const [formData, setFormData] = useState();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Reset Token (QueryString)
  const { token } = useParams();

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();

    // password validation special characters and length and number validation
    if (
      !formData?.password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,32}$/
      )
    ) {
      setMessage(
        "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number"
      );
      setTimeout(() => {
        setMessage("");
      }, 3000);
      return;
    }
    // check if password and confirm password match
    if (formData?.password !== formData?.confirm_password) {
      setMessage("Password and Confirm Password do not match");
      setTimeout(() => {
        setMessage("");
      }, 3000);
      return;
    }

    const data = {
      code: formData?.code,
      // resetToken: token,
      password: formData?.password,
      confirm_password: formData?.confirm_password,
    };

    // call update api with filed
    dispatch(resetPasswordRequest());
    setIsLoading(true);
    await api
      .post([appUrls.resetPasswordUrl, data])
      .then((response) => {
        dispatch(resetPasswordSuccess(response));
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          // title: "Kindly Check Your Mailbox",
          text: response?.message,
          button: "Ok!",
        });
        setTimeout(function () {
          // window.location.reload();
          window.location.href = "/login";
        }, 2000);
      })
      .catch((err) => {
        dispatch(resetPasswordFailure(err?.data));
        setIsLoading(false);
        setTimeout(() => {
          dispatch(resetPasswordReset());
        }, 1000);
        setMessage(err?.data?.message);
      });
  };

  const [passwordReveal, setPasswordReveal] = useState(passwordRevealIcon);
  const revealPass = passwordReveal.isOpen ? "el:eye-open" : "el:eye-close";

  const inputType = passwordReveal.isType ? "password" : "text";

  const handlePasswordReveal = () => {
    setPasswordReveal({
      ...passwordReveal,
      isOpen: !passwordReveal.isOpen,
      isType: !passwordReveal.isType,
    });
  };
  // handle reveal password 2
  const [passwordReveal2, setPasswordReveal2] = useState(passwordRevealIcon);
  const revealPass2 = passwordReveal2.isOpen ? "el:eye-open" : "el:eye-close";

  const inputType2 = passwordReveal2.isType ? "password" : "text";

  const handlePasswordReveal2 = () => {
    setPasswordReveal2({
      ...passwordReveal2,
      isOpen: !passwordReveal2.isOpen,
      isType: !passwordReveal2.isType,
    });
  };

  return (
    <>
      <Main className="p-5 lg:p-0 lg:h-screen">
        <Container className="lg:h-full w-full lg:rounded-none rounded-lg grid lg:grid-cols-2 bg-white lg:bg-slate-200">
          <FormContainer id="sign-in-container">
            <div className="pb-5 flex justify-between">
              <Logo />
            </div>
            <div className="lg:hidden bg-[#c7d3f3]" id="overlay">
              <BouncyDiv>
                <img src={Question} id="imgBxForgot" alt="imgBxForgot" />
              </BouncyDiv>
            </div>

            <div className="lg:px-20 space-y-5 self-center">
              <h3 className="text-blue text-lg font-semibold">
                Reset Password ?
              </h3>
              <p class="text-black">Please type in your new password.</p>

              <form action="#" className="">
                {/* <Logo /> */}
                <input
                  type="text"
                  placeholder="Enter the otp"
                  className="border-b-2 border-blue outline-none text-sm bg-transparent"
                  onChange={handleFormDataChange}
                  name="code"
                />

                <div className="relative w-full">
                  <input
                    type={inputType}
                    placeholder="Enter your new password"
                    className="border-b-2 border-blue outline-none text-sm bg-transparent"
                    onChange={handleFormDataChange}
                    name="password"
                  />
                  <Icon
                    className="absolute right-0 top-0 mt-4 mr-4 cursor-pointer"
                    icon={revealPass}
                    onClick={handlePasswordReveal}
                  />
                </div>
                <div className="relative w-full">
                  <input
                    type={inputType2}
                    placeholder="Confirm password"
                    className="border-b-2 border-blue outline-none text-sm bg-transparent"
                    onChange={handleFormDataChange}
                    name="confirm_password"
                  />
                  <Icon
                    className="absolute right-0 top-0 mt-4 mr-4 cursor-pointer"
                    icon={revealPass2}
                    onClick={handlePasswordReveal2}
                  />
                </div>

                {message && (
                  <div className="text-red-600 w-62 text-center mt-4 text-sm">
                    {message}
                  </div>
                )}
                <button
                  className="submitButton relative"
                  type="submit"
                  onClick={handleResetPasswordSubmit}
                  id="btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent="warning" size={20} />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </form>
            </div>
          </FormContainer>
          <OverlayContainer className="hidden lg:flex justify-center">
            <BouncyDiv>
              <img src={Question} id="imgBxForgot" alt="imgBxForgot" />
            </BouncyDiv>
          </OverlayContainer>
        </Container>
      </Main>
    </>
  );
}

export default ResetPassword;
