import React, { useContext, useEffect, useState } from "react";
import "../../../assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "../../../scss/App.scss";
import { Link } from "react-router-dom";
import {
  getProfileRequest,
  getProfileSuccess,
  getProfileReset,
  getProfileFailure,
} from "../../../redux/actions/dashboard/userProfile";
import { api } from "../../../services/api";
import { appUrls } from "../../../services/urls";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import LoadingSpin from "react-loading-spin";
import Polygon from "../../../assets/images/Polygon1.png";
import Ellipse1 from "../../../assets/images/Ellipse2.png";
import "aos/dist/aos.css";
import { flip } from "react-animations";
import styled, { keyframes } from "styled-components";
const flipper = keyframes`${flip}`;
const FlipDiv = styled.div`
  animation: 10s infinite ${flipper};
`;

const Profile = () => {
  const [userProfile, setUserProfile] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const dispatch = useDispatch();

  // bearerToken
  const token = localStorage.getItem("BearerToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const baseUrl = `https://api.logikeep.africa/api/v1/getuserprofile`;
  const imageUrl = `https://api.logikeep.africa/`;

  // get profile api call
  const fetchProfile = () => {
    dispatch(getProfileRequest());
    axios
      .get(baseUrl, config)
      .then((response) => {
        const data = response.data.data;
        dispatch(getProfileSuccess(data));
        setUserProfile(data);
        setIsShow(false);
      })
      .catch((err) => {
        dispatch(getProfileFailure(err?.data));
        setIsShow(false);
        setTimeout(() => {
          dispatch(getProfileReset());
        }, 5000);
      
      });
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <>
      <div className="w-3 md:w-6 lg:w-12 absolute -top-10 md:-top-10 lg:top-80 left-10">
        <FlipDiv>
          <img className="animate-pulse" src={Polygon} alt="polygon" />
        </FlipDiv>
      </div>

      <h3 className="text-blue p-6 text-xl font-semibold flex justify-center">
        Business Information
      </h3>

      <div>
        {/* form */}
        {isShow ? (
          <div className="flex justify-center">
            <div className={"ExampleOfUsage"}>
              <LoadingSpin />
            </div>
          </div>
        ) : (
          <div>
            {/* <h3 className="text-blue p-6 text-xl font-semibold flex justify-center">
            Dear {username}, let’s know more about your business
          </h3> */}

            <div className="flex justify-center" id="profilebg">
              <div class="w-full max-w-3xl p-6 ">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      First Name
                    </label>
                    <h2 className="font-semibold"> {userProfile.firstname}</h2>
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-last-name"
                    >
                      Last Name
                    </label>
                    <h2 className="font-semibold">{userProfile.lastname}</h2>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Phone Number
                    </label>
                    <h2 className="font-semibold">{userProfile.phonenumber}</h2>
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Email Address
                    </label>
                    <h2 className="font-semibold">{userProfile.email}</h2>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Business Name
                    </label>
                    <h2 className="font-semibold">{userProfile.business_name}</h2>
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Business Phone Number
                    </label>
                    <h2 className="font-semibold">{userProfile.phonenumber}</h2>
                  </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Business Logo
                    </label>
                    <img src= {imageUrl+ userProfile.business_logo} alt="business-logo" className="w-32"/>
                    {/* <h2 className="font-semibold">{userProfile.business_logo}</h2> */}
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Business Document (CAC)
                    </label>
                    
                    <iframe  src={imageUrl+ userProfile.business_doc} alt="business_doc" className=""></iframe>
                  </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full  px-3">
                    <label
                      class="block capitalize tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Business Address
                    </label>
                    <h2 className="font-semibold">{userProfile.business_address}</h2>
                  </div>
                </div>

                <div className="flex justify-end py-2 px-4 ">
                  <div className="bg-blue rounded-lg py-2 px-4 ml-2 text-white">
                    <div className="text-center  ">
                      <Link
                        to="/dashboard/business-info"
                        className="hover:text-white hover:no-underline"
                      >
                        {" "}
                        Edit Business Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="w-12 lg:w-20 right-20 md:right-64 lg:right-32 absolute -top-10 md:-top-10 lg:top-80 animate-pulse drop-shadow-2xl">
        <img className="animate-pulse" src={Ellipse1} alt="ellipse1" />
      </div>
    </>
  );
};

export default Profile;
