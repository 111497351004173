import {
   GETBUSINESS_FAILURE,
   GETBUSINESS_REQUEST,
   GETBUSINESS_SUCCESS,
   GETBUSINESS_RESET,
  } from "../../../actionTypes/dashboard/businessInfo/getBusinessInfo";
  
  export const getBusinessRequest = () => {
    return {
      type:GETBUSINESS_REQUEST,
    };
  };
  
  export const getBusinessSuccess = (data) => {
    return {
      type: GETBUSINESS_SUCCESS,
      payload: data
    };
  };
  
  export const getBusinessFailure = (error) => {
    return {
      type :GETBUSINESS_FAILURE,
      payload: error
    };
  };
  
  export const getBusinessReset = () => {
    return {
      type: GETBUSINESS_RESET,
      payload: null
    };
  };
  
