import {
    INVOICE_FAILURE,
    INVOICE_REQUEST,
    INVOICE_SUCCESS,
    INVOICE_RESET,
    } from "../../../actionTypes/dashboard/invoice/index";
    
    export const invoiceRequest = () => {
      return {
        type:INVOICE_REQUEST,
      };
    };
    
    export const invoiceSuccess = (data) => {
      return {
        type:INVOICE_SUCCESS,
        payload: data
      };
    };
    
    export const invoiceFailure = (error) => {
      return {
        type :INVOICE_FAILURE,
        payload: error
      };
    };
    
    export const invoiceReset = () => {
      return {
        type:INVOICE_RESET,
        payload: null
      };
    };
    
  