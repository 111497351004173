import React, { useRef } from "react";
import SearchBox from "../../components/SearchBox";
import DateInput from "../../components/DateSelector";
import { Icon } from "@iconify/react";
import Grid from "../../components/history/grid";
import { useGlobalContext } from "../../context";
import { CSVLink } from "react-csv";
import Pagination from "../../components/pagination/Pagination";
import { useSelector } from "react-redux";
import ReactToPdf from "react-to-pdf";

const FullPayment = ({ search, handleSearch }) => {
  const {
    fullHistoryData,
    currency,
    dataInCSV,
    csvDownloadRef,
    column,
    headers,
    getCSV,
    currentPage,
    handlePageChange,
    fulllHistoryResponse,
    userProfile,
    setIsReceiptShown,
    isReceiptShown,
    receitDetails,
  } = useGlobalContext();

  // console.log(userProfile);
  // const getCSV = () => {
  //   setTimeout(() => {
  //     csvDownloadRef.current.link.click();
  //   }, 500);
  // };

  const imageUrl = `https://api.logikeep.africa`;
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  const ref = React.createRef();
  const PDFHandler = (toPdf) => {
    console.log(ref);
    toPdf();
  };
  return (
    <div>
      <div className='flex justify-between pb-5 space-x-2'>
        <SearchBox
          placeholder={"Search customer"}
          value={search}
          onChange={handleSearch}
        />
        <div className='flex space-x-2 md:space-x-5'>
          <div className=' self-center'>
            <button
              className='bg-white shadow-lg rounded-lg p-3 flex md:space-x-5 self-center'
              onClick={getCSV}
            >
              <Icon className='self-center' icon='el:download-alt' />
              <span className='text-xs m-0 w-16'>Export CSV</span>
            </button>
            {dataInCSV != null ? (
              <CSVLink
                headers={headers}
                data={dataInCSV}
                filename='Full Payment Logikeep Account Statement.csv'
                className='hidden'
                ref={csvDownloadRef}
                target='_blank'
              />
            ) : null}
          </div>
          <div className='w-32 self-center'>
            <DateInput />
          </div>
        </div>
      </div>
      <div className='overflow-scroll'>
        <Grid
          full
          items={fullHistoryData?.filter((item) =>
            item.customer.customer_name
              .toLowerCase()
              .includes(search.toLowerCase())
          )}
          code={currency}
        />
      </div>

      <div
        className={`${
          isReceiptShown ? "scale-100" : "scale-0"
        } fixed top-0 z-10 w-full h-screen right-0 bg-black bg-opacity-80 flex flex-col items-center justify-center transition`}
      >
        <div
          ref={ref}
          className='bg-white p-8 rounded-sm w-full max-w-2xl h-full overflowY'
        >
          <div className='flex items-center gap-8 border-b py-4 border-b-slate-300'>
            <div className='bg-white rounded-full shadow-md w-52 h-52 flex flex-col items-center justify-center border-2 border-slate-300'>
              <img
                src={imageUrl + userProfile?.business_logo}
                className='w-24'
                alt='logo'
              />
              <h2 className='text-sm uppercase font-bold text-center'>
                {userProfile?.business_name}
              </h2>
            </div>
            <div className='text-center'>
              <h2 className='text-2xl font-bold'>Payment Received!</h2>
              <p>Issue No: LOG{Math.floor(Math.random() * 1000)}</p>
            </div>
          </div>
          <div className='flex flex-col gap-3 border-b py-4 border-b-slate-300'>
            <h2 className='capitalize'>
              Issued To: {receitDetails?.customer?.customer_name}
            </h2>
            <h2>Email: {receitDetails?.customer?.email || "Null"}</h2>
            <h2>Contact: {receitDetails?.customer?.phone_number}</h2>
          </div>
          <div className='flex flex-col gap-3 border-b py-4 border-b-slate-300'>
            <h2>Description: {receitDetails?.inflow_details}</h2>
            <h2>
              Price: {dashboardDetails?.currencysymbol}{new Intl.NumberFormat().format(receitDetails?.total_amount_expected)}
                 

            </h2>
            {/* <h2>Quantity:</h2>
            <h2>Total:</h2> */}
          </div>
          <h2 className='text-blue text-xl my-4 text-center font-bold'>
            Thank you for your patronage!
          </h2>
          <div className='flex justify-between gap-4 items-center'>
            <ReactToPdf targetRef={ref} filename='Logikeep_receipt.pdf'>
              {({ toPdf }) => (
                <button
                  className='rounded-md p-2 whitespace-nowrap bg-blue text-white text-sm my-5'
                  onClick={() => PDFHandler(toPdf)}
                >
                  {" "}
                  Download
                </button>
              )}
            </ReactToPdf>

            <h2
              onClick={() => setIsReceiptShown(false)}
              className='text-red-600 text-sm italic cursor-pointer select-none'
            >
              Close
            </h2>
          </div>
        </div>
      </div>

      <div className='flex justify-end my-10'>
        <Pagination
          data={fulllHistoryResponse}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default FullPayment;
