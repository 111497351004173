import React, { useState, useEffect, useMemo } from "react";
import { Icon } from "@iconify/react";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import {
  incomeRequest,
  incomeFailure,
  incomeSuccess,
  incomeReset,
} from "../../redux/actions/dashboard/incomes";
import axios from "axios";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../context";
import classnames from "classnames";

import SelectBox from "../../components/pagination/SelectBox";

const Income = ({ handlePayment, isPayment, handleCustomer, isCustomer }) => {
  const initialState = {
    isValue: true,
    isClass: false,
  };

  const [customer, setCustomer] = useState(initialState);
  // const [incomes, setIncomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [error, setError] = useState();
  const [payment, setPayment] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [existing, setExisting] = useState();
  const [existingCustomer, setexistingCustomer] = useState([]);
  const dispatch = useDispatch();
  // bearerToken
  const token = localStorage.getItem("BearerToken");
  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);
  // }) {
  //   headers: { Authorization: `Bearer ${token}` },
  // };
  const baseUrl = `https://api.logikeep.africa/api/v1/createinflow`;

  //check existing customer
  useEffect(() => {
    const getExistingCustomerData = async () => {
      await fetch(
        "https://api.logikeep.africa/api/v1/get-business-customer",
        config
      )
        .then((response) => response.json())
        .then((data) => {
          const existingCustomer = data.data.map((existing) => ({
            name: existing.customer_name,
            value: existing.customer_name,
            existing_id: existing.id,
          }));
          setexistingCustomer(existingCustomer);
        });
    };
    getExistingCustomerData();
  }, [config]);

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    // event.preventDefault();
  };

  const handleIncomeSubmit = async (e) => {
    e.preventDefault();

    const data = {
      inflow_details: formData?.inflow_details,
      amount_expected: formData?.amount_expected,
      amount_received: formData?.amount_received,
      payment_type: payment,
      payment_status: paymentStatus,
      date_to_balance: formData?.date_to_balance,
      customer_type: customer,
      customer_name: formData?.customer_name,
      customer_phone: formData?.customer_phone,
      customer_email: formData?.customer_email,
      customer_id: existing?.existing_id || "",
    };

    dispatch(incomeRequest());
    setIsLoading(true);
    axios
      .post(baseUrl, data, config)
      .then((res) => {
        dispatch(incomeSuccess(res));
        setIsLoading(false);

        //sweet alert
        Swal.fire({
          icon: "success",
          title: "Income Updated",
          text: res?.data?.message,
          button: "Ok!",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        dispatch(incomeFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(incomeReset());
        }, 3000);
      });
  };
  const { theme } = useGlobalContext();
  const classes = classnames({
    "income-content rounded-lg shadow-md flex flex-col space-y-10 p-5 md:py-16 text-left transition duration-500 ease-in-out p-5 bg-slate-100":
      theme === "light",
    "income-content transition duration-500 ease-in-out flex flex-col space-y-10 shadow-md p-5 md:py-16 text-left  rounded-lg bg-opacity-80 bg-white":
      theme === "dark",
  });

  const handleOptionChange = (option) => {
    console.log(option);
    setExisting(option);
  };
  return (
    <>
      <div>
        <form
          className="flex flex-col md:grid grid-cols-2 gap-5 md:gap-28 md:pb-20 text-center md:px-32 text-black"
          onSubmit={handleIncomeSubmit}
        >
          <div className="h-full">
            <div className="flex flex-col space-y-1 pb-3">
              <div className="border-2 border-blue self-center rounded-full p-2">
                <Icon className="text-xl text-blue" icon="bxs:user" />
              </div>

              <div
                id="income"
                className={theme === "light" ? "text-black" : "text-white"}
              >
                Customer's Details
              </div>
            </div>
            <div className={classes}>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="category">
                  Customer
                  <span className="text-red-400 absolute -top-1 left-[72px]">
                    *
                  </span>
                </label>
                <select
                  className="p-2"
                  name="customer_type"
                  id="customer_type"
                  onChange={(e) => {
                    handleCustomer(e);
                    setCustomer(e.target.value);
                  }}
                  required
                  value={customer}
                >
                  <option>Select Customer Type</option>
                  <option value="new" id="new">
                    New Customer
                  </option>
                  <option value="existing" id="existing">
                    Existing Customer
                  </option>
                </select>
              </div>

              <div
                className={
                  isCustomer.isExistingCustomer
                    ? "flex flex-col space-y-1"
                    : "hidden"
                }
              >
                {/* <h1>Existing Customer</h1>
                <select
                  className='p-2'
                  name='customer_id'
                  onChange={(e) => setExisting(e.target.value)}
                  value={existing}
                  required
                >
                  <option>Select Customer</option>
                  {existingCustomer.map((existing) => (
                    <option value={existing.existing_id} name={existing.name}>
                      {existing.name}
                    </option>
                  ))}
                </select> */}

                <SelectBox
                  label="Existing Customer"
                  options={existingCustomer}
                  handleOptionChange={handleOptionChange}
                  // func={() => setExisting(val)}
                  setExisting={setExisting}
                  value={existing}
                  required
                />
              </div>
              {isCustomer.isNewCustomer && (
                <div className="flex flex-col space-y-1">
                  {" "}
                  <h1>Add a New Customer</h1>
                  <label className="relative" htmlFor="date">
                    Name of Customer{" "}
                    <span className="text-red-400 absolute -top-1 left-[135px]">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    name="customer_name"
                    id="customer_name"
                    placeholder="E.g Kamarudeen Ishola"
                    onChange={handleFormDataChange}
                    required
                  />
                </div>
              )}
              <div
                className={
                  isCustomer.isNewCustomer
                    ? "flex flex-col space-y-1"
                    : "hidden"
                }
              >
                <label className="relative" htmlFor="note">
                  Phone Number{" "}
                  <span className="text-red-400 absolute -top-1 left-[107px]">
                    *
                  </span>
                </label>
                <input
                  type="number"
                  name="customer_phone"
                  id="customer_phone"
                  placeholder="E.g +234-000-00-000-00"
                  onChange={handleFormDataChange}
                />
              </div>
              <div
                className={
                  isCustomer.isNewCustomer
                    ? "flex flex-col space-y-1"
                    : "hidden"
                }
              >
                <label htmlFor="note">Email Address (Optional)</label>
                <input
                  type="email"
                  name="customer_email"
                  id="phone"
                  placeholder="E.g haruish@yahoo.com"
                  onChange={handleFormDataChange}
                />
              </div>
            </div>
          </div>
          <div className="h-full">
            <div className="flex flex-col space-y-1 pb-3">
              <div className="border-2 border-blue self-center rounded-full p-2">
                <Icon
                  className="text-xl text-blue"
                  icon="mingcute:shopping-bag-1-fill"
                />
              </div>

              <div
                id="income"
                className={theme === "light" ? "text-black" : "text-white"}
              >
                Transaction Details
              </div>
            </div>
            <div className={classes}>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="note">
                  Item Details{" "}
                  <span className="text-red-400 absolute -top-1 left-[86px]">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="inflow_details"
                  id="inflow_details"
                  placeholder="E.g Shoe"
                  onChange={handleFormDataChange}
                  required
                />
              </div>

              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="note">
                  Amount{" "}
                  <span className="text-red-400 absolute -top-1 left-[56px]">
                    *
                  </span>
                </label>
                <input
                  type="number"
                  name="amount_expected"
                  id="amount_expected"
                  placeholder="E.g 0.00"
                  onChange={handleFormDataChange}
                  required
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="category1">
                  Payment type{" "}
                  <span className="text-red-400 absolute -top-1 left-[98px]">
                    *
                  </span>
                </label>

                <select
                  className="p-2"
                  name="payment_type"
                  id="payment_type"
                  onChange={(e) => setPayment(e.target.value)}
                  value={payment}
                  required
                >
                  <option>Select Payment Type</option>
                  <option value="cash" id="cash">
                    Cash
                  </option>
                  <option value="bank_transfer" id="bank_transfer">
                    Bank transfer
                  </option>
                  <option value="pos" id="pos">
                    Pos
                  </option>
                </select>
              </div>
              <div className="flex flex-col space-y-1">
                <label className="relative" htmlFor="category">
                  Payment Status{" "}
                  <span className="text-red-400 absolute -top-1 left-[112px]">
                    *
                  </span>
                </label>
                <select
                  className="p-2"
                  name="payment-status"
                  id="payment-status"
                  required
                  value={paymentStatus}
                  // onChange={(e) => setPaymentStatus(e.target.value)}
                  // onChange={handlePayment}
                  onChange={(e) => {
                    handlePayment(e);
                    setPaymentStatus(e.target.value);
                  }}
                >
                  <option>Select Status</option>
                  <option value="paid" id="paid">
                    Full - payment
                  </option>
                  <option value="part_payment" id="part_payment">
                    Part - payment
                  </option>
                  <option value="not_paid" id="not_paid">
                    No - payment
                  </option>
                </select>
              </div>
              {isPayment.isNoPayment && (
                <div className="flex flex-col space-y-1">
                  <label className="relative" htmlFor="amount">
                    Amount paid{" "}
                    <span className="text-red-400 absolute -top-4 left-[93px]">
                      *
                    </span>
                  </label>
                  <input
                    type="number"
                    name="amount_received"
                    id="amount_received"
                    placeholder="#0.00"
                    onChange={handleFormDataChange}
                    required
                  />
                </div>
              )}

              {isPayment.isFullPayment && (
                <div className="flex flex-col space-y-1 ">
                  <label htmlFor="note">Date due for balance</label>
                  <input
                    type="date"
                    name="date_to_balance"
                    id="date_to_balance"
                    placeholder="19 Sept, 2022"
                    onChange={handleFormDataChange}
                    required
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-span-2">
            <button
              className="bg-blue text-white p-2 rounded-lg md:w-96 w-full"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <Spinner intent="warning" size={20} /> : "Save"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Income;
