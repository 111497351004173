import React, { useEffect } from "react";
import "../successPage/successPage.css";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Logo from "../../home/Logo";
import Confetti from "react-confetti";
import { useGlobalContext } from "../../../context";

import { useDispatch } from "react-redux";

const SuccessPage = () => {
  const dispatch = useDispatch();
  const { userProfile } = useGlobalContext();

  return (
    <div>
      <Confetti />
      <div className="modal">
        <div className="overlayModal"></div>

        <div className="modal-content flex flex-col space-y-3">
          <div className="self-center">
            <Logo />
          </div>
          <div className="details text-2xl text-center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="mr-3 text-blue"
              size="2x"
            />
          </div>
          <div>
            <p className="text-blue text-lg flex justify-center">
              <span className="font-black mr-1">
                Hi, {userProfile.firstname}
              </span>{" "}
              Welcome
            </p>
            <p className="text-blue text-lg flex justify-center -mt-4">
              to <span className="font-black ml-1">Logikeep</span>
            </p>
          </div>

          <p className="mt-6 text-center">
            We are committed to keeping your business records up to date!
          </p>

          <div className="flex justify-center">
            <Link to="/login">
              <button
                className="bg-BACKGROUND_BLUE text-white
                 py-2 px-6 rounded uppercase "
              >
                login to continue
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
