import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContentContainer } from ".";

const DivContainer = styled.div`
  ${tw`grid md:grid-cols-2 lg:grid-cols-2 gap-10`}
`;
const Col = styled.div`
  ${tw`w-full flex flex-col lg:pr-32`}
`;
const ColFull = styled.div`
  ${tw`lg:self-center lg:justify-self-center`}
`;
const Header = styled.h1`
  ${tw`text-4xl font-sans font-medium text-black `}
`;
const Paragraph = styled.p`
  ${tw`text-lg mt-4 text-black`}
`;
const SignBtn = styled.div`
  ${tw`text-sm rounded-lg w-28 p-1 border-2 border-blue
text-center justify-center mt-4`}
`;

export default function Community() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer className='bg-white relative '>
        <DivContainer id='contentContainer3'>
          <Col className='self-center'>
            <Header id='header3' data-aos='fade-left'>
              Join Our Commmunity
            </Header>
            <Paragraph id='paragraph' data-aos='fade-right'>
              If you want to go far, go together. Connect with our other users
              around the world by joining our 50000+ community.
            </Paragraph>
            <div
              className='flex justify-center md:justify-start'
              data-aos='zoom-in-right'
            >
              <SignBtn className='bg-BACKGROUND_BLUE '>
                <Link to=''>
                  <h1 className='text-white animate-pulse'>Get Started</h1>
                </Link>
              </SignBtn>
            </div>
          </Col>
          <ColFull className=''>
            <div className='lg:w-[450px]'>
              <img
                src='/assets/Community.svg'
                alt='img'
                data-aos='flip-left'
                data-aos-easing='ease-out-cubic'
                data-aos-duration='2000'
              />
            </div>
          </ColFull>
        </DivContainer>
      </ContentContainer>
    </>
  );
}
