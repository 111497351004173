import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
// import { Col } from ".";
// import { ContentContainer } from ".";

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col lg:justify-self-center`}
`;
const DivContainer = styled.div`
  ${tw`grid md:grid-cols-2 lg:grid-cols-4`}
`;
const ImgIcon = styled.img`
  ${tw`h-[155px]`};
`;
const Header = styled.h4`
  ${tw`text-base font-semibold text-center text-black`}
`;
const Paragraph = styled.p`
  ${tw`text-base text-center text-black`}
`;

export default function Review() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer className="bg-white">
        <h1
          className="text-4xl text-black text-center lg:px-80"
          id="header4"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          From The People Who Use Logikeep
          
        </h1>
        <DivContainer>
          <Col className="px-16" id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108054/Logikeep/Img1_jmscep.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              DANIEL
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              This is the best innovation I’ve come across in a long while. I
              have become more accountable as a result.
            </Paragraph>
          </Col>
          <Col className="px-16" id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108170/Logikeep/Img2_wcojvm.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              JOY
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              Logikeep has made it easy for me to keep inventory of my stocks
              and I’m super grateful for that.
            </Paragraph>
          </Col>
          <Col className="px-16" id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108066/Logikeep/Img4_wlrlgz.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              EKEOMA
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              I heard about Logikeep from my sister that owns a shop so I
              decided to try it out as well. I’m glad I did.
            </Paragraph>
          </Col>
          <Col className="px-16" id="colReviews">
            <ImgIcon
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              src="https://res.cloudinary.com/dysgwap7p/image/upload/v1659108067/Logikeep/Img3_thoh7u.svg"
              alt="img"
              id="imgReviews"
            />
            <Header
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              PETER
            </Header>
            <Paragraph
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              I just want to say thank you Logikeep
              <br /> for making me more aware of my transactions.
            </Paragraph>
          </Col>
        </DivContainer>
      </ContentContainer>
    </>
  );
}
