import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_RESET,
    RESET_PASSWORD_SUCCESS,
   } from "../../../actionTypes/auth/changePassword/resetPassword";
   
   const initialState = {
     isLoading: false,
     isError: false,
     data: [],
     error: null,
   };
   
   const resetPasswordReducer = (state = initialState, action) => {
     switch (action.type) {
       case RESET_PASSWORD_REQUEST:
         return { ...state, isLoading: true };
       case RESET_PASSWORD_SUCCESS:
         return {
           isLoading: false,
           error: null,
           data: action.payload,
           isError: false,
         };
       case RESET_PASSWORD_FAILURE:
         return {
           data: [],
           isLoading: false,
           error: action.payload,
           isError: true,
         };
       case RESET_PASSWORD_RESET:
         return {
           isLoading: false,
           isError: false,
           data: [],
           error: null,
         };
       default:
         return state;
     }
   };
   
   export default resetPasswordReducer;
   