import {
  GET_USERPROFILE_FAILURE,
  GET_USERPROFILE_SUCCESS,
  GET_USERPROFILE_REQUEST,
  GET_USERPROFILE_RESET,
} from "../../../actionTypes/dashboard/userProfile";

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  error: null,
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERPROFILE_REQUEST:
      return { ...state, isLoading: true };
    case GET_USERPROFILE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        data: action.payload,
        isError: false,
      };
    case GET_USERPROFILE_FAILURE:
      return {
        data: [],
        isLoading: false,
        error: action.payload,
        isError: true,
      };
    case GET_USERPROFILE_RESET:
      return {
        isLoading: false,
        isError: false,
        data: [],
        error: null,
      };
    default:
      return state;
  }
};

export default userProfileReducer