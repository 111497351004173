import React, { useState } from "react";
import Income from "./Income";
import Expenses from "./Expenses";

const Transactions = () => {
  const [active, setActive] = useState(true);
  const [isPayment, setIsPayment] = useState({
    isFullPayment: false,
    isNoPayment: false,
  });
  const [isCustomer, setIsCustomer] = useState({
    isNewCustomer: false,
    isExistingCustomer: false,
  });

  const handlePayment = (e) => {
    let val = e.target.value;
    val === "paid"
      ? setIsPayment({ isFullPayment: false, isNoPayment: false })
      : val === "not_paid"
      ? setIsPayment({ isFullPayment: true, isNoPayment: false })
      : setIsPayment({ isFullPayment: true, isNoPayment: true });
  };

  const handleCustomer = (e) => {
    let val = e.target.value;
    val === "existing"
      ? setIsCustomer({ isExistingCustomer: true, isNewCustomer: false })
      : val === "new"
      ? setIsCustomer({ isExistingCustomer: false, isNewCustomer: true })
      : setIsCustomer({ isExistingCustomer: false, isNewCustomer: false });
  };

  const handleActive = (e) => {
    setActive(e);
  };

  return (
    <>
      <div className='pt-5 md:flex justify-end text-black'>
        <div className='flex bg-white rounded-full border-[1px] border-[rgba(202, 202, 202, 1)]'>
          <div className='w-full md:w-auto'>
            <button
              onClick={() => handleActive(true)}
              id='income'
              className={
                active
                  ? "bg-blue p-2 rounded-full w-full text-white"
                  : "rounded-full bg-white p-2 w-full text-gray-200"
              }
            >
              Record Income
            </button>
          </div>
          <div className='w-full md:w-auto'>
            <button
              onClick={() => handleActive(false)}
              id='expense'
              className={
                !active
                  ? "bg-blue p-2 rounded-full w-full text-white"
                  : "rounded-full bg-white p-2 w-full text-gray-200"
              }
            >
              Record Expenses
            </button>
          </div>
        </div>
      </div>

      <div className='pt-10'>
        {" "}
        {active ? (
          <Income
            handlePayment={handlePayment}
            isPayment={isPayment}
            handleCustomer={handleCustomer}
            isCustomer={isCustomer}
          />
        ) : (
          <Expenses />
        )}
      </div>
    </>
  );
};

export default Transactions;
