import React from "react";
import "./block.css";

const BlockLoader = () => {
  return (
    <>
      <div className="block-loader">
        <div className="block-loader__block"></div>
        <div className="block-loader__block"></div>
        <div className="block-loader__block"></div>
      </div>
    </>
  );
};

export default BlockLoader;
