const sidebarNav = [
  {
    id: 1,
    link: "/dashboard",
    section: "home",
    icon: <i className='bx bx-home-alt'></i>,
    text: "Home",
    plan: "free",
  },
  {
    id: 2,
    link: "transactions",
    section: "transactions",
    icon: <i className='bx bx-receipt'></i>,
    text: "Record",
    plan: "free",
  },
  {
    id: 11,
    link: "invoice",
    section: "invoice",
    icon: <i className='bx bx-book'></i>,
    text: "Invoice",
    plan: "free",
  },
  {
    id: 3,
    link: "inventory",
    section: "inventory",
    icon: <i className='bx bx-box'></i>,
    text: "Inventory",
    plan: "free",
  },
  {
    id: 4,
    link: "history",
    section: "stats",
    icon: <i className='bx bx-line-chart'></i>,
    text: "Transactions",
    plan: "free",
  },
  {
    id: 5,
    link: "statement",
    section: "products",
    icon: <i className='bx bx-cube'></i>,
    text: "Statement",
    plan: "free",
  },
  {
    id: 6,
    link: "profile",
    section: "customers",
    icon: <i className='bx bx-user'></i>,
    text: "Profile",
    plan: "free",
  },
  {
    id: 7,
    link: "Wallet",
    section: "customers",
    icon: <i className='bx bx-wallet'></i>,
    text: "Wallet",
    plan: "free",
  },
  {
    id: 8,
    link: "customers",
    section: "customers",
    icon: <i className='bx bx-group'></i>,
    text: "Customers",
    plan: "free",
  },
  {
    id: 9,
    link: "business-card",
    section: "customers",
    icon: <i className='bx bx-id-card'></i>,
    text: "Business Card",
    plan: "classic",
  },
  {
    id: 10,
    link: "redeem",
    section: "customers",
    icon: <i className='bx bx-money'></i>,
    text: "Convert Point",
    plan: "classic",
  },
  {
    id: 12,
    link: "brt-card",
    section: "customers",
    icon: <i className='bx bx-credit-card-alt'></i>,
    text: "BRT Card",
    plan: "free",
  },
];

export default sidebarNav;
