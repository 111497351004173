import React, { useEffect, useState } from "react";
import "../../../styles/responsiveness.css";
import {useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginFailure,
  loginRequest,
  loginReset,
  loginSuccess,
} from "../../../redux/actions/auth/authActions";
import { api } from "../../../services/api";
import Swal from "sweetalert2";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";



const Resendotp = () => {
    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const navigate = useNavigate();
    const param = useParams();

   const errorDiv = error ? (
      <small className='text-red-600 text-center w-52 mt-4'>{error}</small>
    ) : (
      ""
    );
   const baseUrl = `https://api.logikeep.africa/api/v1/resendotp`;
   const handleFormDataChange = (event) => {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    };
  
    const handleLoginSubmit = async (e) => {
      e.preventDefault();
      const data = {
        email: formData?.email,
       
      };
  
      dispatch(loginRequest());
      setIsLoading(true);
      await api
        .post([baseUrl, data])
        .then((res) => {
          dispatch(loginSuccess(res));
          
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Thank you",
            text: res.message,
            button: "Ok!",
          });
          navigate("/otp");
        })
  
        .catch((err) => {
          dispatch(loginFailure(err?.data));
          setError(err?.data?.message);
          setIsLoading(false);
          setTimeout(() => {
            dispatch(loginReset());
          }, 3000);
        });
    };
   
  

  return (
    <div>
       <div className="flex  flex-wrap h-full  g-6 text-gray-800 ">
        
         
        <div className="lg:flex lg:flex-wrap g-0 ">
          <div className="lg:w-6/12 px-4 md:px-0">
            <div className="md:p-12 md:mx-6">
              <div className="text-center">
              <img
              src="/assets/LogikeepLogo/LogikeepLogo.png"
              id="imgBxForgot"
              alt="img"
              className="h-8 w-15 "
            />
          
              <h2 className='mt-8 mb-8 text-center text-1xl font-normal text-black'>
          Resend Otp <br></br>
          <span>Kindly input your registered email.</span>
        </h2>
              </div>
              <form action='#' className='' onSubmit={handleLoginSubmit}>
             
              <input
                  type='email'
                  placeholder='Email Address'
                  className='shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73]'
                  onChange={handleFormDataChange}
                  name='email'
                  required
                  id='email'
                />
      

                {errorDiv}
                <button
                  className='submitButton '
                  type='submit'
                  id='btn'
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent='Verifying' size={20} />
                  ) : (
                    "VERIFY"
                  )}
                </button>
              </form>
            </div>
          </div>
          <div
            className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-gradient-to-r from-indigo-500  to-cyan-100  " id="overlay"
            
          >
            <div className="text-white px-4 py-6 md:p-12 md:mx-6 ">
            <img
              src="/assets/registerImage.svg"
              id="imgBxForgot"
              alt="img"
            />
             <h2
              className="text-lg text-black font-semibold ml-4 text-center"
              id="imgText"
            >
              Your Book-keeping Team So That You
              <br />
              Can Do What You Love.
            </h2>
            </div>
          </div>
        </div>
   
  
  </div>
    </div>
  )
}

export default Resendotp 
