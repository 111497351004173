import {
    EXPENSES_FAILURE,
    EXPENSES_REQUEST,
    EXPENSES_SUCCESS,
    EXPENSES_RESET,
   } from "../../../actionTypes/dashboard/expenses/index";
   
   export const expenseRequest = () => {
     return {
       type:EXPENSES_REQUEST,
     };
   };
   
   export const expenseSuccess = (data) => {
     return {
       type: EXPENSES_SUCCESS,
       payload: data
     };
   };
   
   export const expenseFailure = (error) => {
     return {
       type :EXPENSES_FAILURE,
       payload: error
     };
   };
   
   export const expenseReset = () => {
     return {
       type: EXPENSES_RESET,
       payload: null
     };
   };
   
 