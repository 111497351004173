import React from "react";
import Modal from "./Modal";

function Container({
  addItem,
  handleChange,
  handleClose,
  submit,
  handleSubmit,
  show,
  close,
  setClose,
  editItem,
  newItem,
  setNewItem,
  isModal,
  handleEditInputChange,
  currentItem,
  handleEditItem,
}) {
  return (
    <>
      <div
        className={
          show ? "absolute modal-transparent w-full h-full left-0 top-0" : ""
        }
      >
        {show ? (
          <Modal
            submit={submit}
            handleSubmit={handleSubmit}
            close={close}
            setClose={setClose}
            handleClose={handleClose}
            addItem={addItem}
            handleChange={handleChange}
            newItem={newItem}
            setNewItem={setNewItem}
            editItem={editItem}
            isModal={isModal}
            // handleEdit={handleEdit}
          />
        ) : null}
      </div>
    </>
  );
}

export default Container;
