import React from "react";

function Nav({ category, active, setActive , count }) {
  return (
    <div className='flex space-x-3'>
      {category.map((item) => {
        return (
          <div key={item.id} className='flex'>
            <div
              className={
                active === item.id
                  ? "flex space-x-3 bg-white rounded-t-2xl p-3 shadow-md cursor-pointer"
                  : "flex space-x-3 bg-indigo-200 rounded-t-md p-3 shadow-lg cursor-pointer"
              }
              onClick={() => setActive(item.id)}
            >
              <div className='self-center'>{item.name}</div>
              <div className='flex flex-col text-center'>
                <div className='bg-blue rounded-full p-1'>
                  <h1 className='w-12 text-white'>{count}</h1>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Nav;
