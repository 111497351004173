import {
    UPDATEBUSINESS_FAILURE,
    UPDATEBUSINESS_SUCCESS,
    UPDATEBUSINESS_REQUEST,
    UPDATEBUSINESS_RESET,
  } from "../../../actionTypes/dashboard/businessInfo/index";
  
  const initialState = {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  };
  
  const updateBusinessReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATEBUSINESS_REQUEST:
        return { ...state, isLoading: true };
      case UPDATEBUSINESS_SUCCESS:
        return {
          isLoading: false,
          isLoggedIn: true,
          error: null,
          // data: action.payload,
          user: action.payload,
          isError: false,
        };
      case UPDATEBUSINESS_FAILURE:
        return {
          data: [],
          isLoading: false,
          error: action.payload,
          isError: true,
        };
      case UPDATEBUSINESS_RESET:
        return {
          isLoading: false,
          isError: false,
          data: [],
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default updateBusinessReducer