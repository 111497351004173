import React, { useState, useRef } from "react";
import Avatar from "../../assets/images/avatar-image.png";

const Card3 = ({ active, userProfile, formData, domEl }) => {
  const [bgColor, setBgColor] = useState("blue");
  const [bgShadow, setShadow] = useState("160,36,36");

  //change background color on click
  const handleColor = (color, shadow) => {
    setBgColor(color);
    setShadow(shadow);
  };

  const imageUrl = `https://api.logikeep.africa`;
  return (
    <div>
      {active === 3 && (
        <div className="grid gap-5 relative">
          <div className="flex space-x-3 self-center">
            <button
              onClick={() =>
                handleColor("blue", "shadow-[-15px_1px_0px_0_rgb(1,24,232)]")
              }
              className="w-10 h-10 rounded-full bg-blue"
            ></button>
            <button
              onClick={() =>
                handleColor(
                  "cyan-400",
                  "shadow-[-15px_1px_0px_0_rgb(34,211,238)]"
                )
              }
              className="w-10 h-10 rounded-full bg-cyan-400"
            ></button>
            <button
              onClick={() => handleColor("black")}
              className="w-10 h-10 rounded-full bg-black"
            ></button>
            <button
              onClick={() =>
                handleColor(
                  "rose-300",
                  "shadow-[-15px_1px_0px_0_rgb(253,164,175)]"
                )
              }
              className="w-10 h-10 rounded-full bg-rose-300"
            ></button>
            <button
              onClick={() =>
                handleColor(
                  "emerald-500",
                  "shadow-[-15px_1px_0px_0_rgb(16,185,129)]"
                )
              }
              className="w-10 h-10 rounded-full bg-emerald-500	"
            ></button>
            <button
              onClick={() =>
                handleColor(
                  "amber-600",
                  "shadow-[-15px_1px_0px_0_rgb(217,119,6)]"
                )
              }
              className="w-10 h-10 rounded-full bg-amber-600"
            ></button>
            <button
              onClick={() =>
                handleColor(
                  "amber-400",
                  "shadow-[-15px_1px_0px_0_rgb(251,191,36)]"
                )
              }
              className="w-10 h-10 rounded-full bg-amber-400"
            ></button>
          </div>

          <div className="self-center rounded-lg w-[380px]">
            <div id="card3" ref={domEl}>
              <div className="relative">
                <div className="grid grid-cols-2">
                  <div className="rounded-lg flex justify-center absolute -z-10">
                    <div
                      className={`bg-${bgColor} rounded-lg h-52 w-72 md:w-72 flex justify-start`}
                    >
                      <div className="self-center ml-10  gap-20 flex flex-col">
                        <div className="w-24 mb-5">
                          {/* {userProfile.business_logo === "undefined" ? (
                            <img
                              src={Avatar}
                              className="ml-2 w-16"
                              alt="profile-pic"
                            />
                          ) : userProfile.business_logo === "NULL" ? (
                            <img
                              src={Avatar}
                              className="ml-2 w-16"
                              alt="profile-pic"
                            />
                          ) : (
                            <img
                              src={imageUrl + userProfile.business_logo}
                              className="ml-2 w-16"
                              alt="profile-pic"
                            />
                          )} */}

                          <div className="flex justify-center items-center flex-col text-white">
                            <div className="text-center">
                              <div className="font-bold uppercase max-w-[140px]">
                                {formData?.business}
                              </div>
                            </div>
                            <small className="relative flex mt-2 gap-1 right-2 ">
                              <i class="bx bxs-phone self-center"></i>
                              {userProfile.phonenumber}
                            </small>
                          </div>
                        </div>
                        <small className="text-xs h-5 mt-5 text-white max-w-5 w-5 relative ">
                          {formData?.tagline}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="arc h-52 absolute -top-[0.6px] left-[156px] z-10"></div>
                  <div
                    className={`w-[195px] rounded-full h-52 absolute -top-[0.6px] left-[166px] ${bgShadow} z-40`}
                  ></div>
                  <div className="bg-black h-52 rounded-l-full rounded-r-lg relative left-[170px] w-44 md:w-52 z-50">
                    <div className="text-white p-4 flex justify-end">
                      <div className="md:space-y-8">
                        <div className="mb-7">
                          <div className="text-center relative left-5 md:left-0">
                            <div className="">
                              <span className="capitalize">
                                {userProfile.firstname}{" "}
                              </span>
                              <span className="capitalize">
                                {userProfile.lastname}
                              </span>
                            </div>
                            <div className="font-bold uppercase">
                              {formData?.title}
                            </div>
                            <div className="flex justify-center">
                              {" "}
                              <div className="border-2 border-b-white w-32 md:mx-auto md:-bottom-2 absolute"></div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-5 h-10">
                          <div className="flex space-x-1">
                            <i className="bx bxs-envelope text-xs mt-1"></i>
                            <p className="text-xs">{userProfile.email}</p>
                          </div>
                          <div className="flex space-x-1">
                            <i class="bx bxs-location-plus text-xs mt-1"></i>
                            <p className="text-xs">
                              {userProfile.business_address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <button onClick={convertToImage}>Download</button> */}
          </div>
          {/* image url */}
          {/* <img src={imgco} alt="" /> */}
        </div>
      )}
    </div>
  );
};

export default Card3;
