import {
   EXPENSES_FAILURE,
   EXPENSES_SUCCESS,
   EXPENSES_REQUEST,
   EXPENSES_RESET,
  } from "../../../actionTypes/dashboard/expenses/index";
  
  const initialState = {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  };
  
  const expensesReducer = (state = initialState, action) => {
    switch (action.type) {
      case EXPENSES_REQUEST:
        return { ...state, isLoading: true };
      case EXPENSES_SUCCESS:
        return {
          isLoading: false,
          isLoggedIn: true,
          error: null,
          // data: action.payload,
          user: action.payload,
          isError: false,
        };
      case EXPENSES_FAILURE:
        return {
          data: [],
          isLoading: false,
          error: action.payload,
          isError: true,
        };
      case EXPENSES_RESET:
        return {
          isLoading: false,
          isError: false,
          data: [],
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default expensesReducer