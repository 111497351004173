import React from "react";
import "../../../assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import RevenueList from "../../../components/revenue-list/RevenueList";
import "../../../styles/overlap.css";
import NewTotal from "../../../components/totals/NewTotal";
import WeekMonth from "../../../components/weekMonth/WeekMonth";
import ProgressBar from "../../../components/curveProgressBar/ProgressBar";
import Overall from "../../../components/totals/Overall";
import CustomersOwing from "../../../components/totals/TotalCustomersOwning";
import Payment from "../../../components/payments/Payment";
import Agents from "../../../components/agents/Agents";
import Slider from "../../../components/swiper/Swiper";
import Premium from "../../../components/premium/Premium";
import ReferralCode from "../../../components/referral-code/ReferralCode";
// import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardDetails } from "../../../redux/actions/dashboard/dashboardActions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const token = localStorage.getItem("BearerToken");
  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
  };
  const url = `https://api.logikeep.africa/api/v1/getdashboarddetails`;
  const fetchDashboard = async () => {
    try {
      const res = await axios.get(url, config);
      dispatch(fetchDashboardDetails(res.data.data));
    } catch (error) {
      dispatch(fetchDashboardDetails(error.response.data.data));
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <div className="pt-3 text-black">
      <div className="flex flex-col lg:grid lg:grid-cols-3 w-full gap-3">
        <div className="md:grid grid-cols-1 lg:col-span-2">
          <NewTotal />
          <div className="mt-3">
            <div className="flex flex-col md:flex-row  gap-3 pt-3 md:pt-0">
              <div className="basis-1/2">
                <Premium />
              </div>
              <div className="flex flex-col gap-3 text-sm basis-full text-black">
                <div className="">
                  <Overall />
                </div>

                <div className='pt-3 md:pt-0 flex flex-col gap-12 md:gap-4 md:flex-row flex-nowrap md:flex md:flex-wrap lg:flex-nowrap justify-between'>
                  <div class=' lg:w-50 w-full  md:max-h-full '>
                    <CustomersOwing />
                  </div>
                
                  {/* <div class='lg:w-50 p-4  w-full md:max-h-full '>

               
                    <CustomersOwing />
                  </div> */}

                  <div className="basis-1/2 md:self-center gap-6 md:basis-full md:block">
                    <ReferralCode />
                  </div>
                </div>

                <div className="pt-3 md:pt-0 flex flex-col gap-12 md:gap-4 md:flex-row flex-nowrap md:flex md:flex-wrap lg:flex-nowrap justify-between">
                  <WeekMonth />
                  <div className="basis-1/2 md:self-center gap-6 md:basis-full md:block">
                    {/* <ReferralCode /> */}
                    <ProgressBar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:grid grid-row-flow pt-3 md:pt-0 gap-0">
          <Payment />
          <div className="mt-5 md:mt-0">
            {/* Ads */}
            <Slider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
