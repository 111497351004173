import React from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { IoNotificationsSharp } from "react-icons/io5";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";
import { useGlobalContext } from "../../context";
import Table from "../Table";
import Timestamp from "../Timestamp";
import sms from "../../assets/images/sms.png";
import { useSelector } from "react-redux";

const Grid = ({ items, part, full, code, editFunc, type }) => {
  const { sendReminder, sendSMS, isLoading, showReceipt } = useGlobalContext();
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);
  if (isLoading) {
    return <Spinner size={30} />;
  }

  return (
    <>
      <div className='min-w-[950px] grid p-2 bg-white'>
        <div
          className={`grid gap-4 ${
            part ? "grid-cols-7" : "grid-cols-6"
          } items-center p-4 font-bold px-8 bg-[#EFEFEF] text-sm`}
        >
          <h2>Date</h2>
          <h2>Name of Customer</h2>
          <h2>Description</h2>
          {full && <h2>Payment Type</h2>}
          {full && <h2>Amount</h2>}
          {part && <h2>Total Amount</h2>}
          {part && <h2>Amount Paid</h2>}
          {part && <h2>Balance Due</h2>}
          <h2>Action</h2>
        </div>

        {items.length < 1 ? (
          <h2 className='font-medium italic my-2 p-4 text-base'>
            No data to display
          </h2>
        ) : (
          items?.map(
            ({
              customer,
              amount_received,
              payment_type,
              total_amount_expected,
              created_at,
              balance_due,
              id,
              inflow_details,
            }) => {
              return (
                <div
                  key={id}
                  className={`grid gap-4 ${
                    part ? "grid-cols-7" : "grid-cols-6"
                  }  bg-white p-4 px-8 text-gray-400 items-center text-sm capitalize`}
                >
                  <Timestamp time={created_at.slice(0, 10)} />
                  <h2>{customer?.customer_name}</h2>
                  <h2 className='w-full'>{inflow_details}</h2>
                  {full && <h2>{payment_type}</h2>}
                  {full && (
                    <h2>
                     {dashboardDetails?.currencysymbol}
                     {new Intl.NumberFormat().format(amount_received)}
                    
                    </h2>
                  )}
                  {part && (
                    <h2>
                    {dashboardDetails?.currencysymbol}
                     {new Intl.NumberFormat().format(total_amount_expected)}
                     
                    </h2>
                  )}
                  {part && (
                    <h2>
                     {dashboardDetails?.currencysymbol}
                     {new Intl.NumberFormat().format(amount_received)}
                      </h2>
                  )}
                  {part && (
                    <h2>
                    {dashboardDetails?.currencysymbol}
                     {new Intl.NumberFormat().format(balance_due)}
                     
                    </h2>
                  )}
                  <div className='flex items-center'>
                    {full ? (
                      <button
                        onClick={() => showReceipt(id)}
                        className='p-3 bg-blue text-white rounded-md'
                      >
                        Send Reciept
                      </button>
                    ) : part ? (
                      <div className='flex gap-4'>
                        <i
                          className='text-xl bg-sky-200 w-10 p-2 flex items-center justify-center rounded-md'
                          onClick={() => editFunc(id, type)}
                        >
                          <AiOutlineEdit />
                        </i>

                        <i
                          className='text-xl bg-amber-200 w-10 p-2 text-amber-600 flex items-center justify-center rounded-md'
                          onClick={() => sendReminder(id)}
                        >
                          <IoNotificationsSharp />
                        </i>
                        <img
                          src={sms}
                          className='w-7 hover:scale-105 object-contain'
                          alt='sms'
                          onClick={() => sendSMS(id)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            }
          )
        )}
      </div>
    </>
  );
};

export default Grid;
