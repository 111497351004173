import React, { useRef, useState } from "react";
import { useGlobalContext } from "../../context";
import { useSelector } from "react-redux";
import ReactToPdf from "react-to-pdf";
import Swal from "sweetalert2";

const InvoicePreview = ({
  invoice,
  total,
  balance,
  items,
  PDFHandler,
  invoicePreviewRef,
  onDeleteItem,
}) => {
  // const ref = useRef();
  const { dashboardDetails } = useSelector((state) => state.toggleReducer);

// Create a function to handle item deletion confirmation
const handleDeleteConfirmation = (itemId) => {
   //sweet alert
   Swal.fire({
    title: "Delete Item",
    text: "Are you sure you want to delete this item?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      onDeleteItem(itemId);
      Swal.fire("Deleted!", "The item has been deleted.", "success");
    }
  });
 
};
  return (
    <div className="p-5" ref={invoicePreviewRef}>
      <div className="flex justify-between items-center">
        <h2>
          {" "}
          <span className="font-semibold">Date: </span> {invoice.date}
        </h2>
        <h2 className="text-sm uppercase font-semibold text-center">
          Invoice #{invoice.invoiceNumber}
        </h2>
      </div>

      <div className="invoice-details">
        <div>
          <h2 className="text-sm  font-semibold mt-2">
            Bill to:
            <span className="text-sm capitalize font-medium">
              {" "}
              {invoice.customer_name}
            </span>
          </h2>
        </div>
      </div>

      <div className="invoice-items py-4">
        <h3 className="text-sm uppercase font-bold">Invoice Items</h3>
        <table className="mt-4 capitalize relative mb-16">
          <thead>
            <tr className="">
              <th>#</th>
              <th>Item</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.length < 1 ? (
              <tr>
                <td className="font-semibold text-lg text-center absolute left-0 right-0 uppercase text-gray-200 py-5 mb-10 mx-auto flex justify-center">
                  {" "}
                  Please add an item
                </td>
              </tr>
            ) : (
              items.map((item, index) => (
                <tr
                  key={index}
                  className="cursor-pointer"
                  onClick={() => handleDeleteConfirmation(item.id)}
                >
                  <td>{item.id}</td>
                  <td>{item.receipt_details}</td>
                  <td>{item.quantity}</td>
                  <td>
                    {dashboardDetails?.currencysymbol} {new Intl.NumberFormat().format(item.selling_price)}
                  </td>
                  <td>
                    {dashboardDetails?.currencysymbol}{" "}{new Intl.NumberFormat().format(item.sub_total)}
                  </td>
                </tr>
              ))
            )}
            {items.length > 0 && (
              <tr>
                <td colSpan="4" className="text-right font-semibold">
                  Subtotal
                </td>
                <td>
                  {/* Calculate the subtotal by summing up all item subtotals */}
                  {dashboardDetails?.currencysymbol}
                  {new Intl.NumberFormat().format(invoice.subtotal)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="invoice-summary py-4 ">
        <p className="flex justify-between text-sm italic">
          Vat
          <span>
            {" "}
            {dashboardDetails?.currencysymbol}{" "}
            {new Intl.NumberFormat().format(invoice.vat)}
          </span>
        </p>
        <p className="flex justify-between text-sm italic">
          Shipping Fee{" "}
          <span>
            {" "}
            {dashboardDetails?.currencysymbol}
            {new Intl.NumberFormat().format(invoice.shipping_fee)}
          </span>
        </p>
        {/* <p className="flex justify-between text-sm italic">
          Subtotal{" "}
          <span>
            {" "}
            {dashboardDetails?.currencysymbol} {invoice.subtotal}
          </span>
        </p> */}
        <p className="flex justify-between text-sm italic text-red-500">
          Discount{" "}
          <span>
            {" "}
            {dashboardDetails?.currencysymbol}{" "}
            {new Intl.NumberFormat().format(invoice.discount)}
          </span>
        </p>
        <p className="flex justify-between text-sm italic font-semibold">
          Total{" "}
          <span>
            {" "}
            {dashboardDetails?.currencysymbol}
            {new Intl.NumberFormat().format(total())}
          </span>
        </p>
        <p className="flex justify-between text-sm italic">
          Received{" "}
          <span>
            {" "}
            {dashboardDetails?.currencysymbol}
            {new Intl.NumberFormat().format(invoice.amount_paid)}
            {}
          </span>
        </p>
        <p className="flex justify-between text-sm italic text-blue">
          Balance{" "}
          <span>
            {" "}
            {dashboardDetails?.currencysymbol}
            {new Intl.NumberFormat().format(balance())}
          </span>
        </p>
      </div>
      <h2 className="text-blue text-xl my-4 text-center font-bold">
        Thank you for your patronage!
      </h2>
      <div className="flex justify-between py-4">
        <h5 className="font-semibold text-sm">
          Powered by Logikeep
          <br />
          <span className="font-extralight ">
            <a
              href="https://play.google.com/store/apps/details?id=com.logikeep.app&hl=en_US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-blue text-sm"> Google Play Store</span>
            </a>
          </span>
        </h5>
      </div>
    </div>
  );
};

export default InvoicePreview;
