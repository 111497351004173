import React from "react";
import "./skeleton.scss";

const Skeleton = () => {
  return (
    <>
      <div className="skeleton">
        <div className="skeleton__img"></div>
      </div>
    </>
  );
};

export default Skeleton;
