import React from "react";
import Navbar from "./Navbar/index";
import Section from "../home/Section/index";
import SecondContent from "./Section/manage";
import ExpensesTrack from "./Section/expensesTrack";
import Community from "./Section/community";
// import SubFooter from "./Section/subFooter";
import Reviews from "./Section/reviews";
import Footer from "./Navbar/footer";
import Accounting from "./Section/Accounting";

export default function Index() {
  return (
    <>
      <Navbar />
      <Section />
      <Accounting />
      <SecondContent />
      {/* <ExpensesTrack/> */}
      {/* <Community/>
      <SubFooter/> */}
      <Reviews />
      <Footer />
    </>
  );
}
