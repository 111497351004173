import React from "react";

function SearchInventory({ search, handleSearch, handleShow }) {
  return (
    <div className="flex justify-between bg-white p-5 space-x-5">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="flex space-x-3">
          <input
            className="border-[1px]"
            type="text"
            placeholder="Search Inventory"
            value={search}
            onChange={handleSearch}
          />
        </div>
      </form>

      <div className="flex space-x-3">
        <button
          onClick={handleShow}
          className="border-[1px] w-36 h-9 self-center bg-blue text-white rounded-md"
          type="submit"
        >
          + Add New Item
        </button>
      </div>
    </div>
  );
}

export default SearchInventory;
