import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import Logo from "../Logo";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../styles/responsiveness.css";

const DivContainer = styled.div`
  ${tw`flex flex-col bg-BACKGROUND_BLUE bg-opacity-80  w-full`}
`;
const LiStyle = styled.div`
  ${tw`border-b border-solid border-transparent text-lg text-white`}
`;
const year = new Date();

let currentYear = year.getFullYear();

function footer() {
  return (
    <>
      <DivContainer id="contentContainer6">
        <div className="">
          <div className="md:px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div className="grid row-gap-10 mb-8 lg:grid-cols-6">
              <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
                <div className="pl-4">
                  <p className="font-medium tracking-wide text-white">
                    Products
                  </p>
                  <ul className="mt-2 space-y-2 text-white  ">
                    <li>
                      <LiStyle>
                        <Link to=""
                        className="">Features</Link>
                      </LiStyle>
                    </li>
                    <li class>
                      <LiStyle>
                        <Link to="">Logikeep Method</Link>
                      </LiStyle>
                    </li>
                    <li class>
                      <LiStyle>
                        <Link to="">Business</Link>
                      </LiStyle>
                    </li>
                  </ul>
                </div>
                <div className="pl-4">
                  <p className="font-medium tracking-wide text-white">Company</p>
                  <ul className="mt-2 space-y-2 text-white">
                  <li class="mb-2">
                    <LiStyle>
                      <Link to="/about"> About Us</Link>
                    </LiStyle>
                  </li>
                  <li class="mb-2">
                    <LiStyle>
                      <Link to="/blog"> Blog</Link>
                    </LiStyle>
                  </li>
                  <li class="mb-2">
                    <LiStyle>
                      <Link to=""> Enterprise</Link>
                    </LiStyle>
                  </li>
                  </ul>
                </div>
                <div className="pl-4">
                  <p className="font-medium tracking-wide text-white">Resources</p>
                  <ul className="mt-2 space-y-2 text-white">
                  <li class="mb-2">
                    <LiStyle>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </LiStyle>
                  </li>
                  <li class="mb-2">
                    <LiStyle>
                      <Link to=""> Service</Link>
                    </LiStyle>
                  </li>
                  <li class="mb-2">
                    <LiStyle>
                      <Link to="">FAQs</Link>
                    </LiStyle>
                  </li>
                  </ul>
                </div>
                <div className="pl-4">
                  <p className="font-medium tracking-wide text-white">Service</p>
                  <ul className="mt-2 space-y-2 text-white">
                  <li class="mb-2">
                    <LiStyle>
                      <Link to="">Sponsors</Link>
                    </LiStyle>
                  </li>
                  <li class="mb-2">
                    <LiStyle>
                      <Link to="">Careers</Link>
                    </LiStyle>
                  </li>
                  <li class="mb-2">
                    <LiStyle>
                      <Link to="">Team</Link>
                    </LiStyle>
                  </li>
                  </ul>
                </div>
          
          
              </div>
              <div className="md:max-w-md lg:col-span-2 px-4 mt-4 sm:mt-4">
                <p class="text-sm text-white ">Logikeep is a technology platform- it is not a bank and does not hold or claim to hold a banking license. <br/>
              The banking services / financial services offered on the logikeep platform are provided by CBN-licensed banks and financial institutions.
            </p>
              </div>
            </div>
            <hr class="horizontal"/>

            <div className="pt-2 px-4 flex justify-between py-0 ">
            <div className="self-center text-white text-sm" id="">
              © LogiKeep Copyright {currentYear}. All Rights Reserved.
            </div>
            <div className="flex text-white" id="icons" >
              <a href="https://facebook.com/logikeep" className="w-6 mx-1">
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a href="https://instagram.com/logikeep" className="w-6 mx-1">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a href="https://twitter.com/logikeep" className="w-6 mx-1">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </a>
              <a href="https://www.linkedin.com/company/logikeep-africa" className="w-6 mx-1">
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
            </div>
          </div>
           
          </div>
        </div>
      </DivContainer>
    </>
  );
}

export default footer;
