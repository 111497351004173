import React, { useEffect } from "react";
import tw from "twin.macro";
import "../../../index.css";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import TopEllipse from "../../../assets/images/Ellipsetop.png";
import manage from "../../../assets/images/manage.jpeg";
import styled from "styled-components";
import Zoom from "react-reveal/Zoom";

export const ContentContainer = styled.div`
  ${tw`text-base p-[calc(8px + 1.5625vw)] pb-10`}
`;
export const DivContainer = styled.div`
  ${tw`grid gap-5 lg:gap-0 md:grid-cols-2 lg:grid-cols-2`}
`;
export const Col = styled.div`
  ${tw`w-full flex flex-col lg:justify-self-center`}
`;
const ColFull = styled.div`
  ${tw`lg:justify-self-center`}
`;

export default function Content() {
    useEffect(() => {
      AOS.init({ duration: 2000 });
      AOS.refresh();
    }, []);
    return (
      <>
        <ContentContainer className="bg-white lg:-mt-20 mt-[75px]" id="content2">
          <div className="relative -top-10">
            <img src={TopEllipse} alt="ellipse" className="topEllipse" />
          </div>
          <h1 className="text-blue text-center pb-4 font-bold text-4xl">ABOUT US</h1>
          <DivContainer id="contentContainer">
            <Col className="relative">
           <h2 className="text-blue text-center mt-6 font-bold text-3xl">WHO WE ARE ?</h2>
           <p className="pt-4 leading-loose">We are an inventory application that helps you add products,record sales and check available stock across your stores with our app. We were established in the year march 2020 and ever since then we have created a large impact among business owners across the world. Currently we have over 2 million users around the world and we have been expanding rapidly. Our sole aim is to help business owners effectively manage their businesses and keep records of their goods easily</p>
                
               
            </Col>
            <ColFull>
              <Zoom>
                <div className="lg:w-[400px]">
                  <img
                    src={manage}
                    alt="img"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  />
                </div>
              </Zoom>
            </ColFull>
          </DivContainer>
        </ContentContainer>
      </>
    );
  }
