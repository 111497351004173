import React, { useState, useEffect } from "react";
import "../../../styles/screen.css";
import "../../../styles/responsiveness.css";
import {
  loginFailure,
  loginRequest,
  loginReset,
  loginSuccess,
  setToken,
} from "../../../redux/actions/auth/authActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { api } from "../../../services/api";
import { appUrls } from "../../../services/urls";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../home/Logo";
import { Icon } from "@iconify/react";
import styled, { keyframes } from "styled-components";
import { merge, fadeIn, bounce } from "react-animations";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "@blueprintjs/core";

const bounceFadeIn = merge(bounce, fadeIn);

const bounceAnimation = keyframes`${bounceFadeIn}`;
const BouncyDiv = styled.div`
  animation: 10s infinite ${bounceAnimation};
`;

const passwordRevealIcon = {
  isOpen: false,
  isType: true,
};

const Registered = () => {
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const dispatch = useDispatch();
  const loginReducder = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();
  const errorDiv = error ? (
    <small className="text-red-600 text-center w-52 mt-4">{error}</small>
  ) : (
    ""
  );

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password: formData?.password,
      email: formData?.email,
      firebase_token: " "
    };

    dispatch(loginRequest());
    setIsLoading(true);
    await api
      .post([appUrls.loginUrl, data])
      .then((res) => {
        dispatch(setToken(res.token));
        dispatch(loginSuccess(res));
        localStorage.setItem("BearerToken", res.token);
        setIsLoading(false);
        let businessName = res.data.business_name;
        businessName ? navigate("/dashboard") : navigate("/dashboard/business");
      })
      .catch((err) => {
        dispatch(loginFailure(err?.data));
        setError(err?.data?.message);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(loginReset());
        }, 3000);
      });
  };

  const passwordRevealIcon = {
    isOpen: false,
    isType: true,
  };

  const [passwordReveal, setPasswordReveal] = useState(passwordRevealIcon);
  const revealPass = passwordReveal.isOpen ? "el:eye-open" : "el:eye-close";

  const inputType = passwordReveal.isType ? "password" : "text";

  const handlePasswordReveal = () => {
    setPasswordReveal({
      ...passwordReveal,
      isOpen: !passwordReveal.isOpen,
      isType: !passwordReveal.isType,
    });
  };

  return (
    <div>
      <div className="flex  flex-wrap h-full  g-6 text-gray-800 ">
        <div className="lg:flex lg:flex-wrap g-0  ">
          <div className="lg:w-6/12 px-4 md:px-0">
            <div className="md:p-12 md:mx-6">
              <div className="text-center">
                <img
                  src="/assets/LogikeepLogo/LogikeepLogo.png"
                  id="imgBxForgot"
                  alt="img"
                  className="h-8 w-15 object-left"
                />
                <div className="space-y-3">
                  {/* <h3
                  className='text-blue text-lg lg:text-3xl font-semibold lg:text-center'
                  id='logText'
                >
                  Sign Up
                </h3> */}
                  <p
                    className="text-blue font-semibold mb-4 mt-10 text-lg lg:text-2xl"
                    id="loginText"
                  >
                    Hello! Welcome back
                  </p>
                  <p className="text-black text-center mb-5" id="loginText">
                    We take care of everything for you, freeing up your time to
                    focus on what you do best.
                  </p>
                </div>
              </div>
              <form action="#" className="" onSubmit={handleLoginSubmit}>
                <input
                  type="email"
                  placeholder="Email"
                  className="shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black"
                  onChange={handleFormDataChange}
                  name="email"
                  id="email"
                  required
                />
                <div className="relative w-full">
                <input
                  type={inputType}
                  placeholder='Password'
                  className='shadow appearance-none rounded w-full py-3  bg-blue-100 text-[#042349] leading-tight focus:outline-none focus:shadow-outline placeholder-[#052B73] border border-black'
                  name='password'
                  onChange={handleFormDataChange}
                  required
                  id="password"
                />
                <Icon
                    className="absolute right-0 top-0 mt-[22px] mr-4 cursor-pointer"
                    icon={revealPass}
                    onClick={handlePasswordReveal}
                  />
                </div>
              
                     <Link className="link" to="/forgot-password">
                      Forgot Password ?
                    </Link>
               

                {errorDiv}
                <button
                  className="submitButton "
                  type="submit"
                  id="btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner intent="Logining" size={20} />
                  ) : (
                    "Login"
                  )}
                </button>
                <div className="space-y-5 flex flex-col items-center">
                  <h1 className="text-center mt-4">
                    Don't have an Account?{" "}
                    <Link to="/register">
                      <span className=" text-blue lg:text-xm ">Sign Up</span>
                    </Link>
                  </h1>
                 

                  <p className="text-center text-bold  text-blue">
                    <Link className="text-xs" to="/">
                      Take me back to Homepage
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-gradient-to-r from-indigo-500  to-cyan-100" id="overlay">
            <div className="text-white px-4 py-6 md:p-12 md:mx-6 ">
              <img src="/assets/registerImage.svg" id="imgBxForgot" alt="img" />
              <h2
                className="text-lg text-black font-semibold ml-4 text-center"
                id="imgText"
              >
                Your Book-keeping Team So That You
                <br />
                Can Do What You Love.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registered;
