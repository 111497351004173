import React, { useState } from "react";
import TotalPoints from "./TotalPoints";
import TotalIncome from "./TotalIncome";
import TotalExpenses from "./TotalExpenses";

function Total() {
  const [active, setActive] = useState(1);

  const [isData, setData] = useState({
    totalcustomers: 0,
    totalsales: 0,
    totalpurchaes: "500.00",
    totalpoints: "2",
  });

  const handleActive = (e) => {
    setActive(e);
  };

  return (
    <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-5">
      <TotalIncome
        active={active}
        handleActive={handleActive}
        isData={isData.totalsales}
      />
      <TotalExpenses
        active={active}
        handleActive={handleActive}
        isData={isData.totalpurchaes}
      />
      <TotalPoints
        active={active}
        handleActive={handleActive}
        isData={isData.totalpoints}
      />
    </div>
  );
}

export default Total;
