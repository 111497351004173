import React, { useState } from "react";

function DeleteAccountRequestPage() {
  const [reason, setReason] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Handle the delete account request
    // Submit the reason for deleting the account to your backend or API
    // The backend can handle the account deletion process

    // Show a success message or redirect the user to a confirmation page
    alert(
      "Your delete account request has been submitted. It will be reviewed by our team."
    );
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Delete Account Request</h1>
      <p className="mb-6">
        If you would like to delete your account, please provide a reason below.
        Your request will be reviewed by our team.
      </p>

      <form onSubmit={handleSubmit} className="max-w-lg">
        <label htmlFor="reason" className="block mb-2 font-medium">
          Reason for Account Deletion:
        </label>
        <textarea
          id="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          rows="4"
        />

        <button
          type="submit"
          className="bg-blue rounded-lg py-2 px-4 ml-2 text-white mt-3"
        >
          Submit Request
        </button>
      </form>
    </div>
  );
}

export default DeleteAccountRequestPage;
