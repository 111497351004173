import {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  getProfileRequest,
  getProfileSuccess,
  getProfileReset,
  getProfileFailure,
} from "./redux/actions/dashboard/userProfile/index";
const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryCount, setInventoryCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [currentItem, setCurrentItem] = useState({});
  const [currency, setCurrency] = useState("");
  const [partPaymentData, setPartPaymentData] = useState([]);
  const [notPaidData, setNotPaidData] = useState([]);
  const [fullHistoryData, setFullHistoryData] = useState([]);
  const csvDownloadRef = useRef(null);
  const [dataInCSV, setDataInCSV] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const [businessLogo, setBusinessLogo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fulllHistoryResponse, setFullHistoryResponse] = useState([]);
  const [partHistoryResponse, setPartHistoryResponse] = useState([]);
  const [notPaidHistoryResponse, setNotPaidHistoryResponse] = useState([]);
  const [readNote, setReadNote] = useState(false);
  const [isReceiptShown, setIsReceiptShown] = useState(false);
  const [receitDetails, setReceitDetails] = useState({});

  const dispatch = useDispatch();

  const [location, setLocation] = useState("part-paid");
  const [userToken, setUserToken] = useState(null);
  const baseUrl = `https://api.logikeep.africa/api/v1/`;
  let url;
  const { user } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    setUserToken(user?.token);
  }, [userToken, user]);
  // console.log(user);

  const config = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }),
    [userToken]
  );

  // const fetchProfile = useCallback(async () => {
  //   dispatch(getProfileRequest());
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(profileurl, {
  //       headers: {
  //         Authorization: `Bearer ${userToken}`,
  //       },
  //     });
  //     const data = await response.json();
  //     console.log(data);

  //     // const data = response.data.data;
  //     dispatch(getProfileSuccess(data));
  //     setUserProfile(data);
  //     setTheme(data.theme);
  //     setCountryId(data.all_country_id);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [userToken, setUserProfile, dispatch, profileurl]);

  // Your fetchProfile function code here
  const profileurl = `${baseUrl}getuserprofile`;
  const fetchProfile = useCallback(() => {
    dispatch(getProfileRequest());
    setIsLoading(true);
    Axios.get(profileurl, config)
      .then((response) => {
        const data = response.data.data;
        console.log(data, "userProfile");
        dispatch(getProfileSuccess(data));
        setUserProfile(data);
        setTheme(data.theme);
        setCountryId(data.all_country_id);
      })
      .catch((err) => {
        dispatch(getProfileFailure(err?.data));
        setIsLoading(false);
        setTimeout(() => {
          dispatch(getProfileReset());
        }, 5000);
      });
  }, [config, dispatch, profileurl]);

  //  get state
  const [allStates, setAllStates] = useState([]);
  const [countryId, setCountryId] = useState("");
  const stateUrl = `https://api.logikeep.africa/api/v1/getstate/${countryId}`;
  const getAllStates = useCallback(() => {
    Axios.get(stateUrl, config)
      .then((response) => {
        // console.log(response);
        const data = response.data.data;
      
        setAllStates(data);
      })
      .catch((err) => {
      
        console.log(err);
      });
  }, [stateUrl, config]);

  const readNotification = async (id) => {
    url = `${baseUrl}readnotification/${id}`;
    try {
      const res = await Axios.post(url, { id }, config);
      setReadNote(!readNote);
    } catch (error) {}
  };

  const deleteNotification = async (id) => {
    setIsLoading(true);
    url = `${baseUrl}deletenotification/${id}`;

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Axios.delete(url, {
            config,
          });
          Swal.fire(
            "Deleted!",
            "Your notification has been deleted.",
            "success"
          );
          setIsLoading(false);
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  let msg;

  const sendReminder = async (id) => {
    url = `${baseUrl}reminder/${id}`;
    setIsLoading(true);
    try {
      Swal.fire({
        title: "Do you want to send Reminder to this Client?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Send",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await Axios.post(url, {}, config);
            msg = res.data.message;
            Swal.fire({
              icon: "success",
              title: "Reminder Sent!",
              text: msg,
            });
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            msg = error.response.data.message;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: msg,
            });
          }
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {}
  };
  const sendSMS = async (id) => {
    url = `${baseUrl}smsreminder/${id}`;
    setIsLoading(true);
    try {
      Swal.fire({
        title: "Do you want to send Reminder to this Client by SMS?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Send",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await Axios.post(url, {}, config);
            msg = res.data.message;
            // console.log(res);
            Swal.fire({
              icon: "success",
              title: "Reminder Sent!",
              text: msg,
            });
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            msg = error.response.data.message;

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: msg,
            });
          }
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {}
  };

  const notificationUrl = `${baseUrl}getnotification`;
  const getNotifications = useCallback(async () => {
    try {
      const res = await Axios.get(notificationUrl, config);
      const data = await res.data;
      setNotifications(data.notifications);
      setNotificationCount(data.totalnotifications);
    } catch (err) {
      const data = await err.response.data;
      setNotifications(data.notifications);
      setNotificationCount(data.totalnotifications);
    }
  }, [config, notificationUrl]);

  const getHistoryUrl = `${baseUrl}fullpaymenttransactions?page=${currentPage}`;
  const getHistory = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await Axios.get(getHistoryUrl, config);
      const { data } = await res.data;

      const code = data.currencycode;
      setCurrency(code);
      setFullHistoryData(data.data);
      setFullHistoryResponse(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [
    config,
    getHistoryUrl,
    setCurrency,
    setFullHistoryData,
    setFullHistoryResponse,
  ]);

  const getCSV = useCallback(() => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  }, []);

  const getPartHistoryUrl = `${baseUrl}partpaymenttransactions?page=${currentPage}`;
  const getPartHistory = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await Axios.get(getPartHistoryUrl, config);
      const { data } = await res.data;
   
      const code = data.currencycode;
      setIsLoading(false);
      setCurrency(code);
      setPartPaymentData(data.data);
      setPartHistoryResponse(data);
    } catch (error) {
      setIsLoading(false);
    }
  }, [config, getPartHistoryUrl]);

  const getNotPaidHistoryUrl = `${baseUrl}notpaidtransactions?page=${currentPage}`;
  const getNotPaidHistory = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await Axios.get(getNotPaidHistoryUrl, config);
      const { data } = await res.data;
      // console.log(data);
      setNotPaidData(data.data);
      setIsLoading(false);
      const code = data.currencycode;
      setCurrency(code);
      setNotPaidHistoryResponse(data);
    } catch (error) {
      setIsLoading(false);
    }
  }, [config, getNotPaidHistoryUrl]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editFunc = (id, typeID) => {
    let arr;
    if (typeID === "part") {
      setLocation("part-paid");
      arr = partPaymentData;
    } else {
      arr = notPaidData;
      setLocation("not-paid");
    }
    setIsEdit(true);
    setEditId(id);
    const selectedItem = arr.find((item) => {
      return item.data.id === id;
    });
    const {
      amount_received: paid,
      payment_status: status,
      date_to_balance: date,
      payment_type: type,
    } = selectedItem.data;
    // console.log(status, type);
  };

  const closeModal = () => {
    setIsEdit(false);
    setCurrentItem({});
  };

  const handleEditInputChange = (e) => {
    setCurrentItem({ ...currentItem, [e.target.name]: e.target.value });
  };

  const updateHistory = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    url = `${baseUrl}updateinflowtransactions/${editId}`;
    // console.log(currentItem);
    Axios.post(
      `${baseUrl}updateinflowtransactions/${editId}`,

      {
        amount_paid: currentItem.paid,
        payment_status: currentItem.status,
        date_to_balance: currentItem.date,
        payment_type: currentItem.type,
      },
      config
    )
      .then(function (response) {
        setCurrentItem({});
        setIsLoading(false);
        // console.log(currentItem);
        msg = response.data.message;
        // console.log(response, msg);
        setIsEdit(false);
        setEditId("");
        if (location === "part-paid") {
          getPartHistory();
        } else if (location === "not-paid") {
          getNotPaidHistory();
        }
        Swal.fire({
          title: "Action Successful",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
          getHistory();
          getPartHistory();
          getNotPaidHistory();
        });
      })
      .catch(function (error) {
        setCurrentItem({});
        setIsLoading(false);
        setIsEdit(false);

        msg = error.response.data.message;
        Swal.fire({
          title: "Error",
          text: msg,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const [currentTheme, setTheme] = useState(userProfile?.theme);

  function toggleTheme() {
    const newTheme = currentTheme === "light" ? "dark" : "light";
    setTheme(newTheme);
    //save to local storage
    localStorage.setItem("theme", newTheme);
    //save to db
    Axios.post(
      `${baseUrl}updatetheme`,
      {
        theme: newTheme,
      },
      config
    )
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const sendReceipt = (id) => {
    // console.log(fullHistoryData, id);
  };

  const showReceipt = (id) => {
    setIsReceiptShown(true);
    // console.log({ id, fullHistoryData });
    const receipt = fullHistoryData?.find((item) => item.id === id);
    setReceitDetails(receipt);
  };
  useEffect(() => {
    if (userToken) {
      getHistory();
      getNotifications();
      getPartHistory();
      getNotPaidHistory();
      fetchProfile();
      getAllStates();
    }
  }, [
    userToken,
    fetchProfile,
    getAllStates,
    getHistory,
    getNotPaidHistory,
    getNotifications,
    getPartHistory,
  ]);
  return (
    <AppContext.Provider
      value={{
        theme: currentTheme,
        toggleTheme,
        notifications,
        notificationCount,
        deleteNotification,
        inventoryCount,
        setInventoryCount,
        sendReminder,
        isLoading,
        isEdit,
        setIsEdit,
        editFunc,
        handleEditInputChange,
        currentItem,
        setCurrentItem,
        closeModal,
        updateHistory,
        currency,
        partPaymentData,
        notPaidData,
        fullHistoryData,
        userProfile,
        businessLogo,
        setCurrentPage,
        getHistory,
        getNotPaidHistory,
        getNotifications,
        getPartHistory,
        fetchProfile,
        fulllHistoryResponse,
        notPaidHistoryResponse,
        partHistoryResponse,
        sendSMS,
        currentPage,
        handlePageChange,
        readNotification,
        sendReceipt,
        isReceiptShown,
        setIsReceiptShown,
        showReceipt,
        receitDetails,
        getAllStates,
        countryId,
        allStates,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
