import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "../../../styles/responsiveness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContentContainer } from ".";

const DivContainer = styled.div`
  ${tw`grid md:grid-cols-2 lg:grid-cols-2 gap-5`}
`;
const Col = styled.div`
  ${tw`w-full flex flex-col lg:pr-32`}
`;
const ColFull = styled.div`
  ${tw`w-full`}
`;
const TextAlign = styled.div`
  ${tw`flex`}
`;
const ImgIcon = styled.img`
  ${tw`lg:w-[500px]`};
`;
const Header = styled.h1`
  ${tw`text-4xl font-sans font-medium text-black mt-24 `}
`;
const Paragraph = styled.p`
  ${tw`lg:text-lg mt-4 text-black`}
`;
const SignBtn = styled.div`
  ${tw`text-sm rounded-lg w-28 p-1 border-2 border-blue
text-center justify-center mt-4`}
`;

export default function ExpensesTrack() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContentContainer>
        <DivContainer>
          <ColFull>
            <TextAlign className="imgExpense">
              <ImgIcon
                src="/assets/PayingOnline.svg"
                alt="img"
                data-aos="fade-right"
              />
            </TextAlign>
          </ColFull>
          <Col>
            <Header id="header2" data-aos="fade-left">
              Track Your Expenses
            </Header>
            <Paragraph id="paragraph" data-aos="zoom-in">
              With a wide variety of database. Logikeep helps you track the
              inflow and outflow of money in your wallet.
            </Paragraph>
            <div
              data-aos="zoom-in-right"
              className="flex justify-center md:justify-start"
            >
              <SignBtn className="bg-BACKGROUND_BLUE">
                <Link to="/register">
                  <h1 className=" text-white animate-pulse">Get Started</h1>
                </Link>
              </SignBtn>
            </div>
          </Col>
        </DivContainer>
      </ContentContainer>
    </>
  );
}
