import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import "../../styles/responsiveness.css";
import Log from "../../assets/images/logikeepPngBlue.png";

export const FlexContainer = styled.div`
  ${tw`flex items-center`}
  ${tw`cursor-pointer`};
`;
const LogoIcon = styled.img`
  ${tw`lg:w-[115px]  md:w-[115px]  sm:w-[115px]  `};
`;

const Logo = () => (
  <Link to="/">
    <FlexContainer className="" id="logo">
      <LogoIcon src={Log} alt="logo" />
    </FlexContainer>
  </Link>
);

export default Logo;
