import {
    UPDATEBUSINESS_FAILURE,
    UPDATEBUSINESS_REQUEST,
    UPDATEBUSINESS_SUCCESS,
    UPDATEBUSINESS_RESET,
  } from "../../../actionTypes/dashboard/businessInfo";
  
  export const updateBusinessRequest = () => {
    return {
      type: UPDATEBUSINESS_REQUEST,
    };
  };
  
  export const updateBusinessSuccess = (data) => {
    return {
      type: UPDATEBUSINESS_SUCCESS,
      payload: data
    };
  };
  
  export const updateBusinessFailure = (error) => {
    return {
      type: UPDATEBUSINESS_FAILURE,
      payload: error
    };
  };
  
  export const updateBusinessReset = () => {
    return {
      type: UPDATEBUSINESS_RESET,
      payload: null
    };
  };
  
