import {
    STATEMENT_FAILURE,
    STATEMENT_REQUEST,
    STATEMENT_SUCCESS,
    STATEMENT_RESET,
    } from "../../actionTypes/dashboard/businessStatement";
    
    export const statementRequest = () => {
      return {
        type:STATEMENT_REQUEST,
      };
    };
    
    export const statementSuccess = (data) => {
      return {
        type:STATEMENT_SUCCESS,
        payload: data
      };
    };
    
    export const statementFailure = (error) => {
      return {
        type :STATEMENT_FAILURE,
        payload: error
      };
    };
    
    export const statementReset = () => {
      return {
        type:STATEMENT_RESET,
        payload: null
      };
    };
    
  