export const appUrls = {
  loginUrl: "api/v1/login",
  signupUrl: "api/v1/register",
  userProfileUrl: "api/v1/getuserprofile",
  changePasswordUrl: "",
  stateUrl: "api/v1/getstate",
  businessUrl: "api/v1/updatebusiness",
  logoutUrl: "api/v1/logout",
  expensesUrl: "api/v1/createoutflow",
  resetPasswordUrl: "api/v1/reset-password",
  forgetPasswordUrl: "api/v1/forgot-password",
  dashboardUrl: "api/v1/getdashboarddetails",
  contactUsUrl:"api/v1/contactus",
  userDetailsUrl: "api/v1/getuserprofile",
  convertAirtime: "api/v1/convert-to-airtime",

};
